import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { enrollmentTypes } from '@src/constants';
import { formatTimeDate, getOptions } from '@src/utils';
import { twelveHoursFormat, splitSign } from '@src/constants';
import { CourseBadges } from '@components/shared/Course/CourseBadges';
import { Button, InstructorName, Icon } from '@components/shared';
import { ProviderLogo } from '@components/provider';

export const ScheduleCard = ({ cardOnClickHandler, event }) => {
  const filters = useSelector((state) => state.schedule.filters);
  const href = window.location.href;

  const getFormattedTimeString = (timeString) =>
    formatTimeDate(timeString, splitSign, twelveHoursFormat).toLowerCase();

  const {
    id,
    course,
    start_time,
    end_time,
    location,
    session,
    is_past,
    is_trial,
  } = event;

  const {
    categories,
    participant_levels,
    participant_level_exception,
    label,
    type,
    title,
  } = course;

  const levels = getOptions(participant_levels);

  const matomoHandler = () => {
    if (href.includes('instructor')) {
      const instructorId = href.split('instructor=')[1].split('&')[0];
      const instructor = filters?.data?.instructors.filter(
        (instructor) => instructor.id === Number(instructorId)
      );
      const instructorName = instructor[0].display_name;

      window._mtm.push({ event: 'search_main', query: `${instructorName}` });
    }

    const categoriesArray = categories.map((category) => {
      return category.name;
    });
    const categoriesString = categoriesArray.join(', ');

    window._mtm = window._mtm || [];
    window._mtm.push({
      event: 'view_item',
      ecommerce: {
        view_item: {
          id: `${course.id}`,
          item_name: `${title}`,
          item_category: `${categoriesString}`,
          price: 'price value',
        },
      },
    });
  };

  return (
    <div className="schedule-card">
      {/* <ProviderLogo 
        className="schedule-card__logo"
        src="https://images.squarespace-cdn.com/content/v1/62dd8c08fd1480736a4e030a/780c2b99-4d78-4060-8348-04fb1110eccd/DS84+NEW+LOGO+STACKED.png"
      /> */}
      <div className="schedule-card__title-wrapper">
        <p className="schedule-card__id">
          {label}
          {type && ` | ${enrollmentTypes[type]}`}
        </p>
        {
          <CourseBadges
            course={course}
            isFree={session.is_free}
            isPast={is_past}
            isTrial={is_trial}
          />
        }
        {title && (
          <h3
            className="schedule-card__title"
            onClick={() => cardOnClickHandler(id, course, matomoHandler)}
          >
            {title}
          </h3>
        )}
      </div>

      <div className="schedule-card__group">
        <Icon type="profile-tab" />
        <InstructorName
          course={course}
          className="schedule-card__instructors"
        />
      </div>

      <div className="schedule-card__group">
        <Icon type="clock" />
        <span>
          {getFormattedTimeString(start_time)} &mdash;{' '}
          {getFormattedTimeString(end_time)} EST
        </span>
      </div>

      {location && (
        <div className="schedule-card__group">
          <Icon type="location" />
          <span>{location}</span>
        </div>
      )}

      {levels && (
        <div className="schedule-card__group">
          <Icon type="participant-level" />
          <span>
            {levels}
            <sup>{levels && participant_level_exception}</sup>
          </span>
        </div>
      )}

      <div className="schedule-card__footer">
        <Button
          onClick={() => cardOnClickHandler(id, course, matomoHandler)}
          iconRight={<Icon type="proceed-next" size="md" />}
          type="navigation"
          className="btn--schedule-more"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

ScheduleCard.propTypes = {
  cardId: PropTypes.string,
  cardTitle: PropTypes.string,
  cardTime: PropTypes.string,
  cardDifficulty: PropTypes.string,
  cardInstructor: PropTypes.string,
  cardStudio: PropTypes.string,
  cardOnClickHandler: PropTypes.func,
  className: PropTypes.string,
};

ScheduleCard.defaultProps = {
  cardId: '',
  cardTitle: '',
  cardTime: '',
  cardDifficulty: '',
  cardInstructor: '',
  cardStudio: '',
  cardOnClickHandler: () => {},
  className: '',
};
