import React from 'react';
import { format } from 'date-fns';
import { userTicketsDateFormat } from '@src/constants';
import { Loader, Icon } from '@components/shared';
import { openLink, zonedDate } from '@src/utils';
import { AccountTicketsCard } from './AccountTicketsCard';
import { useApi } from '@src/hooks';
import { AccountTicketsNavigation } from './AccountTicketsNavigation';

export const AccountTicketsView = () => {
  const tickets = useApi({ url: '/account/tickets' }),
  { data, loading } = tickets || {},
  { Past, Upcoming } = data || {};

  const getDateBlocks = (block) => {
    return Object.entries(block).map(([date, tickets]) => (
      <div className="account-tickets__date-block" key={date}>
        <h3 className="account-tickets__date">
          {format(zonedDate(date), userTicketsDateFormat)}
        </h3>
        {renderTickets(tickets)}
      </div>
    ));
  };

  const renderTickets = (tickets) => {
    return tickets.map((ticket) => (
      <AccountTicketsCard ticketData={ticket} key={ticket.id} />
    ));
  };

  const NoTicketsBlock = () => {
    return (
      <div className="account__no-items account-tickets__no-tickets">
        <Icon type="tickets-account" />
        {!tickets.loading && (
          <p>
            You currently don't have any tickets for {Upcoming ? 'upcoming' : 'past'} offerings.
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="account-tickets">
      <h2 className='account__content-title'>Tickets</h2>
      <Loader attached show={loading} />
      <AccountTicketsNavigation 
        upcomingTickets={ 
          Upcoming ? (
            <>
              {getDateBlocks(Upcoming)}
            </>
          ) : (
            <NoTicketsBlock />
          )
        } 
        pastTickets={ 
          Past ? (
            <>
              {getDateBlocks(Past)}
            </>
          ) : (
            <NoTicketsBlock />
          )
        } 
      />
    </div>
  );
};