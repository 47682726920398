import React, { createRef, useEffect, useState } from 'react';
import { classNames } from '@src/utils';

export const TableRow = ({ className, children, title, subtitle, style }) => {
  return (
    <div
      style={{ ...style }}
      className={classNames([
        'table__row',
        className,
        title && 'table__row--title',
        subtitle && 'table__row--subtitle',
        className,
      ])}
    >
      {children}
    </div>
  );
};