import React, { useState, useEffect } from 'react';
import { FUNDING, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { Radio, Tooltip } from '@components/shared';
import { useSelector, useDispatch } from 'react-redux';
import { setPaymentMethod } from '@actions/registrationActions';

export const PaymentMethodSelector = () => {
  const dispatch = useDispatch();
  const [{ isResolved }] = usePayPalScriptReducer();
  const selectedPaymentMethod = useSelector(
    (state) => state.registration.paymentMethod
  );

  const [isVenmoEligible, setIsVenmoEligible] = useState(false);

  const checkVenmoEligibility = () => {
    const venmo = window?.paypal?.Buttons({ fundingSource: FUNDING.VENMO });
    const isEligible = venmo?.isEligible();
    setIsVenmoEligible(isEligible);
  };

  useEffect(() => {
    return () => dispatch(setPaymentMethod('payflow'));
  }, []);

  useEffect(() => {
    if (isResolved) {
      checkVenmoEligibility();
    }
  }, [isResolved]);

  return (
    <div className="payment-selector">
      <span className="payment-selector__title">PAYMENT METHOD</span>
      <Radio
        name="payflow"
        value={selectedPaymentMethod === 'payflow'}
        onChange={() => dispatch(setPaymentMethod('payflow'))}
        label="Credit/Debit Card or PayPal"
      />
      <Radio
        name="paypal"
        value={selectedPaymentMethod === 'paypal'}
        onChange={() => dispatch(setPaymentMethod('paypal'))}
        label="Venmo"
        disabled={!isVenmoEligible}
        tooltip={
          !isVenmoEligible && (
            <Tooltip
              position="top"
              type="danger"
              title={
                <>
                  <a
                    href="https://help.venmo.com/hc/en-us/articles/221011388-What-is-Venmo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Venmo
                  </a>{' '}
                  (alternate way to credit cards) might be unavailable for you
                  because of the following reasons:
                </>
              }
            >
              1. You must be physically located in the United States.
              <br /> 2. You must have a U.S. cell phone that can send/receive
              text messages from short codes.
              <br /> 3. The only{' '}
              <a
                href="https://help.venmo.com/hc/en-us/articles/115010455987-Getting-Started-Purchasing-with-Venmo-Online"
                target="_blank"
                rel="noopener noreferrer"
              >
                Venmo supported mobile device browsers
              </a>{' '}
              are Google Chrome for Android and Safari for iOS, with Cookies
              enabled.
              <br /> 4. More information on Venmo{' '}
              <a
                href="https://help.venmo.com/hc/en-us/articles/209690188-Requirements"
                target="_blank"
                rel="noopener noreferrer"
              >
                requirements
              </a>
              .
            </Tooltip>
          )
        }
      />
      {/* <Radio
        name="google"
        value={selectedPaymentMethod === 'google'}
        onChange={() => setSelectedPaymentMethod('google')}
        label="Google Pay"
      />
      <Radio
        name="apple"
        value={selectedPaymentMethod === 'apple'}
        onChange={() => setSelectedPaymentMethod('apple')}
        label="Apple Pay"
      /> */}
    </div>
  );
};
