import React, { useEffect, useState } from 'react';
import { Button, Tabs, Loader, Icon } from '@components/shared';
import { SessionsItem } from './SessionsItem';
import { SessionsCourseCard } from './SessionsCourseCard';
import { 
  setActiveTab, 
  createTab, 
  getActiveTab, 
  queryParam,
  classNames 
} from '@src/utils';
import { useApi } from '@src/hooks';

export const Sessions = ({
  isOfferingDetailsPage,
  forwardedRef,
  grouped,
  instructorId,
  classId,
  onRegisterClick,
  onLearnMoreClick,
  contentType,
  onTrialClick,
  isPrivate,
  className,
}) => {
  const initTab = queryParam.get('activeTab');
  const [sessionsTabs, setSessionsTabs] = useState([
    createTab('Current', 'active', initTab ? initTab === 'active' : true),
    createTab('Past', 'past', initTab === 'past'),
  ]);

  const activeTab = getActiveTab(sessionsTabs);

  const getSessionsToShow = (sessions) =>
    sessions.slice(0, showSessionsCounter);

  useEffect(() => {
    setSessionstoShow(4);
  }, [activeTab.value]);

  const instructorSessions = useApi(
    {
      url: `/instructors/${instructorId}/courses?session_status=${activeTab.value}`,
      dataPlaceholder: []
    },
    [activeTab.value],
    !!grouped
  );

  const courseSessions = useApi(
    {
      url: `/courses/${classId}/sessions?status=${activeTab.value}`,
      dataPlaceholder: []
    },
    [activeTab.value],
    !grouped
  );

  const sessions = grouped ? instructorSessions : courseSessions;

  const isActive = activeTab.value === 'active';
  const [showSessionsCounter, setShowSessionsCounter] = useState(4);
  const [sessionsToShow, setSessionstoShow] = useState([]);

  useEffect(() => {
    const newSessions = isActive
      ? sessions.data
      : getSessionsToShow(sessions.data);
    setSessionstoShow(newSessions);
  }, [sessions.data, showSessionsCounter]);

  const canShowMore = sessions.data.length > sessionsToShow.length;

  const onShowMoreClick = (e) => {
    setShowSessionsCounter(showSessionsCounter + 4);
  };

  useEffect(() => {
    queryParam.set('activeTab', activeTab.value);
  }, [activeTab.value]);

  const getSessionItem = (session, grouped, course) => {
    return(
      <SessionsItem
        onRegisterClick={onRegisterClick}
        onTrialClick={onTrialClick}
        onLearnMoreClick={onLearnMoreClick}
        key={session.id}
        data={session}
        grouped={grouped}
        classId={course?.id}
        inactive={!isActive}
        contentType={grouped ? course.content_type : contentType}
        isPrivate={grouped ? course.is_private : isPrivate}
      />
    )
  };

  const renderSessions = (item, index) =>
    grouped ? (
      isActive ? (
        <div
          key={item.id + item.title + index}
          className="sessions__course-block"
        >
          <span className="sessions__course-title">
            {item.title}{' '}
            <Button
              className="sessions__item-button--learn-more-btn"
              iconRight={<Icon type="proceed-next" />}
              onClick={() => onLearnMoreClick(item.id)}
            >
              Learn More
            </Button>
          </span>
          {item.sessions?.map((session) =>
            getSessionItem(session, grouped, item)
          )}
        </div>
      ) : (
        <SessionsCourseCard
          data={item}
          key={item.id + item.title + index}
          onLearnMoreClick={onLearnMoreClick}
        />
      )
    ) : (
      getSessionItem(item, grouped)
    );

  const getSessions = () =>
    sessionsToShow.length ? (
      sessionsToShow.map((item, index) => renderSessions(item, index))
    ) : (
      <span>{getNoSessionsMessage()}</span>
    );

  const getNoSessionsMessage = () => {
    if (!grouped && isActive) {
      return 'There are no any current offerings for this Content / Offering now.';
    }
    if (!grouped && !isActive) {
      return 'There are no any past offerings for this Content / Offering yet.';
    }
    if (grouped && isActive) {
      return 'This Instructor / Producer does not have any current offerings now.';
    }
    if (grouped && !isActive) {
      return 'This Instructor / Producer does not have any past offerings yet.';
    }
  };

  return (
    <div
      className={classNames([
        'sessions',
        className,
      ])}
      ref={forwardedRef}
    >
      {!isOfferingDetailsPage &&
        <h3 className="sessions__title">
          {grouped ? 'Content / Offerings' : 'Schedule / Sessions'}
        </h3>
      }

      <Tabs
        tabsData={sessionsTabs}
        onTabClick={(tab) =>
          setActiveTab(setSessionsTabs, tab.value)
        }
      />

      <div className={classNames([
          'sessions__body',
          grouped && 'sessions__body--grouped',
          !isActive && 'sessions__body--no-active'
        ])}
      >
        {sessions.loading ? <Loader /> : getSessions()}
      </div>

      {canShowMore && !isActive && (
        <div className="sessions__actions sessions__actions--show-all">
          <Button onClick={onShowMoreClick}>Show more</Button>
        </div>
      )}
    </div>
  );
};