import React from 'react';
import { useSelector } from 'react-redux';
import { Faculty } from '@components/features';

export const GlobalFaculty = () => {
  const filtersSelect = useSelector((state) => state.faculty.filtersSelect);
  const search = useSelector((state) => state.faculty.search);
  const instructors = useSelector((state) => state.faculty.instructors);

  return (
    <Faculty 
      className="global-faculty"
      filtersSelect={filtersSelect}
      search={search}
      instructors={instructors}
    />
  );
};