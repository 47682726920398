import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { setActiveTab, createTab, changeBodyScroll } from '@src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@actions/authActions';
import { Header } from '@components/layout';
import {
  Button,
  getModal,
  Icon,
  openModal,
  Tabs,
  Tooltip,
  Link
} from '@components/shared';

export const GlobalHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const singlePages = location.pathname.includes('faculty/') || location.pathname.includes('offering/');
  const initTab = !singlePages && `/${location.pathname.split('/')[1]}`;
  const [pageTabs, setPageTabs] = useState([
    createTab('Programs', '/programs', (!singlePages && true)),
    createTab('Schedule', '/schedule'),
    createTab('Faculty', '/faculty'),
    // createTab('Providers', '/providers'),
    // createTab('About', '/about'),
    // createTab('Contact', '/contact'),
  ]);

  useEffect(() => {
    setActiveTab(setPageTabs, initTab);
  
    history.listen((location) => {
      setActiveTab(setPageTabs, location.pathname);
    });
  }, []);

  const onLoginClick = () => {
    setIsMobileNavOpen(false);
    openModal(
      getModal({
        name: 'login',
      })
    );
  };

  const onLogoutClick = () => {
    dispatch(logout());
  };

  const onNavTabClick = (tab) => {
    setIsMobileNavOpen(false);
    changeBodyScroll(false, 'modal');

    if(localStorage.getItem('returnPath') || localStorage.getItem('returnHref')) {
      localStorage.removeItem('returnHref');
      localStorage.removeItem('returnPath');
    }
    
    history.push(tab.value);
  };

  const onDonationButtonClick = () => {
    setIsMobileNavOpen(false);
    openModal(
      getModal({
        name: 'donation',
      })
    );
  };

  const renderNavigation = (isMob) => {
    return (
      <>
        <div className="header__nav header__nav--pages">
          <Tabs
            tabsData={pageTabs}
            onTabClick={(tab) => onNavTabClick(tab)}
            isShadowVisible={!isMob}
          />
        </div>

        <div className="header__nav header__nav--auth">
          <ul className="list">
            <li className="list__item">
              <Tooltip
                position="bottom"
                className="header__donation-tooltip"
                body={
                  <Button
                    className="header__donation-btn"
                    iconLeft={<Icon type="donate" />}
                    onClick={onDonationButtonClick}
                  >
                    Donate
                  </Button>
                }
              >
                Make a tax-deductible donation
              </Tooltip>
            </li>

            {isAuthorized ? (
              <>
                <li className="list__item">
                  <Link
                    className="header__button"
                    href={`${window.location.origin}/account`}
                    target=""
                    iconLeft={<Icon type="profile-tab" />}
                  >
                    My Account
                  </Link>
                </li>

                <li className="list__item">
                  <Button
                    type="navigation"
                    iconLeft={<Icon size="md" type="log-out" />}
                    onClick={onLogoutClick}
                  >
                    Log out
                  </Button>
                </li>
              </>
            ) : (
              <li className="list__item">
                <Button
                  className="header__button"
                  iconLeft={<Icon type="profile-tab" />}
                  onClick={onLoginClick}
                >
                  Log in &#38; Sign up
                </Button>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  };

  return (
    <Header 
      className="global-header"
      isMobileNavOpen={isMobileNavOpen}
      setIsMobileNavOpen={setIsMobileNavOpen}
      renderNavigation={renderNavigation}
    />
  );
};