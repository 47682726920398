import React from 'react';
import { 
  Button, 
  Icon, 
  getModal, 
  openModal,
  Link 
} from '@components/shared';
import {
  openLink,
  formatTimeDate,
  getFormattedPhone,
  getImageUrl
} from '@src/utils';
import { twelveHoursFormat, splitSign } from '@src/constants';
import { useViewport } from '@src/hooks';

export const AccountScheduleCard = ({ scheduleData }) => {
  const {
    course,
    instructor,
    location,
    schedule,
    order,
    special_notes_and_directions,
    id,
  } = scheduleData;

  const { display_name } = instructor;
  const { isMobile } = useViewport();
  const receiptId = order.transaction?.id;

  const onJoinOnlineClick = () => {
    // openLink(classLink, true)
  };

  const getCourseFormat = () => {
    const { is_online, is_onsite } = course;
    if (is_online && !is_onsite) return 'Online';
    if (!is_online && is_onsite) return 'Onsite';
    if (is_online && is_onsite) return 'Online/Onsite';
    return null;
  };

  const getShceduleTime = () => {
    const formattedStart = formatTimeDate(
      schedule.start_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();
    const formattedEnd = formatTimeDate(
      schedule.end_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();
    return `${formattedStart} - ${formattedEnd}`;
  };

  const onReceiptButtonClick = () => {
    openModal(
      getModal({
        name: 'receipt',
        data: { receiptId },
      })
    );
  };

  const onDirectionsButtonClick = () => {
    openModal(
      getModal({
        name: 'directions',
        data: { id, special_notes_and_directions },
      })
    );
  };

  const onOfferingTitleClick = (eventId) => {
    openModal(
      getModal({
        name: 'class-details',
        data: { eventId },
      })
    );

    window._mtm = window._mtm || [];
    window._mtm.push({
      'event': 'view_item',
      'ecommerce': {
        'view_item': {
          'id': `${scheduleData?.course?.id}`,
          'item_name': `${scheduleData?.course?.title}`,
          'item_category': 'category value',
          'price': 'price value',
          }
        }
    });
  };

  return (
    <div className="account-schedule-card">
      <div className="ftable">
        <div className="ftable__row account-schedule-card__heading">
          <div className="ftable__cell">
            <div className="ftable__cell-content">
              <span className="account-schedule-card__offering-title">
                {/* <Link 
                  onClick={() => onOfferingTitleClick(course.id)}
                  target=""
                >
                  {course.title}
                  <span className="account-schedule-card__offering-id">
                    ({course.label}) <span><Icon type="proceed-next" /></span>
                  </span>
                </Link> */}

                <Button 
                  onClick={() => onOfferingTitleClick(course.id)}
                >
                  {course.title}
                  <span className="account-schedule-card__offering-id">
                    ({course.label}) <span><Icon type="proceed-next" /></span>
                  </span>
                </Button>
              </span>
            </div>
            <div className="ftable__cell-content account-schedule-card__offering-instructor">
              <Link 
                href={`${window.location.origin}/faculty/${instructor.id}`}
              >
                {display_name}
              </Link>
            </div>
          </div>
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__action">
              {/* <Button
                type="primary"
                iconRight={<Icon type="chevron" size="sm" />}
                onClick={() => onJoinOnlineClick()}
              >
                {!isMobile ? 'Join online' : 'Join'}
              </Button> */}
            </div>
          </div>
        </div>
        <div className="ftable__row account-schedule-card__body">
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Time
            </div>
            <div className="ftable__cell-content account-schedule-card__value">
              {getShceduleTime()} EST
            </div>
          </div>
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Format
            </div>
            <div className="ftable__cell-content account-schedule-card__value">
              {getCourseFormat()}
            </div>
          </div>
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Instructor’s Phone
            </div>
            <div className="ftable__cell-content account-schedule-card__value">
              {instructor.phone || instructor.home_phone || instructor.cell_phone ? (
                <>
                  <div>
                    {getFormattedPhone(instructor.phone)}
                  </div>
                  <div>
                    {getFormattedPhone(instructor.home_phone)}
                  </div>
                  <div>
                    {getFormattedPhone(instructor.cell_phone)}
                  </div>
                </>
              ) : (
                '–'
              )}
            </div>
          </div>
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Support Phone
            </div>
            <div className="ftable__cell-content account-schedule-card__value">
              {getFormattedPhone('+16176421444')}
            </div>
          </div>
          <div className="ftable__cell">
            <div className="ftable__cell-content account-schedule-card__label">
              Studio
            </div>
            <div className="ftable__cell-content account-schedule-card__value">
              {schedule.studio.name}
            </div>
          </div>
          {course.is_onsite && (
            <div className="ftable__cell">
              <div className="ftable__cell-content account-schedule-card__label">
                Location
              </div>
              <div className="ftable__cell-content account-schedule-card__value">
                {location.name} – {location.address.address_1},{' '}
                {location.address.city}
              </div>
            </div>
          )}
        </div>
        <div className="ftable__row account-schedule-card__footer">
          <div className="ftable__cell">
            <div className="ftable__cell-content">
              <div className="account-schedule-card__footer-buttons">
                {special_notes_and_directions && 
                  <Button
                    onClick={onDirectionsButtonClick}
                    className='account-schedule-card__button'
                  >
                    {!isMobile ? 'Special Directions' : 'Directions'}
                  </Button>
                }
                {receiptId && 
                  <Button
                    onClick={onReceiptButtonClick}
                    className='account-schedule-card__button'
                  >
                    Receipt
                  </Button>
                }
              </div>
              <div className="account-schedule-card__footer-logo">
                <Link href={location.link}>
                  <img
                    src={getImageUrl(location.logo?.path)}
                    alt="Logo"
                    height={isMobile ? '20' : '30'}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
