import * as types from '@types/authTypes';

const initialState = {
  userData: {
    data: null,
    loading: false,
    updateLoading: false,
    error: '',
  },
  isAuthorized: !!localStorage.getItem('token'),
  globalLoading: false
};

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.USER_DATA: {
      return {
        ...state,
        userData: {
          data: payload,
          loading: false,
          updateLoading: false,
          error: '',
        },
      };
    }

    case types.USER_DATA_LOADING: {
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: true,
          error: '',
        },
      };
    }

    case types.USER_DATA_UPDATE_LOADING: {
      return {
        ...state,
        userData: {
          ...state.userData,
          updateLoading: true,
          error: '',
        },
      };
    }

    case types.USER_DATA_ERROR: {
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          updateLoading: false,
          error: payload,
        },
      };
    }

    case types.IS_AUTHORIZED: {
      return {
        ...state,
        isAuthorized: payload,
      };
    }

    case types.GLOBAL_LOADING: {
      return {
        ...state,
        globalLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
};