import React from 'react';
import {
  CourseDetailsModal,
  Registration,
  Login,
  EmailVerification,
  EmailVerificationSuccess,
  ExpiredLinkNotification,
  LogoutRequestNotification,
  DonationModal,
  DirectionsModal,
  ReceiptModal,
  ReceiptSliderModal
} from '@components/modals';
import { Policy } from '../Policy/Policy';

export const getModal = (modal) => {
  const { name, data } = modal;

  switch (name) {
    case 'registration': {
      return {
        name: 'registration',
        body: <Registration {...data} />,
      };
    }

    case 'class-details': {
      return {
        name: 'class-details',
        body: <CourseDetailsModal {...data} />,
        className: 'modal--class-details',
      };
    }

    case 'login': {
      return {
        name: 'registration',
        body: <Login />,
      };
    }

    case 'email-verification-success': {
      return {
        name: 'email-verification-success',
        className: 'modal--narrow',
        body: <EmailVerificationSuccess />,
      };
    }

    case 'email-verification': {
      return {
        name: 'email-verification',
        className: 'modal--narrow',
        body: <EmailVerification {...data}/>,
      };
    }

    case 'expired-link-notification': {
      return {
        name: 'expired-link-notification',
        className: 'modal--narrow',
        body: <ExpiredLinkNotification {...data}/>,
      };
    }

    case 'logout-request-notification': {
      return {
        name: 'logout-request-notification',
        className: 'modal--narrow',
        body: <LogoutRequestNotification {...data}/>,
      };
    }

    case 'donation': {
      return {
        name: 'donation',
        className: 'modal--narrow',
        body: <DonationModal {...data}/>,
      };
    }

    case 'directions': {
      return {
        name: 'directions',
        className: 'modal--narrow',
        body: <DirectionsModal {...data}/>,
      };
    }

    case 'receipt': {
      return {
        name: 'receipt',
        className: 'modal--narrow',
        body: <ReceiptModal {...data}/>,
      };
    }

    case 'receipt-slider': {
      return {
        name: 'receipt-slider',
        className: 'modal--narrow',
        body: <ReceiptSliderModal {...data}/>,
      };
    }

    case 'policy-notice': {
      return {
        name: 'policy-notice',
        className: 'modal--policy',
        body: <Policy {...data} />,
      };
    }

    default:
      return null;
  }
};
