import React from 'react';
import PropTypes from 'prop-types';
import ICONS from './icons';
import { insertClassName } from '@src/utils';

export const Icon = (props) => {
  const { type, className, handleClick, size } = props;

  const SIZES = {
    default: '',
    xs: ' icon--xs',
    sm: ' icon--sm',
    md: ' icon--md',
    lg: ' icon--lg',
    xl: ' icon--xl',
    xxl: ' icon--xxl',
    full: ' icon--full'
  };

  const icon =
    ICONS[type].format === 'data' ? (
      <path d={ICONS[type].path} />
    ) : (
      ICONS[type].markup
    );

  return (
    <svg
      className={`icon${insertClassName(className, className)}${SIZES[size]}`}
      viewBox={ICONS[type].viewbox}
      aria-labelledby="title"
      onClick={handleClick}
      fill={ICONS[type].initColor || ''}
    >
      {icon}
    </svg>
  );
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  type: 'plus',
  className: '',
  size: 'default',
};