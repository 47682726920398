import * as types from '@types/registrationTypes';

const initialState = {
  regSlots: {
    data: [],
    loading: false,
    error: '',
  },

  blockStartDates: {
    data: null,
    loading: false,
    error: '',
  },

  blockSelectedSlots: {
    data: [],
    loading: false,
    error: '',
  },

  regPrice: {
    data: null,
    loading: false,
    error: '',
  },

  paymentData: {
    data: null,
    loading: false,
    error: '',
  },

  paymentMethod: 'payflow',
  paymentLoading: false,
};

export const registrationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case types.REG_SLOTS: {
      return {
        ...state,
        regSlots: {
          data: payload,
          loading: false,
          error: '',
        },
      };
    }

    case types.REG_SLOTS_LOADING: {
      return {
        ...state,
        regSlots: {
          data: [],
          loading: true,
          error: '',
        },
      };
    }

    case types.REG_SLOTS_ERROR: {
      return {
        ...state,
        regSlots: {
          ...state.dropinSlots,
          loading: false,
          error: payload,
        },
      };
    }

    case types.REG_PRICE: {
      return {
        ...state,
        regPrice: {
          data: payload,
          loading: false,
          error: '',
        },
      };
    }

    case types.REG_PRICE_LOADING: {
      return {
        ...state,
        regPrice: {
          ...state.price,
          loading: true,
          error: '',
        },
      };
    }

    case types.REG_PRICE_ERROR: {
      return {
        ...state,
        regPrice: {
          ...state.price,
          loading: false,
          error: payload,
        },
      };
    }

    case types.BLOCK_SELECTED_SLOTS: {
      return {
        ...state,
        blockSelectedSlots: {
          data: payload,
          loading: false,
          error: '',
        },
      };
    }

    case types.BLOCK_SELECTED_SLOTS_LOADING: {
      return {
        ...state,
        blockSelectedSlots: {
          data: [],
          loading: true,
          error: '',
        },
      };
    }

    case types.BLOCK_SELECTED_SLOTS_ERROR: {
      return {
        ...state,
        blockSelectedSlots: {
          ...state.blockSelectedSlots,
          loading: false,
          error: payload,
        },
      };
    }

    case types.BLOCK_START_DATES: {
      return {
        ...state,
        blockStartDates: {
          data: payload,
          loading: false,
          error: '',
        },
      };
    }

    case types.BLOCK_START_DATES_LOADING: {
      return {
        ...state,
        blockStartDates: {
          ...state.price,
          loading: true,
          error: '',
        },
      };
    }

    case types.BLOCK_START_DATES_ERROR: {
      return {
        ...state,
        blockStartDates: {
          ...state.price,
          loading: false,
          error: payload,
        },
      };
    }

    case types.CLEAR_REG_DATA: {
      return {
        ...state,
        regSlots: initialState.regSlots,
        regPrice: initialState.regPrice,
      };
    }

    case types.PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      };
    }

    case types.PAYMENT_LOADING: {
      return {
        ...state,
        paymentLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
};