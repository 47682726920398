import {
  AlphabetButtons,
  SlideMenu,
  Button,
  Icon,
  Counter,
} from '@components/shared';
import React, { useEffect, useState } from 'react';
import { FacultyFilters } from './FacultyFilters';
import {
  getAlphabetTabs,
  setActiveTab,
  selectedFiltersCounter,
} from '@src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setFacultyLetterFilter } from '@actions/facultyActions';
import { useViewport } from '@src/hooks';

export const FacultyHeader = () => {
  const filtersSelect = useSelector((state) => state.faculty.filtersSelect);
  const [selectedFiltersNumber, setSelectedFiltersNumber] = useState(null);
  const [lettersFilterTabs, setLettersFilterTabs] = useState(getAlphabetTabs());
  const [filtersMenuOpen, setFiltersMenuOpen] = useState(false);
  const { isTablet } = useViewport();
  const dispatch = useDispatch();

  const onLetterClick = ({ value }) => {
    dispatch(setFacultyLetterFilter(value));
  };

  useEffect(() => {
    setActiveTab(
      setLettersFilterTabs,
      filtersSelect.letter || 'all'
    );
  }, [filtersSelect.letter]);

  useEffect(() => {
    setSelectedFiltersNumber(selectedFiltersCounter(filtersSelect));
  }, [filtersSelect]);

  return (
    <>
      <div className="faculty__header">
        <h1 className="global-page__title faculty__title">Faculty</h1>
        <div className="faculty__header-row">
        {!isTablet ? (
          <>
            <FacultyFilters />
            <AlphabetButtons
              tabs={lettersFilterTabs}
              onButtonClick={onLetterClick}
            />
          </>
        ) : (
          <>
            <Button
              type="transparent"
              iconLeft={
                selectedFiltersNumber < 1 ? (
                  <Icon size="md" type="filters" />
                ) : (
                  <Counter number={selectedFiltersNumber} />
                )
              }
              className={'btn--filters-menu'}
              onClick={() => setFiltersMenuOpen(true)}
            >
              Filters
            </Button>
            <SlideMenu
              isShown={filtersMenuOpen}
              className={'mobile-filters'}
              onCloseClick={() => setFiltersMenuOpen(false)}
            >
              <AlphabetButtons
                tabs={lettersFilterTabs}
                onButtonClick={onLetterClick}
              />
              <FacultyFilters />
            </SlideMenu>
          </>
        )}
        </div>
      </div>
    </>
  );
};