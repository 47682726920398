import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  normalizeRegisterData,
  normalizeUserDataErrors,
} from '@src/normalizers';
import {
  countriesWithStates,
  defaultBirthDateFormatMask,
} from '@src/constants';
import { useApi, useForm } from '@src/hooks';
import Recaptcha from 'react-recaptcha';
import {
  Button,
  Checkbox,
  Input,
  Select,
  Icon,
  Radio,
  InfoBox,
  ErrorMessage,
  closeModal,
  openModal,
  getModal,
  Tooltip,
  Loader,
  PhoneListInput
} from '@components/shared';
import { openLink, apiRequest, requestCallback } from '@src/utils';
import { getLoginSchema } from './getLoginSchema';
import _ from 'lodash';

// import { defaultPhoneFormatMask } from '@src/constants';

export const LoginForm = ({
  btnCallbacks,
  isCourseReg,
  setCurrentView,
  formCash,
  setFormCash,
  isPolicyAccepted,
  proceedLink,
}) => {
  const [isRegister, setIsRegister] = useState(formCash.isRegister || false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneCounter, setShowPhoneCounter] = useState(
    formCash.showPhoneCounter || 1
  );
  const [unloginCounter, setUnloginCounter] = useState(0);
  const [showStates, setShowStates] = useState(false);

  const submitHandler = (values) => {
    onSubmitSuccess(values);
  };

  const onSubmitSuccess = async (values) => {
    setIsLoading(true);
    let loginData = normalizeRegisterData(values, isRegister);

    if (proceedLink) {
      loginData = {
        ...loginData,
        redirect_to: proceedLink,
      };
    }

    await apiRequest({
      url: `/${isRegister ? 'account/profile' : 'auth/login'}`,
      method: 'POST',
      data: JSON.stringify(loginData),
      callbacks: requestCallback(
        () => getSubmitCallback(),
        (err) => err && handleServerErrors(err.response)
      ),
    });

    setIsLoading(false);
  };

  const getSubmitCallback = () => {
    if (isRegister) {
      openModal(getModal({ name: 'email-verification' }));
      closeModal('registration');

      window._mtm = window._mtm || [];
      window._mtm.push({'event': 'sign_up'});
    } if (!isRegister && isCourseReg) {
      btnCallbacks.submit();
    } if (!isRegister && !isCourseReg) {
      proceedLink ? openLink(proceedLink, false) : closeModal('registration');

      window._mtm = window._mtm || [];
      window._mtm.push({'event': 'login'});
    }
  };

  useEffect(() => {
    const modal = document.querySelector('.modal__window');
    modal.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [isRegister]);

  const schema = unloginCounter >= 3 ? getLoginSchema(isRegister, showStates, true) : getLoginSchema(isRegister, showStates);

  const initialValues = !_.isEmpty(formCash)  ? 
  { ...formCash, agreePolicy: isPolicyAccepted } : 
  {
    subscribeEmail: true,
    underAge: false,
    agreePolicy: isPolicyAccepted,
    isCaptchaPassed: false,
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setCustomValue,
    setErrors,
    handleBlur,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const surveys = useApi({ url: '/source-survey', dataPlaceholder: [] });
  const countries = useApi({ url: '/countries', dataPlaceholder: [] });
  const states = useApi(
    {
      url: `/countries/${values.country}/states`,
      dataPlaceholder: [],
    },
    [values.country],
    !!values.country
  );

  useEffect(() => {
    if (countries.data.length) {
      checkStates(values.country, countries.data);
    }
  }, [values.country, countries.data.length]);

  useEffect(() => {
    setCustomValue('state', '');
  }, [values.country]);

  useEffect(() => {
    const listener = (event) => {
      if (
        event.code === 'Enter' ||
        event.code === 'NumpadEnter' ||
        event.key === 'Enter'
      ) {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [values]);

  const onAuthBtnClick = () => {
    if (values.password || values.email) {
      delete values['password'];
      delete values['email'];
    }
    setErrors({});
    setIsRegister(!isRegister);
  };

  const onBackBtnClick = () => {
    if (isRegister) {
      return onAuthBtnClick();
    }
    return isCourseReg ? btnCallbacks.back() : closeModal('registration');
  };

  const checkStates = (countryId, countries) => {
    if (!countryId) {
      setShowStates(false);
      return;
    }
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );
    const countryWithStates = countriesWithStates.includes(
      selectedCountry?.name
    );
    setShowStates(countryWithStates);
  };

  const handleServerErrors = (error) => {
    const { data, config } = error;

    if (config.url === '/auth/login') {
      setErrors({ login: data.message, password: data.message });
      return;
    }

    if (data.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  const onForgotPasswordClick = () => {
    setFormCash({ ...values, isRegister, showPhoneCounter });
    setCurrentView('passwordRecovery');
  };

  const onShowPolicyClick = () => {
    setFormCash({ ...values, isRegister, showPhoneCounter });
    setCurrentView('policy');
  };

  const renderRegisterInputs = () => (
    <>
      <div className="form__group">
        <Input
          label="Email"
          placeholder="Enter your Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          name="email"
          alert={errors.email}
          required
        />
      </div>

      <div className="form__group">
        <Input
          label="Password"
          placeholder="Enter your Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          password
          name="password"
          alert={errors.password}
          required
          hint="Password should include at least 6 characters, both uppercase and lowercase letters and at least one number"
        />
      </div>

      <div className="form__group">
        <Input
          label="Repeat Password"
          placeholder="Repeat your Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          password
          name="confirmPassword"
          alert={errors.confirmPassword}
          required
        />
      </div>

      <div className="form__group">
        <Input
          label="Username"
          placeholder="Enter your Username"
          onChange={handleChange}
          value={values.userName}
          name="userName"
          alert={errors.userName}
        />
      </div>

      <div className="form__group login__switcher login__switcher--sign-up">
        <div className="login__switcher__block">
          <span>Already have an account?</span>
          <Button
            type="navigation"
            disabled={isLoading}
            onClick={onAuthBtnClick}
          >
            Sign in
          </Button>
        </div>
        <div className="login__switcher__block">
          <div>Returning Student?</div>
          <Button
            type="navigation"
            htmlType="button"
            className="login__forgot-password"
            onClick={onForgotPasswordClick}
          >
            Create Password
          </Button>
        </div>
      </div>

      <div className="form__section">
        <div className="form__group">
          <Checkbox
            onChange={handleChange}
            value={values.underAge}
            name="underAge"
            label="I’m under 18 years old"
          />
        </div>

        {values.underAge && (
          <>
            <div className="form__group">
              <Input
                placeholder="Enter Name of Parent or Guardian"
                label="Name of Parent or Guardian"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.parentName}
                name="parentName"
                alert={errors.parentName}
                required
              />
            </div>

            <div className="form__group">
              <PhoneListInput
                placeholder="Enter Parent or Guardian Phone Number"
                label="Phone Number"
                onChange={(value) => setCustomValue('parentPhone', value)}
                onBlur={handleBlur}
                value={values.parentPhone}
                name="parentPhone"
                alert={errors.parentPhone}
                required
              />
            </div>
            
            {/* <div className="form__group">
              <Input
                mask={defaultPhoneFormatMask}
                placeholder="Enter Parent or Guardian Phone Number"
                label="Phone Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.parentPhone}
                name="parentPhone"
                alert={errors.parentPhone}
                required
              />
            </div> */}
          </>
        )}
      </div>

      <div className="form__section">
        <h4 className="form__group-title form__group-title--big">
          Please tell us a little more about yourself
        </h4>

        <h4 className="form__group-title">Personal Information</h4>

        <div className="form__group">
          <Input
            label="First Name"
            placeholder="Enter your First Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            name="firstName"
            alert={errors.firstName}
            required
          />
        </div>

        <div className="form__group">
          <Input
            label="Middle name"
            placeholder="Enter your Middle Name"
            onChange={handleChange}
            value={values.middleName}
            name="middleName"
            alert={errors.middleName}
          />
        </div>

        <div className="form__group">
          <Input
            label="Last name"
            placeholder="Enter your Last Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            name="lastName"
            alert={errors.lastName}
            required
          />
        </div>

        <div className="form__group">
          <Input
            mask={defaultBirthDateFormatMask}
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.birthDate}
            name="birthDate"
            alert={errors.birthDate}
            hint="Optional: We use your date of birth to find other great Content/Offerings for you"
          />
        </div>
      </div>
      <div className="form__section">
        <h4 className="form__group-title">Contact information</h4>

        <div className="form__group">
          <PhoneListInput
            placeholder="Enter your Cell Phone"
            label="Cell Phone"
            onChange={(value) => setCustomValue('cellPhone', value)}
            onBlur={handleBlur}
            value={values.cellPhone}
            name="cellPhone"
            alert={errors.cellPhone}
            hint="Entering your phone number will allow us to contact you if such a need arises"
          />

          {/* <Input
            mask={defaultPhoneFormatMask}
            placeholder="Enter your Cell Phone"
            label="Cell Phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cellPhone}
            name="cellPhone"
            alert={errors.cellPhone}
            hint="Entering your phone number will allow us to contact you if such a need arises"
          /> */}

          {showPhoneCounter < 3 && (
            <Button
              className="form__button--add-phone"
              onClick={() => setShowPhoneCounter(showPhoneCounter + 1)}
              htmlType="button"
            >
              <Icon type="plusInCircle" size="md" />
            </Button>
          )}
        </div>

        {showPhoneCounter > 1 && (
          <div className="form__group">
            <PhoneListInput
              placeholder="Enter your Work Phone"
              label="Work Phone"
              onChange={(value) => setCustomValue('workPhone', value)}
              onBlur={handleBlur}
              value={values.workPhone}
              name="workPhone"
              alert={errors.workPhone}
            />
          </div>

          // <div className="form__group">
          //   <Input
          //     mask={defaultPhoneFormatMask}
          //     placeholder="Enter your Work Phone"
          //     label="Work Phone"
          //     onChange={handleChange}
          //     onBlur={handleBlur}
          //     value={values.workPhone}
          //     name="workPhone"
          //     alert={errors.workPhone}
          //   />
          // </div>
        )}

        {showPhoneCounter > 2 && (
          <div className="form__group">
            <PhoneListInput
              placeholder="Enter your Home Phone"
              label="Home Phone"
              onChange={(value) => setCustomValue('homePhone', value)}
              onBlur={handleBlur}
              value={values.homePhone}
              name="homePhone"
              alert={errors.homePhone}
            />
          </div>

          // <div className="form__group">
          //   <Input
          //     mask={defaultPhoneFormatMask}
          //     placeholder="Enter your Home Phone"
          //     label="Home Phone"
          //     onChange={handleChange}
          //     onBlur={handleBlur}
          //     value={values.homePhone}
          //     name="homePhone"
          //     alert={errors.homePhone}
          //   />
          // </div>
        )}
      </div>

      <div className="form__section">
        <h4 className="form__group-title">Emergency Contacts</h4>

        <div className="form__group">
          <Input
            placeholder="Enter Emergency Contact Name"
            label="Emergency Contact Name"
            onChange={handleChange}
            value={values.emergencyContactName}
            name="emergencyContactName"
            alert={errors.emergencyContactName}
          />
        </div>

        <div className="form__group">
          <PhoneListInput
            placeholder="Enter Emergency Contact Phone"
            label="Emergency Contact Phone"
            onChange={(value) => setCustomValue('emergencyContactPhone', value)}
            onBlur={handleBlur}
            value={values.emergencyContactPhone}
            name="emergencyContactPhone"
            alert={errors.emergencyContactPhone}
          />
        </div>

        {/* <div className="form__group">
          <Input
            mask={defaultPhoneFormatMask}
            placeholder="Enter Emergency Contact Phone"
            label="Emergency Contact Phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.emergencyContactPhone}
            name="emergencyContactPhone"
            alert={errors.emergencyContactPhone}
          />
        </div> */}

        <div className="form__group">
          <Input
            label="Health Concerns"
            placeholder="List your Health Concerns"
            onChange={handleChange}
            value={values.healthConcerns}
            name="healthConcerns"
            alert={errors.healthConcerns}
          />
        </div>
      </div>
      <div className="form__section">
        <h4 className="form__group-title required-mark">
          Have you taken classes here before?
        </h4>

        <div className="form__group form__group--radio">
          <Radio
            onChange={() => setCustomValue('tookClassesBefore', true)}
            value={values.tookClassesBefore === true}
            name="tookClassesBefore"
            label="Yes"
            className={!!errors.tookClassesBefore ? 'radio--has-error' : ''}
          />
          <Radio
            onChange={() => setCustomValue('tookClassesBefore', false)}
            value={values.tookClassesBefore === false}
            name="tookClassesBefore"
            label="No"
            className={!!errors.tookClassesBefore ? 'radio--has-error' : ''}
          />
          <ErrorMessage
            show={!!errors.tookClassesBefore}
            text={errors.tookClassesBefore}
          />
        </div>
      </div>

      <div className="form__section">
        <h4 className="form__group-title required-mark">
          How did you hear about us?
        </h4>

        <div className="form__group">
          <Select
            name="knownSource"
            placeholder="Select option"
            value={values.knownSource}
            options={surveys.data}
            onChange={(result) => setCustomValue('knownSource', result.id)}
            loading={surveys.loading}
            optionsLabel="option"
            optionsValue="id"
            optionsKey="id"
          />
          <ErrorMessage text={errors.knownSource} show={!!errors.knownSource} />
        </div>
      </div>

      <div className="form__section">
        <h4 className="form__group-title required-mark">
          Terms and Conditions
        </h4>

        <div className="form__group form__group--checkox">
          <Checkbox
            onChange={handleChange}
            value={values.agreePolicy}
            name="agreePolicy"
            label={
              <>
                I agree{' '}
                <Button
                  type="link"
                  htmlType="button"
                  onClick={onShowPolicyClick}
                >
                  Terms & Conditions and Privacy Policy
                </Button>
              </>
            }
            alert={errors.agreePolicy}
          />
        </div>
      </div>

      <div className="form__section">
        <h4 className="form__group-title required-mark">
          Media Consent and Waiver
        </h4>

        <InfoBox height={150} className="info-box--media-waiver">
          <p>
            I understand that occasional photography, video, and audio, may be 
            taken during classes/workshops/events, and used for archival, marketing, 
            and social media purposes. When used for archival and reselling of digital 
            recordings of classes/workshops/events (to provide residual income for 
            instructors/producers/faculty members, and for other charitable purposes) 
            a specific authorization in the form of explicit permission will be needed. 
            By signing the Media Consent and Waiver, I am giving permission only to be 
            recorded with photography, video, and/or audio during classes/workshops/events. 
            I understand that I may reserve the right not to sign the Media Consent and Waiver 
            or to change my preference at any time, in which case my image, and/or video, 
            and/or audio, will no longer be taken from the date I choose to decline 
            the Media and Consent Waiver.
          </p>
        </InfoBox>

        <div className="form__group form__group--radio">
          <Radio
            onChange={() => setCustomValue('mediaWaiver', true)}
            value={values.mediaWaiver === true}
            name="mediaWaiver"
            label="Yes"
            className={!!errors.mediaWaiver ? 'radio--has-error' : ''}
          />
          <Radio
            onChange={() => setCustomValue('mediaWaiver', false)}
            value={values.mediaWaiver === false}
            name="mediaWaiver"
            label="No"
            className={!!errors.mediaWaiver ? 'radio--has-error' : ''}
          />
          <ErrorMessage show={!!errors.mediaWaiver} text={errors.mediaWaiver} />
        </div>
      </div>

      <div className="form__section">
        <div className="form__group">
          <Checkbox
            onChange={handleChange}
            value={values.subscribeEmail}
            name="subscribeEmail"
            label="I want to receive BCCA/CCCA Email newsletters"
          />
        </div>

        <div className="form__group">
          <Checkbox
            onChange={handleChange}
            value={values.subscribeSnail}
            name="subscribeSnail"
            label="I want to receive BCCA/CCCA snail (standard post) mail"
          />
        </div>

        {values.subscribeSnail && (
          <>
            <div className="form__hint">
              Please provide us with your mailing address for if/when we send
              snail mail communications.
            </div>

            <div className="form__group">
              <Input
                label="Address"
                placeholder="Enter your Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
                name="address"
                alert={errors.address}
                required
              />
            </div>

            <div className="form__group">
              <Input
                label="City"
                placeholder="Enter your City"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                name="city"
                alert={errors.city}
                required
              />
            </div>

            <div className="form__group">
              <Select
                label="Country"
                options={countries.data}
                loading={countries.loading}
                value={values.country}
                placeholder="Select Country"
                onChange={(val) => setCustomValue('country', val.id)}
                optionsKey="id"
                optionsValue="id"
                optionsLabel="name"
                alert={errors.country}
                searchable
                required
              />
            </div>

            {showStates && !!states.data.length && (
              <div className="form__group">
                <Select
                  options={states.data}
                  value={values.state}
                  placeholder="Select State"
                  label="State"
                  onChange={(val) => setCustomValue('state', val.id)}
                  optionsKey="id"
                  optionsValue="id"
                  optionsLabel="name"
                  required
                  searchable
                  alert={errors.state}
                />
              </div>
            )}

            <div className="form__group">
              <Input
                label="Zip"
                placeholder="Enter Zip"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip}
                name="zip"
                alert={errors.zip}
                required
              />
            </div>
          </>
        )}
      </div>

      <div className="form__group">
        <Recaptcha
          render="explicit"
          sitekey="6LcP1N8ZAAAAAHRRH3qW4RBaKc7FogOa7EHq2wfb"
          verifyCallback={() => setCustomValue('isCaptchaPassed', 1)}
          expiredCallback={() => setCustomValue('isCaptchaPassed', '')}
        />
        
        <ErrorMessage
          text={errors.isCaptchaPassed}
          show={!!errors.isCaptchaPassed}
        />
      </div>
    </>
  );

  const renderLoginInputs = () => {
    return (
      <>
        <div className="form__group">
          <Input
            label="Email or Username"
            placeholder="Enter your Email or Username"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.login}
            name="login"
            alert={errors.login}
          />
        </div>

        <div className="form__group">
          <Input
            label="Password"
            placeholder="Enter your Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            password
            name="password"
            alert={errors.password}
          />
        </div>

        <div className="form__group login__switcher login__switcher--sign-in">
          <div className="login__switcher__block">
            <span>Don’t have an account?</span>
            <Button
              type="navigation"
              disabled={isLoading}
              onClick={onAuthBtnClick}
            >
              Sign up
            </Button>
          </div>

          <div className="login__switcher__block">
            <div>Returning Student or Forgot password?</div>
            <Button
              type="navigation"
              htmlType="button"
              className="login__forgot-password"
              onClick={onForgotPasswordClick}
            >
              Recover / Create Password
            </Button>
          </div>
        </div>

        {unloginCounter >= 3 &&    
          <div className="form__group">
            <Recaptcha
              render="explicit"
              sitekey="6LcP1N8ZAAAAAHRRH3qW4RBaKc7FogOa7EHq2wfb"
              verifyCallback={() => setCustomValue('isCaptchaPassed', 1)}
              expiredCallback={() => setCustomValue('isCaptchaPassed', '')}
            />

            {unloginCounter >= 4 &&
              <ErrorMessage
                text={errors.isCaptchaPassed}
                show={!!errors.isCaptchaPassed}
              />
            }
          </div>
        }
      </>
    )
  };

  return (
    <div className="registration-step">
      {<Loader show={isLoading} sticky/>}
      <div className="registration-step__container">
        <form className="form" onSubmit={handleSubmit}>
          <Icon
            type={isCourseReg ? 'registration-step' : 'login-welcome'}
            className="login__icon"
          />
          <h1 className="login__title">
            {isCourseReg ? 'Almost Done!' : 'Welcome!'}
          </h1>

          <div className="login__help">
            <span className="login__help-text">Need Registration Help?</span>
            <Tooltip position="bottom" className="login__tooltip">
              <span>
                For Log in, Sign up, or Registration assistance, please email
              </span>
              <a href="mailto:dan.yonah.marshalll@gmail.com">
                dan.yonah.marshall@gmail.com
              </a>
              <span>or call (617) 642-1444.</span>
            </Tooltip>
          </div>

          {isRegister ? renderRegisterInputs() : renderLoginInputs()}

          <div className="form__group form__group--login-buttons">
            <Button
              type="transparent"
              onClick={onBackBtnClick}
              htmlType="button"
              disabled={isLoading}
              iconLeft={<Icon type="chevron" />}
            >
              Back
            </Button>

            <Button 
              type="primary" 
              htmlType="submit" 
              disabled={isLoading}
              onClick={() => {
                if (!isRegister) {
                  errors && setUnloginCounter(unloginCounter + 1)
                }
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  btnCallbacks: PropTypes.object,
  isCourseReg: PropTypes.bool,
  setCurrentView: PropTypes.func,
  formCash: PropTypes.object,
  isPolicyAccepted: PropTypes.bool,
  setFormCash: PropTypes.func,
};

LoginForm.defaultProps = {
  btnCallbacks: {},
  isCourseReg: false,
  setCurrentView: () => {},
  setFormCash: () => {},
  formCash: {},
  isPolicyAccepted: false,
};