import React, { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MonthPicker } from '@mui/x-date-pickers';
import { YearPicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { getAllowedMonths, classNames } from '@src/utils';
import PropTypes from 'prop-types';
import { StaticCalendar, CalendarDropdownToolbar, CalendarDropdownDatePreview } from '@components/shared';

export const CalendarDropdown = ({ 
  slots,
  dayDate,
  setDate, 
  today, 
  selectedDay, 
  setSelectedDay, 
  isStaticCalendar,
  isAccountCalendar, 
  isScheduleDayCalendar,
  isScheduleWeekCalendar,
  pastEvents, 
  futureEvents 
}) => {
  const currentYear = new Date().getFullYear();
  // const yearDiff = currentYear - 2003;
  // const minDateYear = (yearDiff <= 21) ? 2003 : (2003 + (yearDiff - 21));
  const maxDateYear = currentYear + 1;
  const minDate = new Date('2003-01-01T00:00:00.000');
  const maxDate = new Date(`${maxDateYear}-01-01T00:00:00.000`);
  const allowedMonths = getAllowedMonths(slots);
  const [isDatePicker, setIsDatePicker] = useState(false);
  const [isMonthPicker, setIsMonthPicker] = useState(false);
  const [isYearPicker, setIsYearPicker] = useState(false);
  const isMobileDevice = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB10|Opera Mini/i.test(navigator.userAgent));

  const calendarPickerHandler = (inputState1, inputSetState1, inputState2, inputSetState2) => {
    if (!inputState1) {
      if(!inputState2) {
        inputSetState1(true)
      } else {
        inputSetState2(false)
        inputSetState1(true)
      }
    } else {
      inputSetState1(false)
    }
  };

  const windowCalendarPickerHandler = (e) => {
    if(
      !e.target.closest('.calendar-dropdown__date-preview') &&
      !e.target.closest('.calendar-dropdown__dropdown')
    ) {
      setIsDatePicker(false)
    }
  };

  useEffect(() => {
    window.addEventListener('click', windowCalendarPickerHandler);
    return () => window.removeEventListener('click', windowCalendarPickerHandler);
  });

  const dayPickerArr = document.querySelectorAll('.calendar-dropdown__date-picker .MuiButtonBase-root');
  dayPickerArr.forEach(day => {
    const dayWidth = day.clientWidth;
    day.style = `height: ${dayWidth}px`
  })

  return (
    <div className={classNames([
      'calendar-dropdown', 
      !isMonthPicker && 'calendar-dropdown--month-picker-hide',
      !isYearPicker && 'calendar-dropdown--year-picker-hide',
      !isDatePicker && 'calendar-dropdown--date-picker-hide'
    ])}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CalendarDropdownDatePreview 
          setMonth={(day) => setSelectedDay(day)} 
          allowedMonths={allowedMonths} 
          selectedDay={selectedDay}
          calendarHandler={() => calendarPickerHandler(isDatePicker, setIsDatePicker)} 
          isAccountCalendar={isAccountCalendar}
          isScheduleDayCalendar={isScheduleDayCalendar}
          isScheduleWeekCalendar={isScheduleWeekCalendar}
          dayDate={dayDate}
        />
      <div className="calendar-dropdown__dropdown">
        <div className="calendar-dropdown__toolbar-holder">
          <CalendarDropdownToolbar 
            setMonth={(day) => setSelectedDay(day)} 
            allowedMonths={allowedMonths} 
            selectedDay={selectedDay}
            monthHandler={() => calendarPickerHandler(isMonthPicker, setIsMonthPicker, isYearPicker, setIsYearPicker)}
            yearHandler={() => calendarPickerHandler(isYearPicker, setIsYearPicker, isMonthPicker, setIsMonthPicker)}
          />
        </div>
          <div className="calendar-dropdown__picker-holder">
            <div className='calendar-dropdown__date-picker'>
              <StaticCalendar 
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                calendarHandler={() => calendarPickerHandler(isDatePicker, setIsDatePicker)}
                minDate={minDate}
                maxDate={maxDate}
                setDate={setDate}
                pastEvents={pastEvents}
                futureEvents={futureEvents}
                isScheduleWeekCalendar={isScheduleWeekCalendar}
              />
            </div>
          
          <div className='calendar-dropdown__month-picker' >
            <MonthPicker
              date={selectedDay}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(day) => {
                setSelectedDay(day)
                calendarPickerHandler(isMonthPicker, setIsMonthPicker, isYearPicker, setIsYearPicker)
              }}
            />
          </div>
          
          <div className={'calendar-dropdown__year-picker'} >
            <YearPicker
              date={selectedDay}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(day) => {
                setSelectedDay(day)
                calendarPickerHandler(isYearPicker, setIsYearPicker, isMonthPicker, setIsMonthPicker)
              }}
            />
          </div>
        </div>
      </div>
      {isStaticCalendar &&
        <div className={classNames([
          'calendar-dropdown__static', 
          isMobileDevice && 'calendar-dropdown__static--mobile-only'
        ])}>
          <StaticCalendar 
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            minDate={minDate}
            maxDate={maxDate}
            setDate={setDate}
            pastEvents={pastEvents}
            futureEvents={futureEvents}
            today={today}
            isTransrormToTwoRows
          />
        </div>
      }
    </LocalizationProvider>
    </div>  
  );
}

CalendarDropdown.propTypes = {
  slots: PropTypes.array,
  onMonthChange: PropTypes.func,
  yearDiff: PropTypes.number,
  minDateYear: PropTypes.number,
  maxDateYear: PropTypes.number,
};

CalendarDropdown.defaultProps = {
  slots: [],
  onMonthChange: () => {},
};

