import { combineReducers } from 'redux';
import { scheduleReducer } from './scheduleReducer';
import { programsReducer } from './programsReducer';
import { facultyReducer } from './facultyReducer';
import { registrationReducer } from './registrationReducer';
import { authReducer } from './authReducer';
import { cookieReducer } from './cookieReducer';

const reducers = combineReducers({
  schedule: scheduleReducer,
  programs: programsReducer,
  faculty: facultyReducer,
  registration: registrationReducer,
  auth: authReducer,
  cookie: cookieReducer,
});

export { reducers };