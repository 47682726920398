import React, { useState } from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData, normalizeUserDataErrors } from '@src/normalizers';
import { Button, Checkbox, InfoBox, Tooltip, Link } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const MediaWaiver = ({ 
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const [isMediaWaiverShown, setIsMediaWaiverShown] = useState(false);
  const isMediaWaiver = editorState === 'mediaWaiver';

  const { student } = userData.data || {};
  const { is_media_consent_and_waiver_agreed } = student || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData, handleRequest, handleServerErrors));
  };

  const handleRequest = () => {
    setEditorState('');
  };

  const handleServerErrors = (errors) => {
    const { data } = errors;

    if (data?.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
  } = useForm({
    callback: submitHandler,
    schema,
  });

  const renderMediaWaiverNote = () => {
    return isMediaWaiver ? null : (
      <>
        You{' '}
        {is_media_consent_and_waiver_agreed
          ? 'already accepted'
          : 'did not accept'}{' '}
        the{' '}
        <Button
          onClick={() => setIsMediaWaiverShown(!isMediaWaiverShown)}
          className="account-profile__media-waiver-button"
          type="link"
          htmlType="button"
        >
          Media Waiver
        </Button>
        {isMediaWaiverShown && (
          <InfoBox height={150} className="info-box--media-waiver">
            <p>
              I understand that occasional photography, video, and audio, may be 
              taken during classes/workshops/events, and used for archival, marketing, 
              and social media purposes. When used for archival and reselling of digital 
              recordings of classes/workshops/events (to provide residual income for 
              instructors/producers/faculty members, and for other charitable purposes) 
              a specific authorization in the form of explicit permission will be needed. 
              By signing the Media Consent and Waiver, I am giving permission only to be 
              recorded with photography, video, and/or audio during classes/workshops/events. 
              I understand that I may reserve the right not to sign the Media Consent and Waiver 
              or to change my preference at any time, in which case my image, and/or video, 
              and/or audio, will no longer be taken from the date I choose to decline 
              the Media and Consent Waiver.
            </p>
          </InfoBox>
        )}
      </>
    );
  };

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href="mailto:dan.yonah.marshalll@gmail.com">
        dan.yonah.marshall@gmail.com
      </a>
      <span>or call (617) 642-1444.</span>
    </Tooltip>
  );

  const renderWaiverSuggestion = () => {
    if (!editorState) {
      return is_media_consent_and_waiver_agreed ? (
        <span className="account-profile__subcription-note">
          If you want to decline please{' '}
          <span className="account-profile__subcription-support">
            <Link href='https://cccaonline.org/contact-us/#contacts'>
              contact Support
            </Link>

            {renderSupportTooltip()}
          </span>
        </span>
      ) : (
        <span className="account-profile__subcription-note">
          If you want to accept please{' '}
          <Button onClick={() => setEditorState('mediaWaiver')} type="link">
            click here
          </Button>
        </span>
      );
    }
  };

  const mediaWaiverBlockData = [
    {
      label: 'I accept the Media Waiver',
      inputType: Checkbox,
      inputName: 'mediaWaiver',
    },
  ];

  const profileBlocksData = [
    {
      name: 'mediaWaiver',
      title: 'Media Consent and Waiver',
      data: isMediaWaiver ? mediaWaiverBlockData : [],
      infoText: renderMediaWaiverNote(),
      subscription: renderWaiverSuggestion(),
      editRestricted: true,
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};