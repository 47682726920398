import React from "react";
import { useSelector } from 'react-redux';
import { Programs } from '@components/features';

export const GlobalPrograms = () => {
  const tab = useSelector((state) => state.programs.tab);
  const filtersSelect = useSelector((state) => state.programs.filtersSelect);
  const courses = useSelector((state) => state.programs.courses);
  const sortingBy = useSelector((state) => state.programs.sortingBy);
  const sortingOrder = useSelector((state) => state.programs.sortingOrder);

  return (
    <Programs 
      className="global-programs"
      cardClassName="programs-course--programs"
      tab={tab}
      filtersSelect={filtersSelect}
      courses={courses}
      sortingBy={sortingBy}
      sortingOrder={sortingOrder}
    />
  );
};