import React, { useEffect, useState } from 'react';
import { Button, Icon } from '@components/shared';
import { classNames } from '@src/utils';
import { PayPalButtons, FUNDING } from '@paypal/react-paypal-js';
import { useSelector, useDispatch } from 'react-redux';
import { apiRequest, requestCallback, openLink, queryParam } from '@src/utils';
import { setPaymentLoading } from '@actions/registrationActions';

export const PaymentMethodButton = ({
  handleSubmit,
  values,
  validateOnSubmit,
  isFree = false,
}) => {
  const selectedPaymentMethod = useSelector(
    (state) => state.registration.paymentMethod
  );
  const [error, setError] = useState();
  const isVenmo = selectedPaymentMethod === 'paypal';
  const dispatch = useDispatch();

  const onVenmoClick = (data, actions) => {
    const hasErrors = validateOnSubmit && validateOnSubmit();
    if (hasErrors) {
      return actions.reject();
    } else {
      return actions.resolve();
    }
  };

  const renderButton = () => {
    switch (selectedPaymentMethod) {
      case 'payflow':
        return (
          <Button
            className="payment-action__payflow"
            type="primary"
            onClick={handleSubmit}
          >
            {isFree ? 'Proceed' : 'Credit/Debit Card or PayPal'}
          </Button>
        );
      case 'paypal':
        return (
          <PayPalButtons
            fundingSource={FUNDING.VENMO}
            onClick={onVenmoClick}
            style={{ shape: 'pill' }}
            forceReRender={[values]}
            createOrder={() => handleSubmit()}
            onApprove={async (data) => {
              await dispatch(setPaymentLoading(true));
              await apiRequest({
                url: `/paypal/${data.orderID}/capture?${
                  queryParam.get('error') ? 'error=true' : ''
                }`,
                method: 'POST',
                callbacks: requestCallback(
                  (res) => {
                    openLink(res.data.redirect_link, false);
                  },
                  (err) => {
                    const error = err?.response?.data?.description;
                    if (error) {
                      setError(error);
                    }
                    dispatch(setPaymentLoading(false));
                  }
                ),
              });
            }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="payment-action">
      <span className="payment-action__title">
        {isFree ? 'Proceed Free Registration' : 'Pay by'}:
      </span>
      <div
        className={classNames([
          'payment-action__button-wrapper',
          !isVenmo && 'payment-action__button-wrapper--venmo',
        ])}
      >
        {renderButton()}
      </div>
      {error && (
        <div className="popup">
          <div className="popup__overlay" onClick={(e) => setError('')}></div>
          <div className="popup__window">
            <Icon type="danger" className="popup__icon" />
            <div className="popup__text">
              <b>Payment Declined</b>
              <p>{error}</p>
            </div>

            <Button 
              className='popup__close-button'
              onClick={() => setError('')}
            >
              <Icon type="close-cross" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};