import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { 
  Button, 
  Icon, 
  CourseBadges, 
  Link,  
} from '@components/shared';
import { getImageUrl } from '@src/utils';
import { useViewport } from '@src/hooks';

export const CourseTitleBlockNew = ({ courseInfo }) => {
  const { label, title, id, is_private } = courseInfo || {};
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { isMobile } = useViewport();

  const isVideos = courseInfo?.videos.length !== 0;
  const isRegistrations = courseInfo?.sessions.some(
    ({
      drop_in_registration,
      block_registration,
      sliding_scale_registration,
      pre_registration,
      free_registration,
      available_tickets,
      trial_registration
    }) =>
      drop_in_registration ||
      block_registration ||
      sliding_scale_registration ||
      pre_registration ||
      free_registration ||
      available_tickets || 
      trial_registration
  );

  const history = useHistory();

  const onWatchVideoClick = () => {
    history.push(`${path}/${id}/multimedia`);
  };

  const onRegisterClick = () => {
    history.push(`${path}/${id}/schedule`);
  };

  const renderLogo =() => {
    return (
      <div className="offering-title-block__logo">
      <a href={courseInfo?.location.link} target="_blank">
        <img
          src={getImageUrl(courseInfo?.location.logo?.path)}
          height={isMobile ? "24" : "40"}
          alt="CCCA-logo"
        />
      </a>
    </div>
    );
  }

  const referrerPage = document.referrer;
  const isReferrerPage = 
    referrerPage.includes('account') || 
    referrerPage.includes('schedule') ||
    referrerPage.includes('programs');

  const defineReferrerPageType = () => {
    if (referrerPage.includes('account')) return 'Account'
    if (referrerPage.includes('schedule')) return 'Schedule'
    else return 'Programs'
  };

  const pageLink = () => {
    if (isReferrerPage) {
      return referrerPage
    } else {
      return `${window.location.origin}/programs`
    }
  };

  return (
    <div className="offering-details__title-block offering-title-block">
      <div className="offering-title-block__top">
        <div className="breadcrumbs-block offering-title-block__breadcrumbs">
          <Link 
            href={pageLink()}
            target=""
          >
            {defineReferrerPageType()}
          </Link>

          <Icon type="chevron" size="xs" />

          <span>Offering details</span>
        </div>
        {!isMobile && renderLogo()}
      </div>

      <div className="offering-title-block__middle">
        {label && <span className="offering-title-block__id">{label}</span>}
        {<CourseBadges course={courseInfo} />}
        {isMobile && renderLogo()}
      </div>

      <div className="offering-title-block__bottom">
        {title && 
          <h2 className="offering-title-block__title">
            {title}
          </h2>
        }   

        <div className="offering-title-block__actions">
          {isVideos && !pathname.includes('multimedia') &&        
            <Button
              className="offering-details__watch-button" 
              type="transparent"
              onClick={onWatchVideoClick}
            > 
              Watch the Video
            </Button>
          }

          {!pathname.includes('schedule') &&
            <Button
              className="offering-details__register-button" 
              type="primary"
              onClick={onRegisterClick}
            > 
              {!isRegistrations ? 
                'View Schedule' : 
                (is_private ? 'Pay Studio Fee(s)' : 'Register')
              }
            </Button>
          }

          {/* <Button>
            <Icon type="share-icon" size="xl" />
          </Button> */}
        </div>
      </div>
    </div>
  );
};
