import React from 'react';
import { enrollmentTypes } from '@src/constants';
import {
  getAges,
  getOptions,
  getCategories,
  getImageUrl,
} from '@src/utils';
import { Icon } from '@components/shared';

export const CourseDetailsContent = ({ data }) => {
  if (!data) return <p>No Data</p>;

  const {
    description,
    notes,
    participant_levels,
    participant_level_exception,
    participant_age_from,
    participant_age_to,
    participant_age_types,
    participant_age_exception,
    categories,
    type,
    profile_image_url,
    location,
    is_onsite,
  } = data;

  const { address } = location;
  const { address_1, address_2, city } = address;

  const levels = getOptions(participant_levels);
  const ageTypes = getOptions(participant_age_types);
  const categoriesToRender = getCategories(categories)
  const ageRange = participant_age_from || participant_age_to;

  return (
    <>
      {profile_image_url && (
        <div className="card-info__image-wrapper">
          <Icon
            type="faculty-decorative-item"
            className="icon--decorative-item"
          />
          <img className="card-info__image" src={profile_image_url} alt="" />
          <Icon
            type="faculty-decorative-item"
            className="icon--decorative-item"
          />
        </div>
      )}

      <div className="card-info__wrapper card-info__options">
        <div className="card-info__item card-info__item--full-width">
          <span className="card-info__title">Category(ies)</span>
          <span className="card-info__description">
            {categoriesToRender}
          </span>
        </div>

        {location && is_onsite && (
          <div className="card-info__item card-info__item--full-width">
            <span className="card-info__title">Location</span>
            <span className="card-info__description">
              {location.name} – {address_1}, {city}
            </span>
          </div>
        )}

        <div className="card-info__semicolumn">
          {levels && (
            <div className="card-info__item">
              <span className="card-info__title">Participant Level(s)</span>
              <span className="card-info__description">
                {levels}
                <sup>{participant_level_exception}</sup>
              </span>
            </div>
          )}

          {type && (
            <div className="card-info__item">
              <span className="card-info__title">Enrollment Type</span>
              <span className="card-info__description">
                {enrollmentTypes[type]}
              </span>
            </div>
          )}
        </div>

        <div className="card-info__semicolumn">
          {ageTypes && (
            <div className="card-info__item">
              <span className="card-info__title">Age Type(s)</span>
              <span className="card-info__description">{ageTypes}</span>
            </div>
          )}
          
          {ageRange && (
            <div className="card-info__item">
              <span className="card-info__title">Age Range</span>
              <span className="card-info__description">
                {`Ages ${getAges(participant_age_from, participant_age_to)}`}
                <sup>{participant_age_exception}</sup>
              </span>
            </div>
          )}
        </div>
      </div>

      {description && (
        <div className="card-info__wrapper">
          <span className="card-info__title">Description</span>
          <span
            className="card-info__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      )}

      {notes && (
        <div className="card-info__wrapper">
          <span className="card-info__title">Notes</span>
          <span
            className="card-info__description"
            dangerouslySetInnerHTML={{ __html: notes }}
          />
        </div>
      )}
    </>
  );
};