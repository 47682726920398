import React from "react";
import TextField from '@mui/material/TextField';
import { 
  PickersDay, 
  StaticDatePicker, 
} from '@mui/x-date-pickers';
import { 
  isWithinInterval, 
  startOfWeek, 
  endOfWeek, 
  format, 
  isBefore, 
  isSameMonth, 
  isSameDay 
} from 'date-fns';
import { classNames } from '@src/utils';

export const StaticCalendar = ({
  selectedDay, 
  setSelectedDay, 
  minDate, 
  maxDate, 
  setDate, 
  pastEvents, 
  futureEvents, 
  today, 
  calendarHandler,
  isTransrormToTwoRows,
  isScheduleWeekCalendar
}) => {
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!selectedDay) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(selectedDay);
    const end = endOfWeek(selectedDay);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <PickersDay
        {...pickersDayProps}
        className={classNames([
          dayIsBetween && !isFirstDay && !isLastDay && 'between-day',
          isFirstDay && 'first-day',
          isLastDay && 'last-day',
        ])} 
      >
        <p className='date'>{format(date, 'd')}</p>
      </PickersDay>
    );
  };

  return (
    <StaticDatePicker 
      date={selectedDay}
      views={['day']}
      minDate={minDate}
      maxDate={maxDate}
      showToolbar={false}
      onChange={(day) => {
        setSelectedDay(day)
        !isTransrormToTwoRows && calendarHandler()
      }}
      onMonthChange={setDate}
      onYearChange={setSelectedDay}
      showDaysOutsideCurrentMonth={true}
      renderInput={(params) => <TextField {...params} />}
      renderDay={isScheduleWeekCalendar ? 
        renderWeekPickerDay :
        (day, _value, DayComponentProps) => {
          const isSelected = !DayComponentProps.outsideCurrentMonth 
          const isPastEvent = pastEvents && pastEvents.includes(day.getDate());
          const isfutureEvent = futureEvents && futureEvents.includes(day.getDate());
          const isPastDays = isBefore(day, today);
          const isFutureMonth =  !isSameMonth(new Date(day), new Date(today));

          return (
            <PickersDay 
              {...DayComponentProps} 
              className={classNames([
                isSelected && isPastEvent && 'past-event',
                isSelected && isfutureEvent && 'future-event',
                isSelected && isPastDays && 'past',
                isSelected && isFutureMonth && 'future-month'
              ])} 
            >
              {isTransrormToTwoRows && 
                <>
                  <p className='week-day'>{format(day, 'E')}</p>
                  <p className='date'>{format(day, 'd')}</p>
                </>
              }
            </PickersDay>
          );
        }
      }
    />
  );
};