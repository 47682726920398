import React from 'react';
import { Button, Icon } from '../index';
import { format, addMonths, subMonths, isSameMonth } from 'date-fns';

export const DayPickerNavBar = ({ date, setMonth, allowedMonths }) => {
  const nextMonth = addMonths(new Date(date), 1);
  const prevMonth = subMonths(new Date(date), 1);

  const isAllowedMonth = (allowedMonths, month, specificMonth) => {
    return !allowedMonths.length || allowedMonths.some((month) => isSameMonth(new Date(specificMonth), new Date(month)));
  }

  const onNextMonth = (month) => {
    if (isAllowedMonth(allowedMonths, month, nextMonth)) {
      setMonth(nextMonth);
    }
  };

  const onPrevMonth = (month) => {
    if (isAllowedMonth(allowedMonths, month, prevMonth)) {
      setMonth(prevMonth);
    }
  };

  return (
    <div className="DayPicker-Caption">
      <div className="DayPicker-Caption__navigation">
        {
          isAllowedMonth(allowedMonths, date, prevMonth) && (
            <Button
              onClick={() => onPrevMonth(date)}
              htmlType="button"
              className="DayPicker-Caption__navigation-btn DayPicker-Caption__navigation-btn--left DayPicker-Caption__navigation-btn--margin-right"
            >
              <Icon type="chevron" />
            </Button>
          )
        }
        <div className="DayPicker-Caption__date-preview">
          <Icon
            type="calendar"
            className="DayPicker-Caption__date-preview-icon"
          />
          <span className="DayPicker-Caption__date-preview-content">
            {format(date, 'MMMM yyyy')}
          </span>
        </div>
        {
          isAllowedMonth(allowedMonths, date, nextMonth) && (
            <Button
              onClick={() => onNextMonth(date)}
              htmlType="button"
              className="DayPicker-Caption__navigation-btn DayPicker-Caption__navigation-btn--right DayPicker-Caption__navigation-btn--margin-left"
            >
              <Icon type="chevron" />
            </Button>
          )
        }
      </div>
    </div>
  );
};