import React from 'react';
import { Button, Icon } from '../index';
import { format, addMonths, subMonths, isSameMonth } from 'date-fns';

export const CalendarDropdownToolbar = ({ selectedDay, setMonth, allowedMonths, monthHandler, yearHandler }) => {

  const nextMonth = addMonths(new Date(selectedDay), 1);
  const prevMonth = subMonths(new Date(selectedDay), 1);

  const isAllowedMonth = (allowedMonths, month, specificMonth) => {
    return !allowedMonths.length || allowedMonths.some((month) => isSameMonth(new Date(specificMonth), new Date(month)));
  }

  const onNextMonth = (month) => {
    if (isAllowedMonth(allowedMonths, month, nextMonth)) {
      setMonth(nextMonth);
    }
  };

  const onPrevMonth = (month) => {
    if (isAllowedMonth(allowedMonths, month, prevMonth)) {
      setMonth(prevMonth);
    }
  };

  return (
    <div className="calendar-dropdown__toolbar">
      <div className="toolbar">
        <div className="toolbar__date-preview">
          <Button
            onClick={monthHandler}
            className="toolbar__date-preview-month"
            iconRight={<Icon type="chevron" size="sm" />}
          >              
            {format(selectedDay, 'MMMM')}
          </Button>
          <Button
            onClick={yearHandler}
            className="toolbar__date-preview-year"
            iconRight={<Icon type="chevron" size="sm" />}
          >              
            {format(selectedDay, 'yyyy')}
          </Button>
        </div>
        <div className="toolbar__button-block">
          {isAllowedMonth(allowedMonths, selectedDay, prevMonth) && (
              <Button
                onClick={() => onPrevMonth(selectedDay)}
                className="toolbar__button-prev"
              >              
                <Icon type="chevron" size="sm" />
              </Button>
          )}
          {isAllowedMonth(allowedMonths, selectedDay, nextMonth) && (
              <Button
                onClick={() => onNextMonth(selectedDay)}
                className="toolbar__button-next"
              >
                <Icon type="chevron" size="sm" />
              </Button>
          )}
        </div>
      </div>
    </div>
  );
};
