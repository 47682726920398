import React from 'react';
import { checkoutDateFormat } from '@src/constants';
import { format } from 'date-fns';
import { getTwelveHoursPeriod } from '@src/utils';

export const CheckoutTableMobile = ({ appointments = [] }) => {
  return (
    <div className="checkout-table checkout-table--mobile-only">
      {appointments.map((appointment, index) => {
        const { class_date, schedule, price, discount, increase } = appointment;
        const { studio_name, start_time, end_time } = schedule;
        
        return (
          <div className="checkout-table__block" key={index}>
            <div className="checkout-table__row">
              <p className="checkout-table__value">
                {format(new Date(class_date), checkoutDateFormat)}
              </p>
            </div>
            <div className="checkout-table__row">
              <p className="checkout-table__label">Time</p>
              <p className="checkout-table__value">{getTwelveHoursPeriod(start_time, end_time)}</p>
            </div>
            <div className="checkout-table__row">
              <p className="checkout-table__label">Studio(s)</p>
              <p className="checkout-table__value">
                {studio_name}
              </p>
            </div>
            <div className="checkout-table__row">
              <p className="checkout-table__label">Price</p>
              <p className="checkout-table__value">${price}</p>
            </div>
            {!!discount && <div className="checkout-table__row">
              <p className="checkout-table__label">Discount</p>
              <p className="checkout-table__value">-${discount}</p>
            </div>}
            {!!increase && <div className="checkout-table__row">
              <p className="checkout-table__label">Increase</p>
              <p className="checkout-table__value">${increase}</p>
            </div>}
          </div>
        );
      })}
    </div>
  );
};
