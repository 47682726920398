import React from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData, normalizeUserDataErrors } from '@src/normalizers';
import { Button, Checkbox, Tooltip, Link } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const EmailSubcription = ({ 
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const isEmailSubcription = editorState === 'emailSubscription';
  const { student } = userData.data || {};
  const {
    receive_email_newsletters,
  } = student || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData, handleRequest, handleServerErrors));
  };

  const handleRequest = () => {
    setEditorState('');
  };

  const handleServerErrors = (errors) => {
    const { data } = errors;

    if (data?.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
  } = useForm({
    callback: submitHandler,
    schema,
  });

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href="mailto:dan.yonah.marshalll@gmail.com">
        dan.yonah.marshall@gmail.com
      </a>
      <span>or call (617) 642-1444.</span>
    </Tooltip>
  );

  const renderSubcriptionSuggestion = (isSubscriber, blockName) => {
    if (!editorState) {
      return isSubscriber ? (
        <span className="account-profile__subcription-note">
          If you want to unsubscribe please{' '}
          <span className="account-profile__subcription-support">
            <Link href='https://cccaonline.org/contact-us/#contacts'>
              contact Support
            </Link>

            {renderSupportTooltip()}
          </span>
        </span>
      ) : (
        <span className="account-profile__subcription-note">
          If you want to subscribe please{' '}
          <Button onClick={() => setEditorState(blockName)} type="link">
            click here
          </Button>
        </span>
      );
    }
  };

  const emailSubscriptionBlockData = [
    {
      label: 'I want to receive BCCA/CCCA Email Newsletters',
      inputType: Checkbox,
      inputName: 'emailSubscription',
    },
  ];

  const profileBlocksData = [
    {
      title: 'BCCA/CCCA Email Newsletters',
      data: isEmailSubcription ? emailSubscriptionBlockData : [],
      infoText: receive_email_newsletters
        ? 'You’re already subscribed to our Email Newsletters.'
        : 'You are not subscribed to our Email Newsletters.',
      subscription: renderSubcriptionSuggestion(
        receive_email_newsletters,
        'emailSubscription'
      ),
      name: 'emailSubscription',
      editRestricted: true,
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};