import React, { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import DayPicker from 'react-day-picker';
import { fullWeekDays, shortWeekDays } from '@src/constants';
import { DayPickerNavBar } from '@components/shared';
import { getAllowedMonths, getDisabledDays } from '@src/utils';
import { useViewport } from '@src/hooks';

export const EventsCalendar = ({ slots, onDayClick, selectedDays, modifiers, onMonthChange, isLoading }) => {
  const allowedMonths = getAllowedMonths(slots);
  const disabledDays = getDisabledDays(slots, allowedMonths);
  const initialMonth = allowedMonths.length ? new Date(allowedMonths[0]) : selectedDays[0];
  const [month, setMonth] = useState(initialMonth);
  const { isMobile } = useViewport();

  useEffect(() => {
    onMonthChange(month)
  }, [month])

  return (
    <DayPicker
      className="events-calendar"
      month={month}
      weekdaysShort={isMobile ? shortWeekDays : fullWeekDays}
      disabledDays={disabledDays}
      captionElement={
        <DayPickerNavBar setMonth={setMonth} allowedMonths={allowedMonths} />
      }
      selectedDays={selectedDays}
      enableOutsideDaysClick={false}
      onDayClick={onDayClick}
      modifiers={modifiers}

    />
  );
};

EventsCalendar.defaultProps = {
  slots: [],
  onMonthChange: () => {}
};