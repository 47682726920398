const getMainCategoriesString = (categories = []) => {
  const exceptionNames = [
    'Sessions',
    'Labs',
    'Open Mics',
    'Celebrations',
    'Release Parties',
    'Screenings',
  ];
  const indexOfException = categories.findIndex((item) =>
    exceptionNames.includes(item)
  );

  const exception =
    indexOfException > -1 ? categories.splice(indexOfException, 1) : null;

  if (categories.length <= 1) {
    exception && categories.push(exception);
    return categories.join(' ');
  } else if (categories.length === 2) {
    return `${categories.join(' and ')} ${exception ? ` ${exception[0]}` : ''}`;
  } else if (categories.length > 2) {
    const lastCategoryName = categories.slice(-1);
    categories[categories.length - 1] = `and ${lastCategoryName}`;
    return categories.join(', ') + (exception ? ` ${exception[0]}` : '');
  }
};

export const getCategories = (categories = []) => {
  const subCategoriesArray = categories
    .sort((a, b) => b.level - a.level)
    .filter(({ level }) => level > 0)
    .map((item) => item.name);

  const subCategoriesString = [...new Set(subCategoriesArray)]
    .map((name) => `${name} |`)
    .join(' ');

  const mainCategories = categories
    .filter(({ level }) => level === 0)
    .map(({ name }) => name);

  const mainCategoriesString = getMainCategoriesString(mainCategories);

  return `${subCategoriesString} ${mainCategoriesString}`;
};
