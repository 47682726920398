import { apiRequest, requestCallback, openLink } from '@src/utils';
import { store } from '@src/store';
import * as types from '@types/registrationTypes';

const getPaymentEndpoint = (paymentPurpose) => {
  switch (paymentPurpose) {
    case 'donation':
      return '/donations';
    case 'drop-in':
      return `/orders/drop-in-registration`;
    case 'block':
      return `/orders/block-registration`;
    case 'tickets':
      return `/tickets/purchase`;
    case 'pre-registration':
      return `/orders/pre-registration`;
    case 'videos':
      return `/orders/video-purchase/place`;
    case 'sliding-scale':
      return `/orders/sliding-scale-registration`;
    case 'free':
      return `/orders/free-registration`;
    case 'trial':
      return `/orders/trial-registration`;
    default:
      return '';
  }
};

const paymentCallback = (paymentMethod, data) => {
  store.dispatch({ type: types.PAYMENT_LOADING, payload: false });
  switch (paymentMethod) {
    case 'paypal':
      return data.id;
    case 'payflow':
      return openLink(data.redirect_link, false);
    default:
      return null;
  }
};

export const registrationPaymentRequest = (data, paymentPurpose) => {
  const paymentMethod = store.getState().registration.paymentMethod;
  data.payment_gateway = paymentMethod;
  store.dispatch({ type: types.PAYMENT_LOADING, payload: true });
  return apiRequest({
    url: getPaymentEndpoint(paymentPurpose),
    method: 'POST',
    data: JSON.stringify(data),
    callbacks: requestCallback((res) =>
      paymentCallback(paymentMethod, res.data)
    ),
  });
};

export const checkoutPaymentRequest = (orderId) => {
  const paymentMethod = store.getState().registration.paymentMethod;
  store.dispatch({ type: types.PAYMENT_LOADING, payload: true });
  return apiRequest({
    url: `/orders/${orderId}/purchase`,
    method: 'POST',
    data: JSON.stringify({payment_gateway: paymentMethod}),
    callbacks: requestCallback((res) =>
      paymentCallback(paymentMethod, res.data)
    ),
  });
};
