export const IS_AUTHORIZED = `IS_AUTHORIZED`;

export const USER_DATA = `USER_DATA`;
export const USER_DATA_LOADING = `USER_DATA_LOADING`;
export const USER_DATA_UPDATE_LOADING = `USER_DATA_UPDATE_LOADING`;
export const USER_DATA_ERROR = `USER_DATA_ERROR`;
export const GLOBAL_LOADING = `GLOBAL_LOADING`;

export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_LOADING = "LOGIN_DATA_LOADING";
export const LOGIN_DATA_ERROR = "LOGIN_DATA_ERROR";