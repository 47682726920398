import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CourseTitleBlock,
  CourseDetailsContent,
  CourseDetailsHeader,
} from '@components/modals';
import { Sessions, VideoGallery } from '@components/features';
import { queryParam, openLink } from '@src/utils';
import { 
  getModal, 
  Loader, 
  openModal, 
} from '@components/shared';
import { useApi } from '@src/hooks';
import Videos from '@components/features/Videos/Videos';
import { useSelector } from 'react-redux';

export const CourseDetailsModal = ({ eventId }) => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  const courseInfo = useApi({
    url: `/courses/${eventId}`,
  });
  const title = courseInfo?.data?.title;

  const purchasedVideos = useApi({
    url: isAuthorized && `/courses/${eventId}/purchased-videos`,
  });

  useEffect(() => {
    queryParam.set('classId', eventId);
    
    return () => {
      queryParam.delete('classId');
      queryParam.delete('activeTab');
    };
  }, []);

  const onRegisterClick = (sessionId) => {
    openModal(
      getModal({
        name: 'registration',
        data: {
          classId: eventId,
          sessionId,
        },
      })
    );
  };

  const onPayToWatchClick = (sessionId) => {
    openModal(
      getModal({
        name: 'registration',
        data: {
          classId: eventId,
          sessionId,
          initialStep: 3,
          initialType: 'v'
        },
      })
    );

    window._mtm = window._mtm || [];
    window._mtm.push({'event': 'ptw_popup', 'video_popup': `${title}`});
  };

  const onViewAccointClick = () => {
    openLink(`${window.location.origin}/account/videos`);
  };

  const onTrialClick = (sessionId) => {
    openModal(
      getModal({
        name: 'registration',
        data: {
          classId: eventId,
          sessionId,
          initialStep: 3,
          initialType: 'tr'
        },
      })
    );
  };

  const freeVideos = courseInfo?.data?.videos?.free;
  const paidVideos = courseInfo?.data?.videos?.paid;
  const purchasedVideosData = purchasedVideos.data;

  const purchasedVideosId = purchasedVideosData && purchasedVideosData.map(element => element.id);
  const availablePaidVideos = paidVideos && paidVideos.filter(e => purchasedVideosId && !purchasedVideosId.includes(e.id)).map(e => e);
  const purchasedPaidVideos = paidVideos && paidVideos.filter(e => purchasedVideosId && purchasedVideosId.includes(e.id)).map(e => e);

  return (
    <div className="class-details">
      {courseInfo.loading ? (
        <Loader attached />
      ) : (
        <>
          <CourseDetailsHeader courseInfo={courseInfo.data} />
          <CourseDetailsContent data={courseInfo.data} />
          <Sessions
            className="sessions--offering-details"
            onRegisterClick={onRegisterClick}
            onTrialClick={onTrialClick}
            classId={eventId}
            contentType={courseInfo.data?.content_type}
            isPrivate = {courseInfo.data?.is_private && true}
          />
          {freeVideos && <VideoGallery videos={freeVideos} />}

          {!isAuthorized && paidVideos && paidVideos.length > 0 &&
            <Videos videos={paidVideos} videosHandler={onPayToWatchClick} noAuthorized />
          }

          {isAuthorized && availablePaidVideos && availablePaidVideos.length > 0 && 
            <Videos videos={availablePaidVideos} videosHandler={onPayToWatchClick} />
          }

          {isAuthorized && purchasedPaidVideos && purchasedPaidVideos.length > 0 && 
            <Videos videos={purchasedPaidVideos } videosHandler={onViewAccointClick} isPurchased />
          }
        </>
      )}
    </div>
  );
};

CourseTitleBlock.propTypes = {
  cardInfo: PropTypes.shape({
    cardId: PropTypes.string,
    cardTitle: PropTypes.string,
    cardSubtitle: PropTypes.string,
  }),
  btnCallback: PropTypes.func,
};

CourseTitleBlock.defaultProps = {
  cardInfo: {
    cardId: '',
    cardTitle: '',
    cardSubtitle: '',
  },
  btnCallback: () => {},
};