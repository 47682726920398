import React, { useEffect, useState } from 'react';
import { parseNoSpaces, parseOnlyLettersAndNumbers, useForm } from '@src/hooks';
import {
  Button,
  Input,
  Icon,
  closeModal,
  getModal,
  openModal,
  Tooltip,
  Loader,
} from '@components/shared';
import { queryParam, apiRequest, requestCallback } from '@src/utils';

export const PasswordRecovery = ({ setCurrentView, proceedLink }) => {
  const [recoveryStep, setRecoveryStep] = useState(1);
  const [resendTimer, setResendTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initRecoveryStep = queryParam.get('passRecoveryStep');
    if (initRecoveryStep) {
      setRecoveryStep(+initRecoveryStep);
    }
    return () => {
      queryParam.delete(['passRecoveryStep', 'email', 'token']);
    };
  }, []);

  useEffect(() => {
    let timer = null;

    if (resendTimer) {
      timer = setTimeout(() => {
        setResendTimer((value) => value - 1);
      }, 1000);
    }

    if (resendTimer === 0) {
      setResendTimer(null);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  useEffect(() => {
    queryParam.set('passRecoveryStep', recoveryStep);
  }, [recoveryStep]);

  const onEmailSubmit = async () => {
    setIsLoading(true);
    let data = { email: values.email };

    if (proceedLink) {
      data = {
        ...data,
        redirect_to: proceedLink,
      };
    }

    await apiRequest({
      url: '/auth/password/email',
      method: 'POST',
      data: JSON.stringify(data),
      callbacks: requestCallback(
        () => setRecoveryStep(2),
        (err) => {
          if (err?.response?.data?.errors?.email) {
            const error = err.response.data.errors.email[0];
            setErrors({ email: error });
          }
        }
      ),
    });
    setResendTimer(60);
    setIsLoading(false);
  };

  const onNewPasswordSubmit = async () => {
    setIsLoading(true);
    const data = {
      email: encodeURI(queryParam.get('email')),
      password: values.password,
      password_confirmation: values.passwordConfirm,
      token: encodeURI(queryParam.get('token')),
    };
    await apiRequest({
      url: '/auth/password/reset',
      method: 'POST',
      data: JSON.stringify(data),
      callbacks: requestCallback(
        () => setRecoveryStep(4),
        (err) => err && handleServerErrors(err.response)
      ),
    });
    setIsLoading(false);
  };

  const handleServerErrors = (err) => {
    const { data, status } = err;
    const linkExpired =
      status === 422 &&
      data?.errors?.token[0] === 'The token field is incorrect';

    if (linkExpired) {
      const notificationData = {
        linkType: 'password',
        email: encodeURI(queryParam.get('email')),
        redirect_to: encodeURI(queryParam.get('redirect_to') || ''),
      };
      closeModal('registration');
      openModal(
        getModal({
          name: 'expired-link-notification',
          data: notificationData,
        })
      );
    }
  };

  let schema = {
    email: {
      parse: parseNoSpaces,
      validation: ['email', 'isRequired'],
    },
  };

  if (recoveryStep === 3) {
    schema = {
      password: {
        validation: ['isRequired', 'password'],
        parse: parseOnlyLettersAndNumbers,
      },
      passwordConfirm: {
        parse: parseOnlyLettersAndNumbers,
        customValidation: [
          {
            check: (value) => value,
            message: 'Please repeat your password',
          },
          {
            check: (value, values) =>
              values.password ? value === values.password : true,
            message: 'Passwords do not match. Please repeat.',
          },
        ],
      },
    };
  }

  const submitHandler = (values) => {
    switch (recoveryStep) {
      case 1:
        return onEmailSubmit();
      case 2:
        return onEmailSubmit();
      case 3:
        return onNewPasswordSubmit();
      default:
        return () => {};
    }
  };

  const { values, errors, handleChange, handleSubmit, setErrors, handleBlur } =
    useForm({
      callback: submitHandler,
      schema,
    });

  const stepsNavigation = {
    1: {
      body: (
        <>
          <div className="password-recovery__text">
            Please enter the email address you used upon sign up.
          </div>
          <Input
            label="Email"
            placeholder="Enter your Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            name="email"
            alert={errors.email}
            required
          />
          <div className="password-recovery__actions">
            <Button
              type="transparent"
              onClick={() => setCurrentView('loginForm')}
              htmlType="button"
              iconLeft={<Icon type="chevron" />}
            >
              Login
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </>
      ),
    },
    2: {
      body: (
        <>
          <div className="password-recovery__text">
            The Password Recovery Email has been sent.
          </div>
          <div className="password-recovery__text">
            Please check your inbox (or spam folder) for further instructions
            from
            <span className="password-recovery__email">
              <a href="mailto:staff@cccaonline.org">staff@cccaonline.org</a>
            </span>
          </div>
          <div className="password-recovery__actions">
            <Button
              type="transparent"
              onClick={() => setCurrentView('loginForm')}
              htmlType="button"
              iconLeft={<Icon type="chevron" />}
            >
              Login
            </Button>
          </div>
          <div className="password-recovery__resend-email">
            <div className="password-recovery__text">
              Haven't received an email?
            </div>
            <Button type="primary" htmlType="submit" disabled={!!resendTimer}>
              Resend {resendTimer && <>{resendTimer}s</>}
            </Button>
          </div>
        </>
      ),
    },
    3: {
      body: (
        <div>
          <Input
            label="New Password"
            placeholder="Enter your new password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            name="password"
            alert={errors.password}
            required
            password
            hint="Password should include at least 6 characters, both uppercase and lowercase letters and at least one number"
          />
          <Input
            label="Repeat Password"
            placeholder="Repeat your new password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirm}
            name="passwordConfirm"
            alert={errors.passwordConfirm}
            required
            password
          />
          <div className="password-recovery__actions">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </div>
      ),
    },
    4: {
      body: (
        <div>
          <div className="password-recovery__text">
            Congratulations! Your new password has been set.
          </div>
          <div className="password-recovery__actions">
            <Button type="primary" onClick={() => setCurrentView('loginForm')}>
              Got It
            </Button>
          </div>
        </div>
      ),
    },
  };

  return (
    <>
      <Loader show={isLoading} sticky />
      <form
        className={`password-recovery password-recovery--step${recoveryStep}`}
        onSubmit={handleSubmit}
      >
        <div className="password-recovery__banner">
          <Icon type="password-recovery" />
          <h1 className="password-recovery__title">
            Recover / Create
            <br />
            Password
          </h1>
        </div>
        {recoveryStep < 4 && (
          <div className="login__help">
            <span className="login__help-text">Need Registration Help?</span>
            <Tooltip position="bottom" className="login__tooltip">
              <span>
                For Log In, Sign Up, or Registration assistance, please email
              </span>
              <a href="mailto:dan.yonah.marshalll@gmail.com">
                dan.yonah.marshall@gmail.com
              </a>
              <span>or call (617) 642-1444.</span>
            </Tooltip>
          </div>
        )}
        <div className="password-recovery__body">
          {stepsNavigation[recoveryStep].body}
        </div>
      </form>
    </>
  );
};

PasswordRecovery.defaultProps = {
  initialStep: 1,
};