import React from 'react';
import { 
  Button, 
  Icon, 
  getModal, 
  openModal,
  Link  
} from '@components/shared';
import {
  openLink,
  formatTimeDate,
  openPdf,
  downloadPdf,
  zonedDate,
  formatPrice,
  getImageUrl,
  apiRequest,
  requestCallback,
} from '@src/utils';
import {
  twelveHoursFormat,
  splitSign,
  userTicketsDateFormat,
  ticketDateFormat,
} from '@src/constants';
import { format } from 'date-fns';
import { useViewport } from '@src/hooks';

export const AccountTicketsCard = ({ ticketData }) => {
  const { isMobile } = useViewport();
  const {
    id,
    title,
    course,
    instructor,
    count,
    date_of_purchase,
    price,
    schedules,
    attendees,
    special_notes_and_directions,
  } = ticketData;

  const receiptId = id;

  const onReceiptButtonClick = () => {
    openModal(
      getModal({
        name: 'receipt-slider',
        data: { receiptId },
      })
    );
  };

  const onDirectionsButtonClick = () => {
    openModal(
      getModal({
        name: 'directions',
        data: { id, special_notes_and_directions },
      })
    );
  };

  const getTicketPdf = (id, callback) => {
    apiRequest({
      url: `account/tickets/${id}/pdf`,
      callbacks: requestCallback(({ data }) => {
        callback(data);
      }),
    });
  };

  const onPrintTicketClick = () => {
    getTicketPdf(id, openPdf);
  };

  const onSaveTicketClick = () => {
    getTicketPdf(id, downloadPdf);
  };

  const onOfferingTitleClick = (eventId) => {
    openModal(
      getModal({
        name: 'class-details',
        data: { eventId },
      })
    );

    window._mtm = window._mtm || [];
    window._mtm.push({
      'event': 'view_item',
      'ecommerce': {
        'view_item': {
          'id': `${ticketData?.course?.id}`,
          'item_name': `${ticketData?.course?.title}`,
          'item_category': 'category value',
          'price': 'price value',
          }
        }
    });
  };

  const getScheduleTime = (start_time, end_time) => {
    const formattedStart = formatTimeDate(
      start_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    const formattedEnd = formatTimeDate(
      end_time,
      splitSign,
      twelveHoursFormat
    ).toLowerCase();

    return `${formattedStart} - ${formattedEnd}`;
  };

  const formattedPrice = parseFloat(price) ? `$${formatPrice(price)}` : 'Free';
  const formattedMobilePrice =
    formattedPrice === 'Free' ? 'Free' : `${formattedPrice}/ticket`;
    
  return (
    <div className='account-tickets-card'>
      <div className="account-tickets-card__ticket-title">{title}</div>
      <div className="account-tickets-card__purchase-date">
        Date of purchase:{' '}
        {format(zonedDate(date_of_purchase), ticketDateFormat)}
      </div>
      <div className="account-tickets-card__offering-title">
        {/* <Link 
          onClick={() => onOfferingTitleClick(course.id)}
          target=""
        >
          {course.title}
          <span className='account-tickets-card__offering-id'>
            ({course.label}) <span><Icon type="proceed-next" /></span>
          </span>
        </Link> */}

        <Button 
          onClick={() => onOfferingTitleClick(course.id)}
        >
          {course.title}
          <span className="account-tickets-card__offering-id">
            ({course.label}) <span><Icon type="proceed-next" /></span>
          </span>
        </Button>
      </div>
      <div className="account-tickets-card__count">
        # of Tickets: {count} {isMobile && `(${formattedMobilePrice})`}
      </div>
      <div className="account-tickets-card__offering-instructor">
        <Link 
          href={`${window.location.origin}/faculty/${instructor.id}`}
        >
          {instructor.display_name}
        </Link>
      </div>
      <div className="account-tickets-card__schedule">
        {!isMobile && (
          <>
            <span className="account-tickets-card__date-label">Date(s)</span>
            <span className="account-tickets-card__time-label">Time(s)</span>
            <span className="account-tickets-card__studio-label">Studio(s)</span>
          </>
        )}
        {schedules.map(({ date, start_time, end_time, studios }) => (
          <React.Fragment key={date + start_time + end_time}>
            <span className="account-tickets-card__date-item">
              {format(zonedDate(date), userTicketsDateFormat)}
            </span>
            <span className="account-tickets-card__time-item">
              {getScheduleTime(start_time, end_time)}
            </span>
            <span className="account-tickets-card__studio-item">
              {studios.map(({ name }, i) =>
                ++i === studios.length ? name : name + ', '
              )}
            </span>
          </React.Fragment>
        ))}
      </div>
      <div className="account-tickets-card__attendees">
        Attendees{' '}
        <span>
          {attendees.length
            ? attendees.map((name, i) =>
                ++i === attendees.length ? name : name + ', '
              )
            : 'Not specified'}
        </span>
      </div>
      <div className='account-tickets-card__actions'>
        {special_notes_and_directions && (
          <Button
            onClick={onDirectionsButtonClick}
          >
            {!isMobile ? 'Special Directions' : 'Directions'}
          </Button>
        )}

        {price !== 0 && 
          <Button
            onClick={onReceiptButtonClick}
          >
            Receipt
          </Button>
        }
      </div>
      {!isMobile && (
        <div className="account-tickets-card__additional">
          <div className="account-tickets-card__file-actions">
            <Button onClick={onPrintTicketClick}>
              <Icon type="printer" size="lg" />
            </Button>

            <Button onClick={onSaveTicketClick}>
              <Icon type="download" size="lg" />
            </Button>
          </div>

          <div className="account-tickets-card__price">
            <b>{formattedPrice}</b>
            {formattedPrice !== 'Free' && 'per ticket'}
          </div>

          <div className="account-tickets-card__logo">
            <a href={course.location.link} target="_blank">
              <img
                src={getImageUrl(course.location.logo?.path)}
                alt="Logo"
                height={isMobile ? '20' : '30'}
              />
            </a>
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <div className="account-tickets-card__logo">
            <Link href={course.location.link}>
              <img
                src={getImageUrl(course.location.logo?.path)}
                alt="Logo"
                height={isMobile ? '20' : '30'}
              />
            </Link>
          </div>
          <div className="account-tickets-card__file-actions">
            <Button>
              <Icon type="download" size="lg" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
