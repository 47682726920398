import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWeekTable, setDayDate } from '@actions/scheduleActions';
import { WeekViewTable } from '../weekView/WeekViewTable';
import { ScheduleError } from '../ScheduleError';
import { setWeekDateQuery, WeekPagination } from '../weekView';
import { useHistory } from 'react-router-dom';
import { Loader, NothingFound, TableCell } from '@components/shared';
import { NavigationPanel } from '../Navigation';

export const Week = ({ onCardClick }) => {
  const weekDate = useSelector(state => state.schedule.weekDate);
  const weekTable = useSelector(state => state.schedule.weekTable);
  const filtersSelect = useSelector(state => state.schedule.filtersSelect);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getWeekTable(weekDate, filtersSelect));
    setWeekDateQuery(weekDate);
  }, [weekDate, filtersSelect]);

  const onWeeklyDayClick = day => {
    dispatch(setDayDate(day));
    history.push('/schedule/day');
  };

  const titleRowRenderer = (location, color) => {
    const { address } = location;

    return (
      <TableCell style={{ backgroundColor: color }}>
        <span className="schedule-table__subheader-title">{location.name}</span>
        <span className="schedule-table__subheader-subtitle">
          {address.address_1}, {address.city}
        </span>
      </TableCell>
    );
  };

  const isMainWeekTableData =  weekTable.data?.locations.length;

  return (
    <>
      <NavigationPanel
        className="schedule-table__navigation__wrapper--weekly"
        pagination={<WeekPagination />}
      />
      <Loader show={weekTable.loading} global />
      {weekTable.error ? (
        <ScheduleError />
      ) : (
        weekTable.data && (
          isMainWeekTableData ? (
            <WeekViewTable
              data={weekTable.data}
              filter="locations"
              titleRowRenderer={titleRowRenderer}
              onWeekDayClick={onWeeklyDayClick}
              onCardClick={onCardClick}
            />
          ) : (
            <NothingFound />
          )
        )
      )}
    </>
  );
};