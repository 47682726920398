import React from 'react';
import {
  PaymentMethodSelector,
  PaymentMethodButton,
  Loader,
} from '@components/shared';
import { useViewport, useApi } from '@src/hooks';
import { CheckoutTable } from './CheckoutTable';
import { CheckoutTableMobile } from './CheckoutTableMobile';
import { queryParam } from '@src/utils';

export const Checkout = ({ btnCallbacks }) => {
  const orderId = queryParam.get('orderId');
  const checkoutData = useApi({
    url: `/orders/${orderId}`,
    dataPlaceholder: {},
  });

  const { registration = {}, totals = {} } = checkoutData.data;
  const { total, discount, increase, subtotal } = totals;

  const submitHandler = () => btnCallbacks.submit(orderId);

  const { isMobile } = useViewport();

  return (
    <div className="registration-step registration-step--5 checkout">
      <form>
        <div className="registration-step__group">
          <h4 className="registration-step__title">Checkout information</h4>
          <span className="registration-step__subtitle">
            Please check your order information, choose a desired payment
            method, and click to process your order
          </span>
        </div>
        {checkoutData.loading || !registration.appointments ? (
          <Loader />
        ) : (
          <div className="registration-step__group">
            {isMobile ? (
              <CheckoutTableMobile appointments={registration.appointments} />
            ) : (
              <CheckoutTable appointments={registration.appointments} />
            )}
          </div>
        )}
        <div className="checkout__summary">
          <h4 className="registration-step__title checkout__summary-title">
            Order summary
          </h4>
          {(!!discount || !!increase) && (
            <div className="checkout__correction">
              <span className="checkout__correction-label">Subtotal</span>
              <span className="checkout__correction-value">${subtotal}</span>
            </div>
          )}
          {!!discount && (
            <div className="checkout__correction">
              <span className="checkout__correction-label">Discount</span>
              <span className="checkout__correction-value">-${discount}</span>
            </div>
          )}
          {!!increase && (
            <div className="checkout__correction">
              <span className="checkout__correction-label">Increase</span>
              <span className="checkout__correction-value">${increase}</span>
            </div>
          )}
          <div className="checkout__total">
            <span className="checkout__total-label">TOTAL</span>
            <span className="checkout__total-value">${total}</span>
          </div>
        </div>

        <div className="registration-step__group">
          <div className="registration-step__payment-group">
            <PaymentMethodSelector />
          </div>
        </div>
        <div className="btn__wrapper">
          <PaymentMethodButton handleSubmit={submitHandler} />
        </div>
      </form>
    </div>
  );
};
