import React, { useEffect, useState } from 'react';
import { ViewportProvider } from '@src/hooks';
import { ModalInit } from '@components/shared';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '@src/store';
import { hot } from 'react-hot-loader/root';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ScrollToTopButton } from '@components/shared';
import { addScriptAfterCookie } from '@src/utils';
import { GlobalQueryChecker, MainContent, Cookie } from '@components/basic';

import './styles/index.scss';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCookie = document.cookie.includes('BCCACookie=true');

  useEffect(() => {
    document.addEventListener('mousedown', () => {
      document.body.classList.add('mouse-used');
    });

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Tab') {
        document.body.classList.remove('mouse-used');
      }
    });

    document.addEventListener('touchstart', () => {
      document.body.classList.remove('mouse-used');
      document.body.classList.add('touch-used');
    });

    if (isCookie) {
      addScriptAfterCookie();
    }
  }, []);
  
  return (
    <Provider store={store}>
      <ViewportProvider>
        <PayPalScriptProvider
          options={{
            'client-id': process.env.REACT_APP_PAYPAL_KEY,
            'enable-funding': 'venmo',
          }}
        >
          <Router>
            <MainContent />
            <Cookie />
            <ScrollToTopButton isModalOpen={isModalOpen} />
            <ModalInit setIsModalOpen={setIsModalOpen} />
            <GlobalQueryChecker />
          </Router>
        </PayPalScriptProvider>
      </ViewportProvider>
    </Provider>
  );
};

export default hot(App);