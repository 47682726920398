import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  ErrorMessage,
  Icon,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
  PaymentMethodSelector,
  PaymentMethodButton,
} from '@components/shared';
import VideoCard from '@components/features/Videos/VideoCard';
import { useForm, parseOnlyAmount, useApi } from '@src/hooks';
import { classNames, formatPrice, debounce } from '@src/utils';
import { getVideosPrice } from '@actions/registrationActions';

export const BuyingVideo = ({ btnCallbacks, courseInfo }) => {
  const videosPrice = useSelector((state) => state.registration.regPrice);
  const dispatch = useDispatch();
  const rates = courseInfo.video_prices;
  const [expandedVideo, setExpandedVideo] = useState(null);

  const purchasedVideos = useApi({
    url: `/courses/${courseInfo.id}/purchased-videos`,
  });

  const paidVideos = courseInfo?.videos?.paid;
  const purchasedVideosData = purchasedVideos.data;

  const purchasedVideosId = purchasedVideosData && purchasedVideosData.map(element => element.id);
  const availablePaidVideos = paidVideos && paidVideos.filter(e => purchasedVideosId && !purchasedVideosId.includes(e.id)).map(e => e);

  useApi({ url: `/auth/verified-email` });

  const submitHandler = (values) => {
    const requestData = {
      price_id: activeScale.id,
      videos: selectedVideos,
    };

    if (values.hasDonation) {
      requestData.donation = {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      };
    }
    return btnCallbacks.submit(requestData);
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    activeScale: {
      validation: ['isRequired'],
    },
    selectedVideos: {
      validation: ['isLengthRequired'],
    },
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    activeScale: rates.length === 1 ? rates[0] : null,
    selectedVideos: [],
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    handleSubmit,
    validateOnSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { activeScale, selectedVideos } = values;

  useEffect(() => {
    if (activeScale && selectedVideos.length) {
      const requestData = {
        price_id: activeScale.id,
        videos: selectedVideos,
      };
      getPrice(requestData);
    }
  }, [activeScale, selectedVideos]);

  const getPrice = useCallback(
    debounce((data) => {
      dispatch(getVideosPrice(data));
    }, 500),
    []
  );

  const onVideoCheck = (e, id) => {
    const isChecked = e.target.checked;
    isChecked
      ? setCustomValue('selectedVideos', [...selectedVideos, id])
      : setCustomValue(
          'selectedVideos',
          selectedVideos.filter((video) => video !== id)
        );
  };

  const renderScales = (scales) =>
    scales.map((scale) => {
      const { price, id } = scale;
      return (
        <div className="columns__item" key={id}>
          <button
            onClick={() => setCustomValue('activeScale', scale)}
            type="button"
            className={classNames([
              'switcher',
              activeScale && activeScale.id === id && 'switcher--active',
            ])}
          >
            <span className="switcher__content">
              {formatPrice(price)}$ per video
            </span>
          </button>
        </div>
      );
    });

  return (
    <form className="buying-video" onSubmit={handleSubmit}>
      {rates.length > 1 && (
        <div className="buying-video__rates">
          <div className="registration-step__group">
            <h4 className="registration-step__title">
              Please choose one of these scaled rates based on your financial
              resources
            </h4>

            <p className="registration-step__subtitle">
              <Icon type="rate" size="lg" />
              Contributions help keep artists working, and make our non-profit
              community programming possible
            </p>
          </div>

          <div className="registration-step__group registration-step__group--blocks-select">
            <div className="columns columns--4">{renderScales(rates)}</div>
            <ErrorMessage
              show={!!errors.activeScale}
              text={'Select a Scaled Rate to proceed'}
            />
          </div>
        </div>
      )}
      <h4 className="buying-video__title">Please choose one or more videos</h4>
      <div className="buying-video__cards">
        {availablePaidVideos && availablePaidVideos.map((video) => {
          return (
            <div className="buying-video__card" key={video.id}>
              <Checkbox
                value={selectedVideos.includes(video.id)}
                onChange={(e) => onVideoCheck(e, video.id)}
                name={video.title}
              />
              <VideoCard
                video={video}
                isExpanded={expandedVideo === video.id}
                setExpandedVideo={setExpandedVideo}
                isVideoGalleryCard
              />
            </div>
          );
        })}
        <ErrorMessage
          show={!!errors.selectedVideos}
          text={'Select a Video to proceed'}
        />
      </div>
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={selectedVideos.length && videosPrice.data}
            rate={activeScale?.price}
            contentType={{ singular_short_name: 'video' }}
            loading={videosPrice.loading}
            values={values}
          />
          <PaymentMethodSelector />
        </div>
      </div>
      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <PaymentMethodButton
          handleSubmit={handleSubmit}
          values={values}
          validateOnSubmit={validateOnSubmit}
          amount={videosPrice.data}
        />
      </div>
    </form>
  );
};