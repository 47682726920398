
import { splitSign, twelveHoursFormat } from '@src/constants';
import { formatTimeDate } from '@src/utils';

export const getTwelveHoursPeriod = (start, end) => {
    const formattedStart = formatTimeDate(
        start,
        splitSign,
        twelveHoursFormat
      ).toLowerCase();
      const formattedEnd = formatTimeDate(
        end,
        splitSign,
        twelveHoursFormat
      ).toLowerCase();

      return `${formattedStart} - ${formattedEnd}`
  };