import React from 'react';
import { Container } from '@components/layout';
import { classNames, changeBodyScroll } from '@src/utils';
import { useViewport } from '@src/hooks';
import { useSelector } from 'react-redux';
import {
  Icon,
  SlideMenu,
  BurgerButton,
  Loader,
  Link
} from '@components/shared';

export const Header = ({ className, isMobileNavOpen, setIsMobileNavOpen, renderNavigation }) => {
  const { isTablet } = useViewport();
  const globalLoading = useSelector((state) => state.auth.globalLoading);

  const onBurgerClick = () => {
    if (!isMobileNavOpen) {
      setIsMobileNavOpen(true);
      changeBodyScroll(true, 'modal');
    } else {
      setIsMobileNavOpen(false);
      changeBodyScroll(false, 'modal');
    }
  };

  const onWindowClick = () => {
    setIsMobileNavOpen(false)
    changeBodyScroll(false, 'modal');
  };

  return (
    <>
      <Loader show={globalLoading} sticky />    

      <header
        className={classNames([
          "header",
          isMobileNavOpen && "header--active",
          className
        ])}
      >
        <Container>
          <div className="header__content">
            <Link
              className="header__go-back"
              href="https://cccaonline.org/"
              target=""
              iconLeft={<Icon type="chevron" size="sm" />}
            >
              Back to CCCA
            </Link>

            {isTablet ? (
              <>
                <BurgerButton
                  onClick={onBurgerClick}
                  isActive={isMobileNavOpen}
                />
                  
                <SlideMenu
                  isShown={isMobileNavOpen}
                  className={'mobile-nav'}
                  onCloseClick={onWindowClick}
                >
                  {renderNavigation(true)}
                </SlideMenu>
              </>
            ) : (
              renderNavigation(false)
            )}
          </div>
        </Container>
      </header>
    </>
  );
};