import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { setProgramsFiltersSelect, setProgramsTab } from '@actions/programsActions';
import { Button } from '@components/shared';

export const CourseCategory = ({ course }) => {
  const { categories } = course;
  const history = useHistory();
  const dispatch = useDispatch();
  
  const openCategoriesPrograms = (id) => {
    dispatch(setProgramsFiltersSelect({ category: [`${id}`] }));
    dispatch(setProgramsTab('current'));
    history.push('/programs');
  };

  const renderCategories = (categories) => {
    return (
      categories.map((category, i, arr) => (
        <span key={category.id} style={{display: "inline-flex"}}>
          <Button
            key={category.id}
            type="navigation"
            onClick={() => openCategoriesPrograms(category.id)}
          >
            {category.name}
          </Button>
          {i !== arr.length - 1 && <>&nbsp;|&nbsp;</>}
        </span>
      ))
    );
  };

  return (
    <>
      {course && renderCategories(categories)}
    </>
  );
};

CourseCategory.propTypes = {
  course: PropTypes.object,
};

CourseCategory.defaultProps = {
  course: {},
};