import React from 'react';
import { Button, Icon, Tooltip } from '@components/shared';
import { format } from 'date-fns';
import {
  dateSessionsFormat,
  twelveHoursFormat,
  weekdaysObject,
  dateAndMonthShort,
} from '@src/constants';
import {
  zonedDate,
  getDateTimes,
  getOfferingsAmount,
  getImageUrl,
  classNames
} from '@src/utils';
import { useViewport } from '@src/hooks';

export const SessionsItem = ({
  data,
  onRegisterClick,
  onTrialClick,
  onLearnMoreClick,
  grouped,
  inactive,
  classId,
  contentType,
  location,
  isPrivate
}) => {
  const {
    schedules,
    start_date,
    end_date,
    drop_in_registration,
    sliding_scale_registration,
    pre_registration,
    block_registration,
    inactivity_days,
    free_registration,
    available_tickets,
    trial_registration,
    has_available_trial_dates,
    length,
    rate
  } = data;
  const { isTablet } = useViewport();
  const startDate = format(zonedDate(start_date), dateSessionsFormat);
  const endDate = end_date
    ? format(zonedDate(end_date), dateSessionsFormat)
    : null;

  const renderInactivityDaysTooltip = () => {
    if (!inactivity_days || !inactivity_days.length) return null;

    return (
      <Tooltip
        title="Inactivity day"
        position="right"
        className="sessions-item-card__inactivity-tooltip"
      >
        {inactivity_days.map(({ inactivity_day }, i) => (
          <span key={inactivity_day}>
            {format(zonedDate(inactivity_day), dateAndMonthShort)}
            {i !== inactivity_days.length - 1 ? ',' : ''}
            &nbsp;
          </span>
        ))}
      </Tooltip>
    );
  };

  const dateRenderer = startDate === endDate ? (
    <>{startDate}</>
  ) : (
    <>
      {startDate}
      <span className="sessions__date-separator">&nbsp;-&nbsp;</span>
      {endDate}
      {renderInactivityDaysTooltip()}
    </>
  );

  const hasActiveRegistrations =
    drop_in_registration ||
    block_registration ||
    sliding_scale_registration ||
    pre_registration ||
    free_registration ||
    trial_registration ||
    available_tickets;

  const renderArray = schedules.map((schedule, i) => {
    const { studio, start_time, end_time } = schedule;
    const { startTime, endTime } = getDateTimes(start_time, end_time);
    const classesAmount =
      i === 0 ? getOfferingsAmount(length, contentType) : '';

    const dateTime = {
      week: weekdaysObject[schedule.day_num],
      startTime: format(startTime, twelveHoursFormat).toLowerCase(),
      endTime: format(endTime, twelveHoursFormat).toLowerCase(),
    };
    const studioName = studio ? studio.name : '';

    return {
      startTime,
      endTime,
      dateTime,
      studioName,
      classesAmount: i === 0 ? classesAmount : '',
      date: i === 0 ? dateRenderer : '',
    };
  });

  return (
    <div className="sessions__item">
      <div className="sessions-item-card">
        <div className="sessions-item-card__body">
          <div className="sessions-item-card__top">
            {location && (
              <div className="ftable__row sessions-item-card__logo">
                <a 
                  href={location.link} 
                  target="_blank"
                >
                  <img
                    src={getImageUrl(location.logo?.path)}
                    alt="Logo"
                    height='24'
                  />
                </a>
              </div>
            )}

            <div 
              className={classNames([
                'ftable__row',
                'sessions-item-card__date-number',
                free_registration && !location && 'sessions-item-card__date-number--no-location'
              ])}
            >
              {free_registration && (
                <div
                  className={classNames([
                    'sessions-item-card__free-badge',
                    has_available_trial_dates ? 
                      'sessions-item-card__free-badge--active' : 
                      'sessions-item-card__free-badge--no-active'
                  ])}
                >
                  <span className='badge'>
                    Free
                  </span>
                </div>
              )}

              <div className="ftable__cell">
                <span className="ftable__cell-content sessions-item-card__cell-label">
                  Сlass dates
                </span>

                <span className="ftable__cell-content sessions-item-card__date-value">
                  {renderArray[0].date}
                </span>
              </div>

              <div className="ftable__cell">
                <span className="ftable__cell-content sessions-item-card__cell-label">
                  Number of classes
                </span>

                <span className="ftable__cell-content">
                  {renderArray[0].classesAmount.slice(0, 1)}
                </span>
              </div>
            </div>

            {!isTablet ? (
              <div className='ftable__row sessions-item-card__schedule-studio'>
                <div className="ftable__cell">
                  <span className="ftable__cell-content sessions-item-card__cell-label">
                    Schedule
                  </span>
                                    
                  <span className="ftable__cell-content sessions-item-card__cell-label">
                    Studio
                  </span>
                </div>
            
                {renderArray.map(
                  ({ date, dateTime, studioName }, i) => (
                    <div className="ftable__cell" key={date + i}>
                      <span key={dateTime} className="ftable__cell-content">
                        {dateTime.week}, {dateTime.startTime} – {dateTime.endTime} EST
                      </span>
                                    
                      <span key={studioName} className="ftable__cell-content">
                        {studioName}
                      </span>
                    </div>
                  )
                )}
              </div>
            ) : (   
              <div className='ftable__row sessions-item-card__schedule-studio'>
                <div className="ftable__cell">
                  <span className="ftable__cell-content sessions-item-card__cell-label">
                    Schedule
                  </span>
                </div>
            
                {renderArray.map(
                  ({ date, dateTime, studioName }, i) => (
                    <div className="ftable__cell" key={date + i}>
                      <span key={studioName} className="ftable__cell-content">
                        {studioName}:
                      </span>

                      <span key={dateTime} className="ftable__cell-content">
                        {dateTime.week}, {dateTime.startTime} – {dateTime.endTime} EST
                      </span>            
                    </div>
                  )
                )}
              </div>
            )}

            {/* {grouped && inactive && (
              <Button
                className="sessions-item-card__button--inactive-session"
                onClick={() => onLearnMoreClick(classId)}
              >
                <Icon size="lg" type="proceed-next" />
              </Button>
            )} */}
          </div>

          {!inactive &&
            <div className="sessions-item-card__bottom">
              {rate && 
                <div className='sessions-item-card__rate'>
                  <p><span>Registration Rate:</span> starting from $<span>{rate}</span> per class</p>
                </div>
              }

              {!inactive && (
                <div className="sessions-item-card__actions">
                  {has_available_trial_dates && (
                    <Button
                      onClick={() => onTrialClick(data.id, classId)}
                      type="secondary"
                    >
                      Free Trial
                    </Button>
                  )}

                  {hasActiveRegistrations && (
                    <Button
                      onClick={() => onRegisterClick(data.id, classId)}
                      type="primary"
                    >
                      {isPrivate ? 
                        'Pay Studio Fee(s)' : 
                        (!!available_tickets ? 'Get Tickets' : 'Register to Attend')
                      }
                    </Button>
                  )}
                </div>
              )}
            </div>
          }  
        </div>
      </div>
    </div>
  );
};

SessionsItem.defaultProps = {
  onRegisterClick: () => {},
  grouped: false,
};