import React from 'react';
import { useLocation } from 'react-router-dom';
import { useApi } from '@src/hooks';
import { Container } from '@components/layout';
import { Loader, Link, Icon } from '@components/shared';
import { InstructorDetailsContent } from './InstructorDetailsContent';

export const InstructorDetails = () => {
  const referrerPage = document.referrer;
  const isReferrerPage = 
    referrerPage.includes('account') || 
    referrerPage.includes('schedule') ||
    referrerPage.includes('faculty') || 
    referrerPage.includes('programs');
  const location = useLocation();
  const instructorId = `${location.pathname.split('/')[2]}`;

  const instructorInfo = useApi({ url: `/instructors/${instructorId}` });

  const defineReferrerPageType = () => {
    if (referrerPage.includes('account')) return 'Account'
    if (referrerPage.includes('schedule')) return 'Schedule'
    if (referrerPage.includes('faculty')) return 'Faculty'
    else return 'Programs'
  };

  const pageLink = () => {
    if (isReferrerPage) {
      return referrerPage
    } else {
      return `${window.location.origin}/programs`
    }
  };

  return (
    <>
      <Loader show={instructorInfo.loading} attached/>

      {instructorInfo.data && (
        <div className="instructor-details">
          <Container>
            <div className="instructor-details__block breadcrumbs-block instructor-details__breadcrumbs">
              <Link 
                href={pageLink()}
                target=""
              >
                {defineReferrerPageType()}
              </Link>

              <Icon type="chevron" size="xs" />
              
              <span>Details</span>
            </div>
            <InstructorDetailsContent data={instructorInfo.data} id={instructorId} />
          </Container>
        </div>
      )}
    </>
  );
};