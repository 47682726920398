import React from 'react';
import VideoGalleryCard from '@components/features/Videos/VideoGalleryCard';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { stopIframeVideo } from '@src/utils';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Navigation, Pagination]);

export const VideoGallery = ({ videos }) => {
  return (
    <div className="video-gallery--old">
      <h3>Gallery</h3>
      <Swiper
        className="video-gallery__slider"
        navigation
        pagination={{ clickable: true, type: 'fraction' }}
        slidesPerView={1}
        spaceBetween={20}
        resizeObserver={true}
        onSlideChangeTransitionEnd={({ previousIndex, slides }) =>
          stopIframeVideo(slides[previousIndex])
        }
      >
        {videos?.map((video) => {
          return (
            <SwiperSlide className="video-gallery__slide" key={video.id}>
              <VideoGalleryCard videoData={video} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};