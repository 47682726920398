import React from 'react';
import { Icon, Checkbox, Tooltip, Input } from '@components/shared';

export const RegistrationDonationBlock = ({
  values,
  errors,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="registration-donation">
      <div className="registration-donation__note">
        <Icon type="donate" size="md" />
        Contributions help keep artists working, and make our non-profit
        community programming possible
      </div>
      <div className="registration-donation__actions">
        <div className="registration-donation__action-wrapper">
          <Checkbox
            onChange={handleChange}
            value={values.hasDonation}
            label="I’d like to add a tax-deductible donation"
            name="hasDonation"
          />
          <Tooltip position="bottom">
            Brookline/Cambridge Community Center for the Arts, Inc. (BCCA /
            CCCA), is a non-profit, tax-exempt 501(c)(3) public charitable
            organization. Tax ID number: 05-0548309
          </Tooltip>
        </div>
        {values.hasDonation && (
          <div className="registration-donation__action-wrapper">
            <Checkbox
              onChange={handleChange}
              value={values.isAnonymous}
              label="I prefer to remain anonymous"
              name="isAnonymous"
            />
            <Tooltip position="bottom">
              Your name will be added to our list of supporters unless you
              specify to remain anonymous. The name and contact info of
              anonymous donors will only be retained for the purpose of
              providing a donation thank you letter, and in case of large
              donations, per federal requirements.
            </Tooltip>
          </div>
        )}
      </div>
      {values.hasDonation && (
        <div className="registration-donation__amount-wrapper">
          <Input
            label="Donation Amount"
            value={values.donationAmount ? `$${values.donationAmount}` : ''}
            placeholder="Please enter amount"
            onChange={handleChange}
            onBlur={handleBlur}
            alert={errors.donationAmount}
            name="donationAmount"
          />
        </div>
      )}
    </div>
  );
};
