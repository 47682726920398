import React from "react";
import { useSelector } from 'react-redux'
import { Schedule } from '@components/features';

export const GlobalSchedule = () => {
  const activeTab = useSelector((state) => state.schedule.activeTab);

  return (
    <Schedule 
      className="global-schedule"
      activeTab={activeTab}
    />
  );
};