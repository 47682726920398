import { useOldState } from './useOldState';
import { useEffect } from 'react';
import { apiRequest } from '@src/utils';

export const useApi = (settings, dependency = [], callIf = true) => {
  const {
    url,
    dataPlaceholder = null,
    method = 'GET',
    reduce = false,
  } = settings;

  const [state, setState] = useOldState({
    data: dataPlaceholder,
    loading: false,
    error: '',
    hasNextPage: false,
  });

  useEffect(() => {
    if (callIf) {
      apiRequest({
        url,
        method,
        callbacks: {
          success: (res) =>
            setState({
              data: reduce ? [...state.data, ...res.data] : res.data,
              loading: false,
              error: '',
              hasNextPage: reduce ? !!res.links.next : false,
            }),
          loading: () =>
            setState({
              data: reduce ? state.data : dataPlaceholder,
              loading: true,
              error: '',
            }),
          error: (err) =>
            setState({
              data: reduce ? state.data : dataPlaceholder,
              loading: false,
              error: err,
            }),
        },
      });
    }
  }, dependency);

  return state;
};
