import React, { useState, useEffect } from 'react';
import { Container } from '@components/layout';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useHistory
} from 'react-router-dom';
import {
  AccountScheduleView, 
  AccountTicketsView,
  AccountVideos,
  AccountTransactions,
  AccountProfileInformation,
  AccountInstitutionsSettings,
} from '@components/features/Account';
import { useDispatch, useSelector } from 'react-redux';
import { classNames, queryParam, openLink } from '@src/utils';
import { setProgramsFiltersSelect, setProgramsTab } from '@actions/programsActions';
import { getUserData } from '@actions/authActions';
import { useViewport } from '@src/hooks';
import { Button, Icon } from '@components/shared';
import { AccountNavigation } from './AccountNavigation';

export const Account = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [notification, setNotification] = useState(
    queryParam.get('notification')
  );
  const [editorState, setEditorState] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = window.location;
  const { isTablet, height } = useViewport();
  const [offset, setOffset] = useState(false);

  const returnHref = localStorage.getItem('returnHref');
  const returnPath = localStorage.getItem('returnPath');

  const initializePath = () => {
    if (returnPath === 'programs') {
      return 'Programs'
    } if (returnPath === 'schedule') {
      return 'Schedule'
    } if (returnPath === 'faculty') {
      return 'Faculty'
    } if (returnPath && returnPath.includes('offering')) {
      return 'Offerings'
    }
  }

  const returnHandler = () => {
    if (returnPath && returnPath.includes('offering')) {
      const id = returnPath.substring(9);

      dispatch(setProgramsFiltersSelect({ instructor: id }));
      dispatch(setProgramsTab('current'));
      history.push('/programs');
    } else {
      openLink(returnHref, false)
    }
  }
  
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!notification) {
      queryParam.delete('notification');
    }
  }, [notification]);

  useEffect(() => {
    dispatch(getUserData());
  }, []);
  
  return (
    <div className='global-page account'>
      <Container>
        <div className='columns account__columns'>
          <div className='columns__item account__navigation-column'>
            <div 
              className={classNames([
              'account-navigation',
              height > 565 && offset > 68 && 'account-navigation--scroll',
              ])}
            >
              <h1 className='global-page__title account__title'>Account</h1>
              {!isTablet && <AccountNavigation />}
            </div>
          </div>
          <div className='columns__item account__content-column'>
            <Switch>
              <Route path={`${path}/schedule`} exact>
                <AccountScheduleView />
              </Route>

              <Route path={`${path}/tickets`} exact>
                <AccountTicketsView />
              </Route>

              <Route path={`${path}/videos`} exact>
                <AccountVideos />
              </Route>

              <Route path={`${path}/transactions`} exact>
                <AccountTransactions />
              </Route>

              <Route path={`${path}/profile-information`} exact>
                <AccountProfileInformation 
                  userData={userData} 
                  editorState={editorState}
                  setEditorState={setEditorState}
                  dispatch={dispatch}
                />
              </Route>

              <Route path={`${path}/institutions-settings`} exact>
                <AccountInstitutionsSettings 
                  userData={userData} 
                  editorState={editorState}
                  setEditorState={setEditorState}
                  dispatch={dispatch}
                />
              </Route>

              <Route>
                <Redirect to={`${path}/schedule`} />
              </Route>
            </Switch>

            {!!notification && (
              <div className="popup">
                <div
                  className="popup__overlay"
                  onClick={() => setNotification('')}
                ></div>
                <div className="popup__window">
                  <Icon type="success" className="popup__icon" />
                  <div className="popup__body">
                    <div 
                      className="popup__text"
                      dangerouslySetInnerHTML={{ __html: notification }}
                    >
                    </div>
                    {(pathname !== '/account/transactions' && initializePath()) &&
                      <Button 
                        className='popup__return-button'
                        type='primary' 
                        onClick={() => returnHandler()}
                      >
                        Return to {initializePath()}
                      </Button>
                    }
                  </div>

                  <Button 
                    className="popup__close-button"
                    onClick={() => setNotification('')}
                  >
                    <Icon type="close-cross" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};