import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  closeModal,
  Input,
  Loader,
  openModal,
  getModal,
} from '@components/shared';
import { useSelector } from 'react-redux';
import { queryParam, openLink, apiRequest, requestCallback } from '@src/utils';
import { parseNoSpaces, parseOnlyLettersAndNumbers, useForm } from '@src/hooks';
import { normalizeRegisterData } from '@src/normalizers';

export const EmailVerificationSuccess = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      queryParam.delete([
        'verification_url',
        'redirect_to',
        'email-verification',
      ]);
    };
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      const verificationURL = encodeURI(queryParam.get('verification_url'));
      apiRequest({
        url: verificationURL,
        callbacks: requestCallback(
          () => setIsLoading(false),
          (err) => {
            err && handleServerErrors(err.response);
          },
          () => setIsLoading(true)
        ),
      });
    }
  }, [isAuthorized]);

  const onAgreeClick = () => {
    const redirectLink = encodeURI(queryParam.get('redirect_to') || '');
    if (redirectLink) {
      openLink(redirectLink, false);
    }
    closeModal('email-verification-success');
  };

  const schema = {
    login: {
      parse: parseNoSpaces,
      validation: ['isRequired'],
    },
    password: {
      validation: ['isRequired'],
      parse: parseOnlyLettersAndNumbers,
    },
  };

  const submitHandler = (values) => {
    const loginData = normalizeRegisterData(values, false);
    apiRequest({
      url: `/auth/login`,
      method: 'POST',
      data: JSON.stringify(loginData),
      callbacks: requestCallback(
        () => setIsLoading(false),
        (err) => err && handleServerErrors(err.response),
        () => setIsLoading(true)
      ),
    });
  };

  const handleServerErrors = (error) => {
    if (!error) return;

    const { data, config, status } = error;
    const isServerError = status === 403;
    const isLinkExpired =
      isServerError && data?.message === 'Invalid signature.';
    const isUserLogin =
      isServerError && data?.message === 'This action is unauthorized.';

    if (config.url === '/auth/login') {
      setErrors({ login: data.message, password: data.message });
      return;
    }

    if (isLinkExpired) {
      const notificationData = {
        linkType: 'email',
        redirect_to: encodeURI(queryParam.get('redirect_to') || ''),
      };
      closeModal('email-verification-success');
      openModal(
        getModal({
          name: 'expired-link-notification',
          data: notificationData,
        })
      );
    }

    if (isUserLogin) {
      closeModal('email-verification-success');
      openModal(
        getModal({
          name: 'logout-request-notification',
        })
      );
    }
  };

  const { values, errors, handleChange, handleSubmit, setErrors, handleBlur } =
    useForm({
      callback: submitHandler,
      schema,
    });

  return (
    <>
      <Loader sticky show={isLoading} />
      <div className="login">
        <div className="registration-step registration-step--email-verification">
          <div className="registration-step__container">
            {isAuthorized ? (
              <>
                <Icon type="login-welcome" className="login__icon" />
                <h1 className="login__title">Congratulations!</h1>
                <div className="registration-step__text">
                  You've successfully confirmed your email address.
                </div>
                <div className="registration-step__text">
                  Now you can use your account to the fullest and register for
                  the Content / Offerings.
                </div>
                <div className="form__group form__group--login-buttons">
                  <Button type="primary" onClick={onAgreeClick}>
                    Got it!
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Icon type="login-welcome" className="login__icon" />
                <h1 className="login__title">Congratulations!</h1>
                <div className="registration-step__text">
                  You've almost confirmed your email address.
                </div>
                <div className="registration-step__text">
                  Please log in to start using your account to the fullest.
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form__group">
                    <Input
                      label="Email or Username"
                      placeholder="Enter your Email or Username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.login}
                      name="login"
                      alert={errors.login}
                    />
                  </div>
                  <div className="form__group">
                    <Input
                      label="Password"
                      placeholder="Enter your Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      password
                      name="password"
                      alert={errors.password}
                    />
                  </div>
                  <div className="form__group form__group--login-buttons">
                    <Button type="primary" htmlType="submit">
                      Log In
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};