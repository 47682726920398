import React, { useEffect, useState } from 'react';
import {format, startOfDay } from 'date-fns';
import { dateFormatRequest, dateAndMonthShort } from '@src/constants';
import { Loader } from '@components/shared';
import { AccountScheduleCard } from './AccountScheduleCard';
import { openLink, zonedDate, queryParam } from '@src/utils';
import { useApi } from '@src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getDayTable } from '@actions/scheduleActions';
import { AccountScheduleCalendar } from './AccountScheduleCalendar';

export const AccountScheduleView = () => {
  const today = startOfDay(new Date());
  const querryDate = queryParam.get('chosen-date');
  const closestDay = useApi(
    { url: `/account/appointments/closest-date` },
    [],
    querryDate === 'closest'
  );
  const initDate = querryDate && querryDate !== 'closest' ? zonedDate(querryDate) : today;

  const [selectedDay, setSelectedDay] = useState(initDate);
  const [date, setDate] = useState(selectedDay);
  const dayDate = useSelector((state) => state.schedule.dayDate);
  const filtersSelect = useSelector((state) => state.schedule.filtersSelect);

  const dispatch = useDispatch();

  useEffect(() => {
    if (closestDay.data) {
      setSelectedDay(zonedDate(closestDay.data));
    }
  }, [closestDay.data]);

  useEffect(() => {
    dispatch(getDayTable(dayDate, filtersSelect));
    queryParam.set('dayDate', format(dayDate, dateFormatRequest));
  }, [dayDate, filtersSelect]);

  const requestDate = format(selectedDay, dateFormatRequest);

  const userSchedules = useApi(
    { url: `/account/appointments?date=${requestDate}`, dataPlaceholder: [] },
    [requestDate]
  );

  useEffect(() => {
    queryParam.set('chosen-date', requestDate);
    return () => queryParam.delete('chosen-date');
  }, [requestDate]);

  const onScheduleLinkClick = () => {
    const schedulePageLink = `${window.location.origin}/schedule`;
    openLink(schedulePageLink, true);
  };

  return (
    <div className="account-schedule">
      <h2 className='account__content-title'>Schedule</h2>
      <div className="account-schedule__calendar-block">
        <AccountScheduleCalendar 
          date={date}
          setDate={setDate}
          today={today}
          selectedDay={selectedDay}
          setSelectedDay={(day) => setSelectedDay(day)}
          onMonthChange={setDate}
        />
      </div>
      {userSchedules.loading || closestDay.loading ? (
        <Loader attached />
      ) : (
        <>
          {userSchedules.data.length ? (
            userSchedules.data.map((schedule, i) => (
              <AccountScheduleCard scheduleData={schedule} key={i} />
            ))
          ) : (
            <p className="account-schedule__no-schedules">
              No Content / Offerings for{' '}
              {format(selectedDay, dateAndMonthShort)}. 
              {/* You can purchase Content
              / Offerings on the{' '}
              <Button type="link" onClick={onScheduleLinkClick}>
                Schedule page
              </Button> */}
            </p>
          )}
        </>
      )}
    </div>
  );
};