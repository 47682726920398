import React, { useState, useEffect } from 'react';
import { Loader, Policy, Button } from '@components/shared';
import { SnailSubcription, EmailSubcription, MediaWaiver } from './InstitutionsCards';

export const AccountInstitutionsSettings = ({ 
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const [isPolicyShown, setIsPolicyShown] = useState(false);

  useEffect(() => {
    const modalWindow = document.querySelector('.modal__window');

    modalWindow?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [isPolicyShown]);

  return (
    <div className="account-profile">
      <h2 className="account__content-title">Institutions settings</h2>
      <Loader show={userData.loading} sticky />
      {isPolicyShown ? (
        <Policy setCurrentView={() => setIsPolicyShown(false)} />
      ) : (
        <div className="account-profile__body">
          <div className="account-profile__item">
            <SnailSubcription 
              userData={userData} 
              editorState={editorState}
              setEditorState={setEditorState}
              dispatch={dispatch}
            /> 
          </div>

          <div className="account-profile__item">
            <EmailSubcription 
              userData={userData} 
              editorState={editorState}
              setEditorState={setEditorState}
              dispatch={dispatch}
            /> 

            <MediaWaiver 
              userData={userData} 
              editorState={editorState}
              setEditorState={setEditorState}
              dispatch={dispatch}
            />

            <Button
              className="account-profile__policy-button"
              type="link"
              onClick={() => setIsPolicyShown(true)}
            >
              Terms and Conditions and Privacy Policy
            </Button>
          </div>
      </div>
      )}
    </div>
  );
};