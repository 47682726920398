import axios from 'axios';
import { store } from '@src/store';
import { closeAllModals, openModal, getModal } from '@components/shared';
import { apiRequest } from '@src/utils';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const tokenKey = 'token';

const userLogin = (token) => {
  localStorage.setItem(tokenKey, token);
  store.dispatch({ type: 'IS_AUTHORIZED', payload: true });
};

const userLogout = () => {
  localStorage.removeItem(tokenKey);
  sessionStorage.removeItem('redirect_to');
  store.dispatch({ type: 'IS_AUTHORIZED', payload: false });
};

const redirectToLogin = (currentUrl) => {
  if (!sessionStorage.getItem('redirect_to')) {
    sessionStorage.setItem('redirect_to', currentUrl);
  }
  closeAllModals();
  openModal(getModal({ name: 'login' }));
};

const tokenRefreshApiConfig = (token) => {
  return {
    method: 'POST',
    url: `/auth/refresh`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const authURLs = ['/auth/login', '/auth/refresh', '/account/profile'];

function tokenHandling(res) {
  const isAuthURL = authURLs.includes(res.config.url);
  const isLogout = res.config.url === '/auth/logout';
  const token = res.data.data.token;

  if (isAuthURL && token) {
    userLogin(token);
  }

  if (isLogout) {
    userLogout();
  }

  return res;
}

const onError = (err) => {
  const {
    config,
    response: { status, data },
  } = err;
  const currentUrl = window.location.href;
  const unauthorized = status === 401 && config.url !== '/auth/login';
  const emailNotVerificated =
    status === 403 && data.message === 'Your email address is not verified.';

  if (unauthorized) {
    const originalRequest = config;
    const token = localStorage.getItem(tokenKey);

    if (token) {
      request(tokenRefreshApiConfig(token))
        .then((response) => {
          const refreshedToken = response.data.data.token;
          localStorage.setItem(tokenKey, refreshedToken);
          apiRequest({...originalRequest, refreshedToken });
        })
        .catch((err) => {
          userLogout();
          if (err.response?.status === 422) {
            redirectToLogin(currentUrl);
          }
          return Promise.reject(err);
        });
    } else {
      redirectToLogin(currentUrl);
      return Promise.reject(err);
    }
  } else if (emailNotVerificated) {
    closeAllModals();
    openModal(
      getModal({ name: 'email-verification', data: { accessDeniedCase: true } })
    );
  } else if (status >= 500 || status === 429) {
    if (window.location.pathname !== '/server-error') {
      window.location.href = '/server-error';
    }
  } else if (status === 404) {
    if (window.location.pathname !== '/not-found') {
      window.location.href = '/not-found';
    }
  } else {
    return Promise.reject(err);
  }
};

export default function (axios) {
  axios.interceptors.response.use(tokenHandling, onError);
}
