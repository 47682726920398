import { queryParam } from '@src/utils';

export const GET_FACULTY_INSTRUCTORS = (
  page,
  { location, instructor, category, letter, program },
  search
) =>
  `/instructors${queryParam.generateQuery([
    {
      label: 'page',
      value: page,
    },
    {
      label: 'search',
      value: search,
    },
    {
      label: 'id',
      value: instructor,
    },
    {
      label: 'location',
      value: location,
    },
    {
      label: 'category',
      value: category,
    },
    {
      label: 'letter',
      value: letter !== 'all' && letter,
    },
    {
      label: 'program',
      value: program,
    },
  ])}`;
