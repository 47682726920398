import React, { useRef, useEffect } from "react";
import { useViewport } from '@src/hooks';
import CookieConsent from "react-cookie-consent"
import { useDispatch, useSelector } from 'react-redux';
import { acceptCookie } from '@actions/cookieActions';
import { addScriptAfterCookie } from '@src/utils';
import { Container } from '@components/layout';
import { 
  Button, 
  Link, 
  Icon, 
  getModal, 
  openModal, 
} from '@components/shared';

export const Cookie = () => {
  const { isMobile } = useViewport();
  const cookieConsentRef = useRef();
  
  const isModal = true;
  const isCurrentRef =  true;

  const dispatch = useDispatch();
  const isCookieState = useSelector((state) => state.cookie.isCookie);

  useEffect(() => {
    if (isCookieState) {
      addScriptAfterCookie();
    }
  }, [isCookieState]);

  const cookieSettings = {
    containerClasses: "cookie-block",
    cookieName: "BCCACookie",
    disableStyles: true,
    buttonStyle: { display: 'none' },
    cookieSecurity: true,
    sameSite: "lax",
    ref: cookieConsentRef,
  };

  const onPolicyClick = () => {
    openModal(
      getModal({
        name: 'policy-notice',
        data: {isModal, isCurrentRef},
      })
    );
  };

  return (
    <CookieConsent {...cookieSettings}>
      <Container>
        <div className="cookie-block__body">
          <Button 
            className="cookie-block__close-button"
            onClick={() => {
              cookieConsentRef.current.decline();
              document.cookie = 'BCCACookie' + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }}
          >
            <Icon type="close-cross" size={isMobile ? "md" : "lg"} />
          </Button> 

          <div className="cookie-block__visual">
            <Icon type="cookie" />
          </div>

          <div className="cookie-block__content">
            <h2 className="cookie-block__title">Here’s how we use cookies</h2>

            <p className="cookie-block__description">
              We use cookies to better understand your preferences so that we can bring you the best, most personalized experience possible. By using this website, you agree to use of cookies as outlined in {''}
              <Link onClick={onPolicyClick} target="">BCCA/CCCA cookie notice & policy</Link>.
            </p>

            <div className="cookie-block__buttons">
              <Button 
                type="primary" 
                onClick={() => {
                  cookieConsentRef.current.accept();
                  dispatch(acceptCookie());
                }}
              >
                Accept All
              </Button>

              <Button 
                type="transparent"
                onClick={() => cookieConsentRef.current.decline()}
              >
                Deny Optional Cookies
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </CookieConsent>
  );
};