import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDidUpdate, usePrevious } from '@src/hooks';
import { Policy, changeModalProps } from '@components/shared';
import { PasswordRecovery } from './PasswordRecovery';
import { LoginForm } from './LoginForm';
import { queryParam, getRegistrationProceedLink } from '@src/utils';

export const Login = ({ btnCallbacks, isCourseReg }) => {
  const [formCash, setFormCash] = useState({});
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [currentView, setCurrentView] = useState('loginForm');
  const loginModal = useRef();
  const proceedLink =
    (isCourseReg && getRegistrationProceedLink()) ||
    sessionStorage.getItem('redirect_to');
  const prevView = usePrevious(currentView);

  useEffect(() => {
    changeModalProps('registration', {
      className: `modal--narrow`,
    });

    return () => {
      changeModalProps('registration', {
        className: `modal`,
      });
    };
  }, []);

  useEffect(() => {
    if (!isCourseReg) {
      queryParam.set('loginModal', true);
    }
    const initialView = queryParam.get('loginView');
    if (initialView) {
      setCurrentView(initialView);
    }
    return () => {
      queryParam.delete(['loginView', 'loginModal']);
    };
  }, []);

  useDidUpdate(() => {
    if (currentView === 'loginForm' && prevView !== 'passwordRecovery') {
      loginModal.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } else {
      document.querySelector('.modal__window')?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [currentView]);

  const view = {
    loginForm: (
      <LoginForm
        btnCallbacks={btnCallbacks}
        isCourseReg={isCourseReg}
        setCurrentView={setCurrentView}
        formCash={formCash}
        setFormCash={setFormCash}
        isPolicyAccepted={isPolicyAccepted}
        proceedLink={proceedLink}
      />
    ),
    policy: (
      <Policy
        setCurrentView={setCurrentView}
        setIsPolicyAccepted={setIsPolicyAccepted}
        options
      />
    ),
    passwordRecovery: (
      <PasswordRecovery
        setCurrentView={setCurrentView}
        proceedLink={proceedLink}
      />
    ),
  };

  return (
    <div className="login" ref={loginModal}>
      {view[currentView]}
    </div>
  );
};

Login.propTypes = {
  btnCallbacks: PropTypes.object,
};
Login.defaultProps = {
  btnCallbacks: {},
};