import React from 'react';
import { Button, CourseTitleBlock, Icon } from '@components/shared';
import { getImageUrl } from '@src/utils';

export const CourseDetailsHeader = ({ courseInfo }) => {
  const { videos } = courseInfo || {};
  const isPrivate = courseInfo?.is_private;

  const hasActiveRegistrations = courseInfo?.sessions.some(
    ({
      drop_in_registration,
      block_registration,
      sliding_scale_registration,
      pre_registration,
      free_registration,
      available_tickets,
      trial_registration
    }) =>
      drop_in_registration ||
      block_registration ||
      sliding_scale_registration ||
      pre_registration ||
      free_registration ||
      available_tickets || 
      trial_registration
  );

  const hasVideos = !!videos?.free || !!videos?.paid;

  const onAnchorClick = () => {
    const sessionsDOM = document.querySelector('.sessions.sessions--offering-details');
    sessionsDOM.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const onVideoAnchorClick = () => {
    const videoGalleryDOM = document.querySelector('.video-gallery');

    if (videoGalleryDOM) {
      videoGalleryDOM.scrollIntoView({ block: 'start', behavior: 'smooth' });
      return;
    }
    document
      .querySelector('.videos')
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <>
      <CourseTitleBlock courseInfo={courseInfo} />
      <div className="class-details__actions">
        <Button
          type="primary-outline"
          iconRight={<Icon type="arrow-bottom" />}
          onClick={onAnchorClick}
        >
          {hasActiveRegistrations
            ? (isPrivate ? 'Pay Studio Fee(s)' : 'Register to Attend')
            : 'Schedule / Sessions'}
        </Button>
        {hasVideos && (
          <Button
            type="primary-outline"
            iconRight={<Icon type="arrow-bottom" />}
            onClick={onVideoAnchorClick}
          >
            Video
          </Button>
        )}

        {/*{hasDemoSessions && (*/}
        {/*  <Button type="secondary" onClick={onAnchorClick}>*/}
        {/*    Try for free*/}
        {/*  </Button>*/}
        {/*)}*/}
        <div className="class-details__presented">
          <span>Presented by</span>
          <a href={courseInfo?.location.link} target="_blank">
            <img
              src={getImageUrl(courseInfo?.location.logo?.path)}
              height="30"
              alt="CCCA-logo"
            />
          </a>
        </div>
      </div>
    </>
  );
};