import React from 'react';
import { InstructorName } from '@components/shared';
import { CourseBadges } from '@components/shared/Course/CourseBadges';

export const CourseTitleBlock = ({ courseInfo }) => {
  const { label, title, contractor } = courseInfo || {};

  return (
    <div className="class-details__title-wrapper">
      <div className="class-details__title-container">
        {label && <span className="offering-title-block__id">{label}</span>}
        {<CourseBadges course={courseInfo} />}
      </div>
      {title && <h3 className="offering-title-block__title">{title}</h3>}
      <div className="offering-title-block__subtitle">
        <InstructorName
          course={courseInfo}
          className="offering-title-block__subtitle"
          hasLink={true}
        />
        {contractor && (
          <span className="class-details__contractor"> ({contractor})</span>
        )}
      </div>
    </div>
  );
};
