import React from 'react';
import { Badge } from '@components/shared';

export const CourseBadges = ({ course, session, isFree, isTrial, isPast }) => {
  const { is_online, is_onsite, content_type, has_videos } = course || {};

  const displayBadges = () => {
    const badges = [];

    content_type?.name &&
      badges.push({
        id: 'content',
        className: `offering-details__badge--content-${content_type.name
          .split(' ')
          .join('-')
          .toLowerCase()}`,
        content: content_type.name,
      });

    is_online &&
      !is_onsite &&
      badges.push({
        id: 'isOnline',
        className: 'offering-details__badge--online-onsite',
        content: 'Online',
      });

    is_onsite &&
      !is_online &&
      badges.push({
        id: 'isOnsite',
        className: 'offering-details__badge--online-onsite',
        content: 'Onsite',
      });

    is_onsite &&
      is_online &&
      badges.push({
        id: 'isOnsiteOnline',
        className: 'offering-details__badge--onsite-online',
        content: 'Onsite & Online',
      });

    has_videos &&
      badges.push({
        id: 'hasVideos',
        className: 'offering-details__badge--hasVideos',
        content: 'Video',
      });

      isFree && !isPast &&
      badges.push({
        id: 'isFree',
        className: 'offering-details__badge--isFree',
        content: 'Free',
      });

      isFree && isPast &&
      badges.push({
        id: 'isFree',
        className: 'offering-details__badge--isPastFree',
        content: 'Free',
      });

      isTrial && !isPast &&
      badges.push({
        id: 'isTrial',
        className: 'offering-details__badge--isTrial',
        content: 'Free Trial',
      });

      isTrial && isPast &&
      badges.push({
        id: 'isPastTrial',
        className: 'offering-details__badge--isPastTrial',
        content: 'Free Trial',
      });

    if (!badges.length) return null;

    return (
      <div className="offering-details__badges">
        {badges.map(({ id, className, content }) => (
          <Badge key={id} className={className}>
            {content}
          </Badge>
        ))}
      </div>
    );
  };

  return displayBadges();
};