import { request } from './index';

export const apiRequest = ({
  url,
  method,
  data,
  callbacks = {},
  reduxHandlers = {},
  rawResponse = false,
  refreshedToken,
}) => {
  request.defaults.reduxHandlers = reduxHandlers;
  request.defaults.callbacks = callbacks;
  const { loading, success, error } = callbacks;
  const { reduxLoading, reduxSuccess, reduxError } = reduxHandlers;
  loading && loading(true);
  reduxLoading && reduxLoading(true);

  const token = refreshedToken || localStorage.getItem('token');

  if (token) {
    request.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    request.defaults.headers['Authorization'] = '';
  }

  return request({ url, method, data })
    .then((res) => {
      reduxSuccess && reduxSuccess(rawResponse ? res : res.data);
      return success && success(rawResponse ? res : res.data);
    })
    .catch((err) => {
      reduxError && reduxError(err.err || err);
      error && error(err.err || err);
    });
};
