import React from 'react';
import { classNames } from '@src/utils';

export const InfoBox = ({ children, height, className }) => (
  <div
    className={classNames(['info-box', className && className])}
    style={{ height: `${height}px` }}
  >
    {children}
  </div>
);
