import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom';
import {
  NotFoundPage,
  ServerErrorPage,
  Account,
  OfferingDetails,
  InstructorDetails,
  Tests
} from '@components/features';
import {
  GlobalHeader, 
  GlobalFooter,
  GlobalAbout,
  GlobalContact,
  GlobalFaculty,
  GlobalPrograms,
  GlobalProviders,
  GlobalSchedule
} from '@components/global';
import { AccountHeader } from '@components/features';
import { useSelector } from 'react-redux';

export const MainContent = () => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const location = useLocation();
  const initTab = location.pathname.split('/')[1];

  const renderHeader = () => {
    if (initTab === 'account') {
      return (
        <AccountHeader />
      );
    } else {
      return (
        <GlobalHeader />
      );
    }
  }

  useEffect(() => {
    renderHeader();
  }, [location])

  return (
    <>
      {renderHeader()}

      <main className="content">
        <Switch>
          <Route path="/" exact>
            <Redirect to="/programs" />
          </Route>

          <Route path="/programs" exact>
            <GlobalPrograms />
          </Route>

          <Route path="/faculty" exact>
            <GlobalFaculty />
          </Route>

          <Route path="/schedule">
            <GlobalSchedule />
          </Route>

          {/* <Route path="/test">
            <Tests />
          </Route> */}

          <Route path="/account" >
            {isAuthorized ? <Account /> : <Redirect to="/" />}
          </Route>

          {/* <Route path='/offering'>
            <OfferingDetails />
          </Route> */}

          <Route path="/faculty/:instructorId">
            <InstructorDetails />
          </Route>

          {/* <Route path="/providers" exact>
            <GlobalProviders />
          </Route>

          <Route path="/about" exact>
            <GlobalAbout />
          </Route>

          <Route path="/contact" exact>
            <GlobalContact />
          </Route> */}

          <Router path="/server-error">
            <ServerErrorPage />
          </Router>

          <Route path="*">
            <NotFoundPage />
          </Route>

          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </main>

      {/* <GlobalFooter /> */}
    </>
  );
}