import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Icon,
  SingleDateSchedulePicker,
  ErrorMessage,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
  PaymentMethodSelector,
  PaymentMethodButton,
} from '@components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { getScaleSlots, getScalePrice } from '@actions/registrationActions';
import { parseOnlyAmount, useForm } from '@src/hooks';
import { classNames, formatPrice, debounce } from '@src/utils';
import { normalizeScaleRegData } from '@src/normalizers';

export const SlidingScale = ({ sessionInfo, btnCallbacks, contentType }) => {
  const slots = useSelector((state) => state.registration.regSlots);
  const price = useSelector((state) => state.registration.regPrice);
  const [daysData, setDaysData] = useState([]);
  const [scheduleErrors, setScheduleErrors] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScaleSlots(sessionInfo.id));
  }, []);

  const submitHandler = (values) => {
    const scaleRegData = {
      selectedSchedules,
      scaleId: activeScale.id,
      donation: {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      },
    };
    return btnCallbacks.submit(normalizeScaleRegData(scaleRegData));
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    selectedDays: {
      validation: ['isLengthRequired'],
    },
    activeScale: {
      validation: ['isRequired'],
    },
    selectedSchedules: {
      customValidation: [
        {
          check: () => validateTimeslots(),
          message: 'Select a Timeslot to proceed',
        },
      ],
    },
  };

  const validateTimeslots = () => {
    const selectedDates = selectedSchedules.map((day) => day.date);
    const failedSchedules = daysData.filter(
      (day) => !selectedDates.includes(day.date)
    );
    setScheduleErrors(failedSchedules.map(({ date }) => date));

    return !failedSchedules.length;
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    selectedDays: [],
    selectedSchedules: [],
    activeScale: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    setErrors,
    handleSubmit,
    validateOnSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { selectedSchedules, selectedDays, activeScale } = values;

  useEffect(() => {
    if (activeScale && selectedSchedules.length) {
      const reqData = {
        selectedSchedules,
        scaleId: activeScale.id,
      };
      getPrice(reqData);
    }
  }, [selectedSchedules, activeScale]);

  useEffect(() => {
    if (scheduleErrors.length) {
      const selectedDates = selectedSchedules.map((day) => day.date);
      setScheduleErrors((errors) =>
        errors.filter((error) => !selectedDates.includes(error))
      );
    }
  }, [selectedSchedules]);

  const getPrice = useCallback(
    debounce((data) => {
      dispatch(getScalePrice(data));
    }, 500),
    []
  );

  const renderScales = (scales) =>
    scales.map((scale) => {
      const { rate, id } = scale;
      return (
        <div className="columns__item" key={id}>
          <button
            onClick={() => setCustomValue('activeScale', scale)}
            type="button"
            className={classNames([
              'switcher',
              activeScale && activeScale.id === id && 'switcher--active',
            ])}
          >
            <span className="switcher__content">
              {formatPrice(rate)}$ per {contentType.singular_short_name}
            </span>
          </button>
        </div>
      );
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          Please choose one of these scaled rates based on your financial
          resources
        </h4>
      </div>
      <div className="registration-step__group registration-step__group--blocks-select">
        <div className="columns columns--4">
          {renderScales(sessionInfo.sliding_scale_registration.rates)}
        </div>
        <ErrorMessage
          show={!!errors.activeScale}
          text={'Select a Scaled Rate to proceed'}
        />
      </div>
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          Please choose the dates of the {contentType.plural_short_name} you
          would like to attend
        </h4>
      </div>
      <div className="registration-step__group registration-step__group--calendar">
        <SingleDateSchedulePicker
          slots={slots}
          errors={errors}
          setErrors={setErrors}
          selectedDays={selectedDays}
          daysData={daysData}
          setDaysData={setDaysData}
          selectedSchedules={selectedSchedules}
          contentType={contentType}
          setCustomValue={setCustomValue}
          scheduleErrors={scheduleErrors}
        />
      </div>
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={selectedSchedules.length && activeScale && price.data}
            rate={activeScale?.rate}
            contentType={contentType}
            loading={price.loading}
            values={values}
          />
          <PaymentMethodSelector />
        </div>
      </div>
      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <PaymentMethodButton
          handleSubmit={handleSubmit}
          values={values}
          validateOnSubmit={validateOnSubmit}
          amount={price.data}
        />
      </div>
    </form>
  );
};