import React, { useEffect } from 'react';
import { getUserData, updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData, normalizeUserDataErrors } from '@src/normalizers';
import { Input, PhoneListInput } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

// import { defaultPhoneFormatMask } from '@src/constants';
// import { getFormattedPhone } from '@src/utils';

export const EmergencyContacts = ({   
  userData, 
  editorState, 
  setEditorState, 
  dispatch 
}) => {
  const { student } = userData.data || {};
  const {
    emergency_contact_name,
    emergency_contact_phone,
    health_concerns,
  } = student || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData, handleRequest, handleServerErrors));
  };

  const handleRequest = () => {
    setEditorState('');
  };

  const handleServerErrors = (errors) => {
    const { data } = errors;

    if (data?.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  const initialValues = {
    emergencyContactName: emergency_contact_name,
    emergencyContactPhone: emergency_contact_phone,
    healthConcerns: health_concerns,
  };

  // const initialValues = {
  //   emergencyContactName: emergency_contact_name,
  //   emergencyContactPhone: getFormattedPhone(emergency_contact_phone),
  //   healthConcerns: health_concerns,
  // };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
    setValues,
    setCustomValue,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const emergencyBlockData = [
    {
      label: 'Emergency Contact Name',
      inputType: Input,
      inputName: 'emergencyContactName',
      placeholder: 'Enter Emergency Contact Name',
    },
    {
      label: 'Emergency Contact Phone',
      inputType: PhoneListInput,
      inputName: 'emergencyContactPhone',
      placeholder: 'Enter Emergency Contact Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('emergencyContactPhone', value),
      },
    },
    // {
    //   label: 'Emergency Contact Phone',
    //   inputType: Input,
    //   inputName: 'emergencyContactPhone',
    //   placeholder: 'Enter Emergency Contact Phone',
    //   additionalProps: {
    //     mask: defaultPhoneFormatMask,
    //   },
    // },
    {
      label: 'Health Concerns',
      inputType: Input,
      inputName: 'healthConcerns',
      placeholder: 'List your Health Concerns',
    },
  ];

  const profileBlocksData = [
    {
      title: 'Emergency Contacts',
      data: emergencyBlockData,
      name: 'emergencyContacts',
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};