export const normalizeUserDataErrors = (userErrors) => {
  const errors = {}
  Object.entries(userErrors).forEach(([name, value]) => errors[name] = value[0])

  const normalizedErrors = {
    firstName: errors['contact.first_name'],
    middleName: errors['contact.middle_name'],
    lastName: errors['contact.last_name'],
    birthDate: errors['contact.date_of_birth'],
    cellPhone: errors['contact.cell_phone'],
    homePhone: errors['contact.home_phone'],
    workPhone: errors['contact.work_phone'],
    parentName: errors['student.guardian_contact_name'],
    parentPhone: errors['student.guardian_contact_phone'],
    userName: errors['user.login'],
    email: errors['user.email'],
    password: errors['user.password'],
    confirmPassword: errors['user.password_confirmation'],
    oldPassword: errors['user.old_password'],
    address: errors['contact.adress.address_1'],
    country: errors['contact.adress.country_id'],
    city: errors['contact.adress.city'],
    state: errors['contact.adress.state_id'],
    zip: errors['contact.adress.zip'],
    emergencyContactName: errors['student.emergency_contact_name'],
    emergencyContactPhone: errors['student.emergency_contact_phone'],
    healthConcerns: errors['student.health_concerns'],
    underAge: errors['student.under_eighteen'],
    agreePolicy: errors['student.is_terms_and_conditions_agreed'],
    subscribeEmail: errors['student.receive_email_newsletters'],
    subscribeSnail: errors['student.receive_snail_mail'],
    tookClassesBefore: errors['student.took_classes_before'],
    mediaWaiver: errors['student.is_media_consent_and_waiver_agreed'],
  };

  return normalizedErrors;
};