import React, { useState } from 'react';
import { startOfDay } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { setDayDate } from '@actions/scheduleActions';
import { queryParam, zonedDate } from '@src/utils';
import { CalendarDropdown } from '@components/shared';

export const WeekPagination = () => {
  const today = startOfDay(new Date());
  const querryDate = queryParam.get('chosen-date');
  const initDate = querryDate && querryDate !== 'closest' ? zonedDate(querryDate) : today;

  const [selectedDay, setSelectedDay] = useState(initDate);
  const [date, setDate] = useState(selectedDay);
  const dayDate = useSelector((state) => state.schedule.dayDate);

  const dispatch = useDispatch();

  return (
    <>
      <CalendarDropdown 
        setDate={setDate}
        today={today}
        selectedDay={dayDate}
        setSelectedDay={(day) => dispatch(setDayDate(day))}
        onMonthChange={setDate}
        isScheduleWeekCalendar
      />
    </>
  );
};