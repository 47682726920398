import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link } from '@components/shared';
import { classNames } from '@src/utils';

export const InstructorName = ({ course, className, hasLink, hasIcon, isInline, separator }) => {
  const { main_instructor_1, main_instructor_2, assistant_instructors = [] } =
    course || {};

  const instructorsData = [
    main_instructor_1,
    main_instructor_2,
    ...assistant_instructors,
  ];

  const instructorsToRender = instructorsData.filter((assistant) => assistant);

  const renderInstructorNames = (instructors) => {
    return hasLink
      ? instructors.map((instructor, i, arr) => (
        <span key={instructor.id} style={{display: "inline-flex"}}>
          <Link
            key={instructor.id}
            href={`${window.location.origin}/faculty/${instructor.id}`}
            iconLeft={hasIcon && <Icon type="person-solid" />}
          >
            {instructor.display_name}
          </Link>
          {i !== arr.length - 1 && <>,&nbsp;</>}
        </span>
      ))
      : instructors.map((instructor, i, arr) => {
          return (
            <span key={instructor.id} style={{display: "inline-flex"}}>
              <span
                key={instructor.id}
                className={classNames(['instructor-name', className])}
              >
                {instructor.display_name}
              </span>
              {i !== arr.length - 1 && <>,&nbsp;</>}
            </span>
          );
        });
  };

  return <div className={className}>{course && renderInstructorNames(instructorsToRender)}</div>;
};

InstructorName.propTypes = {
  className: PropTypes.string,
  hasLink: PropTypes.bool,
  hasIcon: PropTypes.bool,
  course: PropTypes.object,
};

InstructorName.defaultProps = {
  className: '',
  hasLink: true,
  hasIcon: false,
  course: {},
};
