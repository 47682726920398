import React, { useEffect } from 'react';
import { zonedDate } from '@src/utils';
import { isBefore, getDaysInMonth } from 'date-fns';
import { useApi } from '@src/hooks';
import { CalendarDropdown } from '@components/shared';

export const AccountScheduleCalendar = ({ 
  date,
  setDate, 
  today, 
  selectedDay, 
  setSelectedDay, 
}) => {
  const selectedYear = date.getFullYear();
  const selectedMonth = date.getMonth() + 1;
  const staticDayPickerArr = document.querySelectorAll('.calendar-dropdown__static .MuiPickersDay-root:not(.MuiPickersDay-dayOutsideMonth)');
  const staticCalendarContainer = document.querySelector('.calendar-dropdown__static .PrivatePickersSlideTransition-root');

  const userScheduleDates = useApi(
    {
      url: `/account/appointments/dates?month=${selectedMonth}&year=${selectedYear}`,
      dataPlaceholder: [],
    },
    [date]
  );

  const formattedScheduleDates = userScheduleDates.data.map((date) =>
    zonedDate(date)
  );

  const pastEvents = formattedScheduleDates.filter((day) => isBefore(day, today)).map((day => day.getDate()));
  const futureEvents = formattedScheduleDates.filter((day) => !isBefore(day, today)).map((day => day.getDate()));

  useEffect(() => {
    staticDayPickerArr.forEach((day, index) => {
      staticCalendarContainer.insertBefore(day, staticCalendarContainer.children[day]);
      if (index + 1 <= getDaysInMonth(selectedDay)) {
        staticCalendarContainer.insertBefore(day, staticCalendarContainer.children[day]);
      } else {
        staticCalendarContainer.removeChild(day, '');
      }
    })
  })

  return (
    <CalendarDropdown
      setDate={setDate}
      today={today}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      onMonthChange={setDate} 
      pastEvents={pastEvents}
      futureEvents={futureEvents}
      isStaticCalendar
      isAccountCalendar
    />
  );
};