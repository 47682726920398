import React from 'react';
import { useDidUpdate } from '@src/hooks';
import { classNames, changeBodyScroll } from '@src/utils';
import { Button, Icon } from '@components/shared';
import PropTypes from 'prop-types';

export const SlideMenu = ({ children, isShown, className, onCloseClick, position = "right" }) => {
  // useDidUpdate(() => {
  //   changeBodyScroll(isShown, 'slide');
  // }, [isShown]);

  const isFilters = className === 'mobile-filters';

  return (
    <div
      className={classNames([
        'slide-menu',
        isShown && 'slide-menu--shown',
        `slide-menu--${position}`,
        className,
      ])}
    >
      <div className="slide-menu__overlay" onClick={onCloseClick} />
      <div className="slide-menu__inner">
        {isFilters && (
          <div className="slide-menu__header">
            <Button
              type="navigation"
              className="slide-menu__close-btn"
              onClick={() => onCloseClick()}
            >
              <Icon type={'close-cross'} />
            </Button>
          </div>
        )}
        <div className="slide-menu__body">
          {children}
          {isFilters && (
            <div className="slide-menu__btn-wrapper">
              <Button
                type="primary"
                className="slide-menu__result-btn"
                onClick={onCloseClick}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SlideMenu.propTypes = {
  children: PropTypes.node,
  isShown: PropTypes.bool,
  className: PropTypes.string,
};

SlideMenu.defaultProps = {
  children: null,
  isShown: false,
  className: '',
};
