import React, { useState } from 'react';
import { Loader, Icon, Button } from '@components/shared';
import { AccountTransactionsCard } from './AccountTransactionsCard';
import { useViewport, useApi } from '@src/hooks';
import { openLink } from '@src/utils';

export const AccountTransactions = () => {
  const [pageCounter, setPageCounter] = useState(1);
  const { isMobile } = useViewport();

  const accountTransactions = useApi(
    {
      url: `/account/transactions?page=${pageCounter}`,
      dataPlaceholder: [],
      reduce: true,
    },
    [pageCounter]
  );

  const onSchedulLinkClick = () => {
    const schedulePageLink = `${window.location.origin}/schedule`;
    openLink(schedulePageLink, true);
  };

  const renderNoOrdersBanner = () => {
    return (
      <div className="account__no-items account-transactions__no-orders">
        <Icon type="wallet" />
        {!accountTransactions?.data?.length && !accountTransactions.loading && (
          <p>
            You haven't made any transactions yet. 
            {/* You can purchase a Content /
            Offering on the{' '}
            <Button type="link" onClick={onSchedulLinkClick}>
              Schedule page
            </Button> */}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="account-transactions">
      <h2 className='account__content-title'>Transactions</h2>
      <Loader sticky show={accountTransactions.loading} />
      {accountTransactions?.data?.length ? (
        <>
          <div className="ftable">
            {!isMobile && (
              <div className="ftable__row account-transactions__table-heading">
                <div className="ftable__cell account-transactions__id">
                  <div className="ftable__cell-content">ID</div>
                </div>
                <div className="ftable__cell account-transactions__date">
                  <div className="ftable__cell-content">DATE</div>
                </div>
                <div className="ftable__cell account-transactions__class-title">
                  <div className="ftable__cell-content">TITLE</div>
                </div>
                <div className="ftable__cell account-transactions__amount">
                  <div className="ftable__cell-content">AMOUNT</div>
                </div>
                <div className="ftable__cell account-transactions__action">
                  <div className="ftable__cell-content"></div>
                </div>
              </div>
            )}
            {accountTransactions.data.map((transaction) => (
              <AccountTransactionsCard
                transaction={transaction}
                key={transaction.id}
              />
            ))}
          </div>
          {accountTransactions.hasNextPage && (
            <Button
              className="account__more-button account-transactions__more-button"
              onClick={() => setPageCounter(pageCounter + 1)}
              iconRight={<Icon type="chevron" size={'sm'} />}
            >
              View More (5)
            </Button>
          )}
        </>
      ) : (
        renderNoOrdersBanner()
      )}
    </div>
  );
};
