import React, { useEffect } from 'react';
import { getUserData, updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { normalizeUserData, normalizeUserDataErrors } from '@src/normalizers';
import { PhoneListInput } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

// import { defaultPhoneFormatMask } from '@src/constants';
// import { getFormattedPhone } from '@src/utils';
// import { Input } from '@components/shared';

export const ContactInformation = ({   
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const { contact } = userData.data || {};
  const {
    home_phone,
    work_phone,
    cell_phone,
  } = contact || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);

    dispatch(updateUserData(userData, handleRequest, handleServerErrors));
  };

  const handleRequest = () => {
    setEditorState('');
  };

  const handleServerErrors = (errors) => {
    const { data } = errors;

    if (data?.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  const initialValues = {
    cellPhone: cell_phone,
    homePhone: home_phone,
    workPhone: work_phone,
  };

  // const initialValues = {
  //   cellPhone: getFormattedPhone(cell_phone),
  //   homePhone: getFormattedPhone(home_phone),
  //   workPhone: getFormattedPhone(work_phone),
  // };


  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
    setValues,
    setCustomValue,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const contactBlockData = [
    {
      label: 'Cell Phone',
      inputType: PhoneListInput,
      inputName: 'cellPhone',
      placeholder: 'Enter Cell Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('cellPhone', value),
      },
    },
    {
      label: 'Work Phone',
      inputType: PhoneListInput,
      inputName: 'workPhone',
      placeholder: 'Enter Work Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('workPhone', value),
      },
    },
    {
      label: 'Home Phone',
      inputType: PhoneListInput,
      inputName: 'homePhone',
      placeholder: 'Enter Home Phone',
      additionalProps: {
        onChange: (value) => setCustomValue('homePhone', value),
      },
    },
  ];

  // const contactBlockData = [
  //   {
  //     label: 'Cell Phone',
  //     inputType: Input,
  //     inputName: 'cellPhone',
  //     placeholder: 'Enter Phone',
  //     additionalProps: {
  //       mask: defaultPhoneFormatMask,
  //     },
  //   },
  //   {
  //     label: 'Work Phone',
  //     inputType: Input,
  //     inputName: 'workPhone',
  //     placeholder: 'Enter Phone',
  //     additionalProps: {
  //       mask: defaultPhoneFormatMask,
  //     },
  //   },
  //   {
  //     label: 'Home Phone',
  //     inputType: Input,
  //     inputName: 'homePhone',
  //     placeholder: 'Enter Phone',
  //     additionalProps: {
  //       mask: defaultPhoneFormatMask,
  //     },
  //   },
  // ];

  const profileBlocksData = [
    {
      title: 'Contact Information',
      data: contactBlockData,
      name: 'contact',
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};