import React, { useState } from 'react';
import { useViewport } from '@src/hooks';
import { classNames } from '@src/utils';
import { Button, Loader, Icon } from '@components/shared';

export const AccountProfileCard = (block) => {
  const {
    name,
    title,
    data,
    additionalButtons,
    editRestricted,
    subscription,
    infoText,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    userData,
    editorState, 
    setEditorState,
    clearValues,
    setErrors,
  } = block;
  const { isMobile } = useViewport();
  const [ isOpenAccordion, setIsOpenAccordion ] = useState(false);

  const onCancelEditClick = () => {
    clearValues(true);
    setEditorState('');
    setErrors({});
  };

  const isEditing = editorState === name;

  const EditorBlock = () => {
    return (
      <div className="account-profile__block-edit-action">
        {!editorState && !editRestricted && (
          <Button
            onClick={() => setEditorState(name)}
            iconLeft={<Icon type="edit-action" />}
            type="navigation"
          >
            Edit
          </Button>
        )}
        {additionalButtons}
      </div>
    )
  };

  return (
    <form
      key={title}
      className={classNames([
        'account-profile__block',
        `account-profile__block--${name}`,
        `${isOpenAccordion ? 'account-profile__block--open' : ''}`,
        isEditing && 'account-profile__block--edited',
      ])}
      onSubmit={handleSubmit}
    >
      <div className="account-profile__block-header"
        onClick={() => isMobile && setIsOpenAccordion(!isOpenAccordion)}
      >
        <div className="account-profile__block-title">{title}</div>

        {isMobile && <Icon size="sm" type="chevron" />}

        {!isMobile && <EditorBlock />}
      </div>

      <div className="account-profile__block-body">
        <Loader show={userData.updateLoading && isEditing} attached />

        {isMobile && <EditorBlock />}

        {!isEditing && infoText && (
          <div className="account-profile__info-text">{infoText}</div>
        )}
        <ul className="list">
          {data?.map((item) => {
            const {
              label,
              inputName,
              inputType: RenderInput,
              additionalProps,
              placeholder,
              hide,
            } = item;

            return (
              <React.Fragment key={label}>
                {!hide && (
                  <li className={`list-item list-item--${inputName}`}>
                    <RenderInput
                      name={inputName}
                      value={values[inputName]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isEditing}
                      label={label}
                      placeholder={
                        !isEditing && !values[inputName] ? '–' : placeholder
                      }
                      alert={errors[inputName]}
                      {...additionalProps}
                    />
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>

        {subscription}
        
        {isEditing && (
          <div className="account-profile__block-footer">
            <Button type="transparent" onClick={onCancelEditClick}>
              Cancel
            </Button>

            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};