import { queryParam } from '@src/utils';

export const getRegistrationProceedLink = () => {
    const { origin, pathname } = window.location;
    const classId = queryParam.get('classId');
    const sessionId = queryParam.get('sessionId');
    const regType = queryParam.get('regType');
    const regStep = regType ? 3 : 1;
    const redirectUrl = `${origin}${pathname}?&regStep=${regStep}&classId=${classId}&sessionId=${sessionId}${
      regType ? `&regType=${regType}` : ''
    }`;

    return redirectUrl;
  };
  