import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@components/layout';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {getFilters, setScheduleActiveTab} from '@actions/scheduleActions';
import { setActiveTab, createTab, classNames } from '@src/utils';
import { getModal, openModal, Tabs } from '@components/shared';
import { 
  Day, 
  Week, 
  Categories, 
  Instructors 
} from '@components/features/Schedule';

export const Schedule = ({ className, activeTab }) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const href = window.location.href;
  const history = useHistory();

  const dispatch = useDispatch();

  const schedulePath = '/schedule'

  const [navTabs, setNavTabs] = useState([
    createTab('Week', `${path}/week`),
    createTab('Day', `${path}/day`),
    createTab('Instructor', `${path}/instructor`),
    createTab('Category', `${path}/category`),
  ]);

  useEffect(() => {
    setActiveTab(setNavTabs, activeTab)

    if(pathname !== schedulePath) {
      setActiveTab(setNavTabs, pathname);
    }

    history.listen((location) => {
      setActiveTab(setNavTabs, location.pathname);
    });

    dispatch(getFilters());
  }, []);

  const cardOnClickHandler = (id, currentEventDetails, matomoHandler) => {
    localStorage.setItem('returnHref', href);
    localStorage.setItem('returnPath', 'schedule');

    openModal(
      getModal({
        name: 'class-details',
        data: { eventId: currentEventDetails.id },
      })
    );

    matomoHandler();
  };

  const onScheduleTabClick = ({value}) => {
    history.push(value);
    dispatch(setScheduleActiveTab(value))
  }

  return (
    <Container>
      <div
        className={classNames([
          "global-page",
          "schedule", 
          className
        ])}
      >
        <h1 className="global-page__title schedule__title">Schedule</h1>
        <Tabs
          tabsData={navTabs}
          label="display by:"
          className="tabs--bordered schedule__tabs"
          onTabClick={(tab) => {
            onScheduleTabClick(tab)
          }}
        />

        <Switch>
          <Route path={`${path}/day`} exact>
            <Day onCardClick={cardOnClickHandler} />
          </Route>

          <Route path={`${path}/week`} exact>
            <Week onCardClick={cardOnClickHandler} />
          </Route>

          <Route path={`${path}/instructor`} exact>
            <Instructors onCardClick={cardOnClickHandler} />
          </Route>

          <Route path={`${path}/category`} exact>
            <Categories onCardClick={cardOnClickHandler} />
          </Route>

          <Route>
            <Redirect to={activeTab} />
          </Route>
        </Switch>
      </div>
    </Container>
  );
};
