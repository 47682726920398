import React, { useRef } from 'react';
import { dailyCellHeight } from '@src/constants';
import { ScheduleCard } from '../ScheduleCard';

export const DailyScheduleCard = ({
  event,
  rowspan,
  colorCellBackground,
  cardOnClickHandler,
  eventEndAfterMidnight,
}) => {
  const initialCardHeight = useRef(null);
  const cardContainer = useRef(null);

  const expandCard = (e) => {
    const element = e.currentTarget;
    const elementHeight = element.offsetHeight;
    const elementScrollHeight = element.scrollHeight;

    if (elementHeight < elementScrollHeight) {
      if (!initialCardHeight.current) initialCardHeight.current = elementHeight;

      element.style.height = elementScrollHeight + 'px';
      element.style.zIndex = '2';

      if (eventEndAfterMidnight) {
        cardContainer.current.style.top = 'auto';
        cardContainer.current.style.bottom = '-100%';
      }
    }
  };

  const reduceCard = (e) => {
    const element = e.currentTarget;

    element.style.height = initialCardHeight.current + 'px';
    element.style.zIndex = '1';
  };

  return (
    <div
      className='schedule-card__container'
      style={{
        height: dailyCellHeight * rowspan + 'px',
        background: colorCellBackground,
      }}
      onMouseLeave={reduceCard}
      onMouseEnter={expandCard}
      onFocus={expandCard}
      onBlur={reduceCard}
      ref={cardContainer}
    >
      <ScheduleCard event={event} cardOnClickHandler={cardOnClickHandler} />
    </div>
  );
};
