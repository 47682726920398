export const dailyCellHeight = 55;

export const splitSign = ':';

export const minutesStep = 15;

export const tableRoot = '@';

export const endScheduleTime = 24;

export const dateFormatRequest = 'yyyy-MM-dd';

export const dateFormatView = 'MMM d, yyyy';

export const userTicketsDateFormat = 'MMMM d, yyyy';

export const dateAndMonthShort = 'MMM d';

export const dayFormatView = 'eeee, MMM d, yyyy';

export const dayFormatMobileView = 'eee, MMM d, `yy';

export const dateWeeklyViewFormat = 'MMM d, yyyy';

export const dateSessionsFormat = 'M/d/yyyy';

export const dateSessionsDemoFormat = 'M/d';

export const dateInactivityFormat = ' MMM d, yyyy';

export const dateBlockStartFormat = 'iii, MMM d, yyyy';

export const timeFormatView = 'hh:mm aaaa';

export const twelveHoursFormat = 'hh:mm aaa';

export const datePickerCaptionFormat = 'MMM yyyy';

export const dateEventsCalendarFormat = 'yyyy MM d';

export const allowedDatesEventsCalendarFormat = 'd MMMM, yyyy';

export const dropInSelectedFormat = 'MM/d/yyyy';

export const dropInRegisterFormat = 'yyyy-MM-dd HH:mm:ss';

export const dateBackEndFormat = '';

export const userProfileDateFormat = 'MM/dd/yyyy';

export const receiptDateFormat = 'MM/dd/yyyy';

export const ticketDateFormat = 'MM/dd/yyyy';

export const receiptTimeFormat = 'hh:mm aaa';

export const checkoutDateFormat = 'MM/dd/yy - EEEEEE';

export const minAgeRangeDefault = 0;

export const maxAgeRangeDefault = 120 * 12;

export const defaultPhoneFormatMask = [
  '+',
  '1',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const defaultBirthDateFormatMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const ageRangeYearMask = [/[0-9]/, /[0-9]?/, /[0-9]?/, /[y]/];

export const ageRangeMonthMask = [/[0-9]/, /[0-9]?/, /[m]/];

export const fullWeekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const shortWeekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const weekdaysObject = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
};

export const tableColors = [
  '250, 243, 247',
  '246, 249, 251',
  '255, 242, 234',
  '240, 240, 240',
  '255, 231, 231',
  '243, 255, 237',
  '247, 247, 247',
  '255, 255, 238',
  '234, 255, 254',
  '238, 245, 255',
  '255, 233, 244',
  '222, 233, 226',
];

export const monthSelectArray = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

export const serviceInfoSources = [
  {
    value: 'Saw CCCA info on the following social media site:',
    label: 'Saw CCCA info on the following social media site:',
  },
  {
    value: 'Was referred by a friend or family member',
    label: 'Was referred by a friend or family member',
  },
  {
    value: 'Saw one of the Soofa Sign ads',
    label: 'Saw one of the Soofa Sign ads',
  },
  {
    value: 'Walked into the CCCA and spoke to someone',
    label: 'Walked into the CCCA and spoke to someone',
  },
  {
    value: 'Saw a flyer posted around town',
    label: 'Saw a flyer posted around town',
  },
  {
    value: 'Visited the BCCA/CCCA websites',
    label: 'Visited the BCCA/CCCA websites',
  },
  {
    value: 'Read/saw info on news media',
    label: 'Read/saw info on news media',
  },
  {
    value: 'Heard about it from BioMed Realty',
    label: 'Heard about it from BioMed Realty',
  },
  { value: 'Other', label: 'Other' },
];

export const registerTypes = {
  b: 'block',
  d: 'drop-in',
  s: 'sliding-scale',
  p: 'pre-registration',
  f: 'free',
  v: 'videos',
  t: 'tickets',
  tr: 'trial'
};

export const enrollmentTypes = {
  o: 'Ongoing',
  s: 'Session',
};

export const countriesWithStates = [
  'Australia',
  'Canada',
  'Japan',
  'United States',
];
