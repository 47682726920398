import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, CheckboxesBranch, Icon } from '@components/shared';
import { classNames } from '@src/utils';
import _ from 'lodash';

export const ProgramsFilterCheckSection = ({
  title,
  identifier,
  data = [],
  values,
  setCustomValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const filtersSelect = useSelector((state) => state.programs.filtersSelect);

  const onExpandClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (_.isEmpty(filtersSelect)) {
      setIsOpen(false);
    }
  }, [filtersSelect]);

  useEffect(() => {
    values[identifier] && values[identifier].length
      ? setCheckedAll(true)
      : setCheckedAll(false);
  }, [values[identifier]]);

  const onCheckAllClick = () => {
    setCheckedAll(true);
    return identifier === 'category'
      ? setCustomValue(identifier, getAllChildren(data))
      : setCustomValue(
          identifier,
          data.map((elem) => elem.id)
        );
  };

  const getAllChildren = (data) => {
    const childrenIds = [];

    (function getChildren(data) {
      data.forEach((item) => {
        childrenIds.push(item.id);
        if (item.children) {
          getChildren(item.children);
        }
      });
    })(data);

    return childrenIds;
  };

  const onUncheckAllClick = () => {
    setCustomValue(identifier, []);
    setCheckedAll(false);
  };

  const onCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;

    const valuesToSet = isChecked
      ? [...values[identifier], id]
      : values[identifier].filter((value) => value !== id);

    setCustomValue(identifier, valuesToSet);
  };

  const renderFormGroup = (formGroupData) =>
    formGroupData.map((item) => (
      <div className="form__group" key={item.id}>
        <Checkbox
          name={identifier + item.id}
          onChange={(e) => onCheckboxChange(e, item.id)}
          value={(values[identifier] || []).includes(item.id)}
          label={item.name}
        />
      </div>
    ));

  const renderNestedFormGroup = (formGroupData) => {
    return formGroupData.map((item) => (
      <CheckboxesBranch
        data={item}
        identifier={identifier}
        setCustomValue={setCustomValue}
        values={values[identifier]}
        key={item.id}
        isCheckedAll={isCheckedAll}
      />
    ));
  };

  const { length: dataLength } = data;

  const pointsToShow = 6;
  const renderData =
    dataLength > pointsToShow && !isOpen ? data.slice(0, pointsToShow) : data;

  return (
    <>
      <div
        className={classNames([
          'form__section',
          identifier === 'category' && 'form__section--nested',
        ])}
      >
        <div className="form__group-title-row">
          <div className="form__group-title">{title}</div>
          <div className="form__group-title-action">
            <Button
              onClick={() =>
                isCheckedAll ? onUncheckAllClick() : onCheckAllClick()
              }
              className="programs__filter-check-all"
              type="navigation"
            >
              {isCheckedAll ? 'Uncheck all' : 'Check All'}
            </Button>
          </div>
        </div>
        {renderData.length &&
          (identifier === 'category'
            ? renderNestedFormGroup(renderData)
            : renderFormGroup(renderData))}
        {dataLength > pointsToShow && (
          <div className="form__group">
            <Button
              className={classNames([
                'programs__filter-view-more',
                isOpen && 'programs__filter-view-more--open',
              ])}
              iconRight={<Icon type="chevron" size="sm" />}
              onClick={onExpandClick}
            >
              {isOpen ? 'Hide' : `View More (${dataLength - pointsToShow})`}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};