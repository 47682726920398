import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '@actions/authActions';
import { useApi, useViewport } from '@src/hooks';
import { createTab, changeBodyScroll } from '@src/utils';
import { Header } from '@components/layout';
import { AccountNavigation } from './AccountNavigation';
import {
  Button,
  getModal,
  Icon,
  openModal,
  Tooltip,
  Tabs,
} from '@components/shared';

export const AccountHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isTablet } = useViewport();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const userData = useApi({ url: '/account/profile' });

  const [pageTabs, setPageTabs] = useState([
    createTab('Programs', '/programs'),
    createTab('Schedule', '/schedule'),
    createTab('Faculty', '/faculty'),
    // createTab('Providers', '/providers'),
    // createTab('About', '/about'),
    // createTab('Contact', '/contact'),
  ]);

  const onNavTabClick = (tab) => {
    setIsMobileNavOpen(false);
    changeBodyScroll(false, 'modal');

    if(localStorage.getItem('returnPath') || localStorage.getItem('returnHref')) {
      localStorage.removeItem('returnHref');
      localStorage.removeItem('returnPath');
    }

    history.push(tab.value);
  };


  const onLogoutClick = () => {
    dispatch(logout());
  };

  const onDonationButtonClick = () => {
    setIsMobileNavOpen(false);
    openModal(
      getModal({
        name: 'donation',
      })
    );
  };

  const renderNavigation = () => {
    return (
      <>
        <div>
          <Tabs
            tabsData={pageTabs}
            onTabClick={(tab) => onNavTabClick(tab)}
            isShadowVisible={false}
          />
          
          {isTablet && 
            <div>
              <Button 
                className='account-opener'  
                iconRight={<Icon type="chevron" size="sm" />}
              >
                Account
              </Button>

              <AccountNavigation 
                setIsMobileNavOpen={setIsMobileNavOpen} 
              />
            </div>
          }
        </div>

        <ul className="list">
          {isAuthorized &&
            <>
              <li className="list__item list__item--donation">
                <Tooltip
                  position="bottom"
                  className="header__donation-tooltip"
                  body={
                    <Button
                      className="header__donation-btn"
                      iconLeft={<Icon type="donate" />}
                      onClick={onDonationButtonClick}
                    >
                      Donate
                    </Button>
                  }
                >
                  Make a tax-deductible donation
                </Tooltip>
              </li>

              {userData.data && !userData.loading &&
                <li className="list__item list__item--user">
                  <Button
                    iconLeft={<Icon type="profile-tab" />}
                  >
                    {userData.data?.display_name}
                  </Button>
                </li>
              }

              <li className="list__item list__item--log-out">
                <Button
                  type="navigation"
                  iconLeft={<Icon type="log-out" size="md" />}
                  onClick={onLogoutClick}
                >
                  Log out
                </Button>
              </li>
            </>
          }
        </ul>
      </>
    );
  };

  return (
    <Header 
      className="account__header"
      isMobileNavOpen={isMobileNavOpen}
      setIsMobileNavOpen={setIsMobileNavOpen}
      renderNavigation={renderNavigation}
    />
  );
};