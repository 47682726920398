import React, { useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { getDailyTimeRangeArray } from './getDailyTimeRangeArray';
import { getDayTableArray } from './getDayTableArray';
import { DailyRootCell } from './DailyRootCell';
import { DailyScheduleCard } from './DailyScheduleCard';
import { format } from 'date-fns';
import { splitSign, tableRoot, timeFormatView } from '@src/constants';
import {
  convertColorRgba,
  convertRgbStringToObject,
  createLighterColor,
  insertClassName,
  getTimeDate,
  dragScroll,
  tableFixedBar,
} from '@src/utils';
import { Table, TableCell, TableRow, NothingFound } from '@components/shared';
import { useViewport } from '@src/hooks';

export const DailyTable = ({ data, onCardClick }) => {
  const tableRef = useRef(null);
  const { isMobile } = useViewport();

  useEffect(() => {
    if (tableHasEvents) {
      const newDragScroll = dragScroll(tableRef.current.children[0]);
      const newFixedBar = tableFixedBar(tableRef.current, {
        hasLeftBar: true,
        hasSubtitle: false,
      });

      newFixedBar.init();
      newDragScroll.init();

      return () => {
        newDragScroll.destroy();
        newFixedBar.destroy();
      };
    }
  }, [tableRef]);

  const tableArray = data
    ? getDayTableArray(data, getDailyTimeRangeArray(0))
    : [];

  const getStudioCellContent = ({ studio, location }) => {
    return (
      <>
        <span className="schedule-table__header-title">{studio.name}</span>
        <span className="schedule-table__header-subtitle">{location.name}</span>
      </>
    );
  };

  const getClassCellContent = (cell, cardColor) => {
    const { start_time, end_time } = cell.event;

    const startTime = getTimeDate(start_time, splitSign).getTime();
    let endTime = getTimeDate(end_time, splitSign).getTime();

    const eventEndAfterMidnight = start_time > end_time;

    if (eventEndAfterMidnight) {
      endTime = getTimeDate('24:15', splitSign).getTime();
    }

    const row = Math.round((endTime - startTime) / 1000 / 60 / 15);

    return (
      <DailyScheduleCard
        event={cell.event}
        rowspan={row}
        cardOnClickHandler={onCardClick}
        colorCellBackground={cardColor}
        eventEndAfterMidnight={eventEndAfterMidnight}
      />
    );
  };

  const getTimeCellContent = (timeString) => {
    const time = timeString.split(splitSign);

    const formattedTime = format(
      getTimeDate(timeString, splitSign),
      timeFormatView
    ).split(' ');

    const renderTime = formattedTime[0];
    const renderTimeFormat = formattedTime[1];

    const isEven = time[1] === '00';

    return (
      <span
        className={`schedule-table__time${insertClassName(
          isEven,
          'schedule-table__time--even'
        )}`}
      >
        {renderTime}{' '}
        <span className="schedule-table__time-format">{renderTimeFormat}</span>
      </span>
    );
  };

  const getCellElement = (cell, isTitle, index) => {
    const colorTitleBackground = cell.color
      ? convertColorRgba(cell.color, 1)
      : null;
    const colorCellBackground = cell.color
      ? createLighterColor(convertRgbStringToObject(cell.color))
      : null;

    let cellContent = '';
    const isRootCell = cell === tableRoot;

    if (isRootCell) {
      cellContent = <DailyRootCell />;
    }

    if (cell.studio) {
      cellContent = getStudioCellContent(cell);
    }

    if (cell.event) {
      cellContent = getClassCellContent(cell, colorCellBackground);
    }

    if (cell && !isRootCell && typeof cell === 'string') {
      cellContent = getTimeCellContent(cell);
    }

    return (
      <TableCell
        key={index}
        style={{
          backgroundColor: isTitle ? colorTitleBackground : colorCellBackground,
        }}
      >
        {cellContent}
      </TableCell>
    );
  };

  const getRowElement = (row, i) => {
    const isTitleRow = i === 0;

    return (
      <TableRow title={isTitleRow} key={i}>
        {row.map((cell, index) => getCellElement(cell, isTitleRow, index))}
      </TableRow>
    );
  };

  const tableHasEvents = tableArray.some((row) =>
    row.some((cell) => cell.event)
  );

  const getTableElement = (tableData) => tableData.map(getRowElement);

  const scrollToFirstEvent = () => {
    const firstCard = document.querySelector('.schedule-card__container');
    const table = document.querySelector('.table__container');
    if (firstCard) {
      const coorditates = firstCard.getBoundingClientRect();
      window.scrollTo({
        top: coorditates.top - 200,
        behavior: 'smooth',
      });
      table.scrollTo({
        left: coorditates.left - (!isMobile ? 550 : 130),
        behavior: 'smooth',
      });

    }
  };

  useLayoutEffect(() => {
    const scrollTimeout = setTimeout(() => {
      scrollToFirstEvent();
    }, 500);
    return () => clearTimeout(scrollTimeout);
  }, []);

  return (
    <>
      {tableHasEvents ? (
        <Table
          tableRef={tableRef}
          className="schedule-table schedule-table--day"
        >
          {getTableElement(tableArray)}
        </Table>
      ) : (
        <NothingFound />
      )}
    </>
  );
};