import React, { useEffect, useCallback, useRef } from 'react';
import { Button, Icon } from '../index';
import { useViewport } from '@src/hooks';
import { useHistory } from 'react-router-dom';

export const Modal = ({
  show,
  headerContent,
  changeState,
  children,
  className,
  nativeClose,
  canGoBack,
}) => {
  const { isMobile } = useViewport();
  const closeModal = (e, isCloseAllModals) => {
    if (!nativeClose) return false;
    e.preventDefault();
    e.stopPropagation();

    changeState(isCloseAllModals);
  };

  const closeOnEscButton = useCallback((e) => {
    if (e.keyCode === 27) {
      closeModal(e, true);
    }
  }, []);

  const history = useHistory();

  useEffect(() => {
    document.addEventListener('keydown', closeOnEscButton, false);

    return () => {
      document.removeEventListener('keydown', closeOnEscButton, false);
    };
  }, []);

  const modalWindow = useRef(null);

  useEffect(() => {
    const modalStorageItem = localStorage.getItem('modalStorageItem');
    const modalOffsetTop = modalStorageItem &&  modalStorageItem.split(', ')[0];
    const modalWindowInfo = modalStorageItem && document.querySelector(`${modalStorageItem.split(', ')[1]}`);

    if(isMobile) {
      if (modalStorageItem) {
        modalWindow.current && modalWindowInfo.scrollTo({
          top: modalOffsetTop ? modalOffsetTop : 0,
          behavior: 'smooth',
        })
      } else {
        modalWindow.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    } else {
        modalWindow.current?.scrollTo({
        top: modalOffsetTop ? modalOffsetTop : 0,
        behavior: 'smooth',
      })
    }
  }, [children]);

  if (!show) return null;

  return (
    <div className={`modal${className ? ` ${className}` : ''}`}>
      <div className="modal__overlay" onClick={(e) => closeModal(e, true)} />
      <div className="modal__window" ref={modalWindow}>
        {nativeClose ? (
          <Button
            className="btn btn--navigation modal__close-btn"
            onClick={(e) => closeModal(e, true)}
          >
            {isMobile ? <Icon type="close-cross" /> : 'Close'}
          </Button>
        ) : null}
        {canGoBack && nativeClose && (
          <Button
            type="navigation"
            iconLeft={<Icon type="chevron" />}
            className="modal__go-back"
            onClick={(e) => history.goBack()}
          >
            Back
          </Button>
        )}
        <div className="modal__header">{headerContent}</div>
        <div className="modal__body">{children}</div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  nativeClose: true,
  onClose: () => {},
};