import { queryParam } from '@src/utils';

export const PROGRAMS_FILTERS_URL = `/filter/programs`;
export const COURSES_URL = (
  { tab, sortingBy, sortingOrder },
  search,
  page
) =>
  `/courses${queryParam.generateQuery([
    {
      label: 'tab',
      value: tab !== 'media' && tab,
    },
    {
      label: 'has_videos',
      value: tab === 'media' && 1,
    },
    {
      label: 'search',
      value: search,
    },
    {
      label: 'sort_by',
      value: sortingBy,
    },
    {
      label: 'sort',
      value: sortingOrder,
    },
    {
      label: 'page',
      value: page,
    },
  ])}`;
