import React from 'react';
import { Button, Icon, CourseBadges } from '@components/shared';
import { getOptions, getAgeRange } from '@src/utils';

export const SessionsCourseCard = ({ data, onLearnMoreClick }) => {
  const {
    participant_age_from,
    participant_age_to,
    participant_age_types = [],
    participant_levels = [],
    title,
    label,
    id,
  } = data;

  const ageRange = getAgeRange(
    participant_age_from,
    participant_age_to,
    participant_age_types
  );

  const levels = getOptions(participant_levels);

  const ageTypes = getOptions(participant_age_types);

  return (
    <div className="sessions-course-card">
      <div className="sessions-course-card__body">
        <div className="offering-title-block__middle">
          {<span className="offering-title-block__id">{label}</span>}
          {<CourseBadges course={data} />}
        </div>

        <h3 className="offering-title-block__title">{title}</h3>

        {levels && (
          <div className="sessions-course-card__info-block">
            <span className="sessions-course-card__label">
              Participant Level(s)
            </span>
            <span className="sessions-course-card__value">{levels}</span>
          </div>
        )}

        {ageTypes && (
          <div className="sessions-course-card__info-block">
            <span className="sessions-course-card__label">Age Type(s)</span>
            <span className="sessions-course-card__value">{ageTypes}</span>
          </div>
        )}

        {ageRange && (
          <div className="sessions-course-card__info-block">
            <span className="sessions-course-card__label">Age Range</span>
            <span className="sessions-course-card__value">{ageRange}</span>
          </div>
        )}

        <Button
          className="sessions__item-button--learn-more-btn"
          iconRight={<Icon type="proceed-next" />}
          onClick={() => onLearnMoreClick(id)}
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

SessionsCourseCard.defaultProps = {
  grouped: false,
};