import React, { useEffect, useState } from 'react';
import { updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect, useApi } from '@src/hooks';
import { getProfileSchema } from '@src/utils';
import { countriesWithStates } from '@src/constants';
import { normalizeUserData, normalizeUserDataErrors } from '@src/normalizers';
import {
  Button,
  Select,
  Input,
  Checkbox,
  Tooltip,
  Link
} from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const SnailSubcription = ({ 
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const [showStates, setShowStates] = useState(false);
  const countries = useApi({ url: '/countries', dataPlaceholder: [] });
  
  const isSnailSubcription = editorState === 'snailMailSubscription';

  const { contact, student } = userData.data || {};
  const { address } = contact || {};
  const { country, address_1, state, city, zip } = address || {};

  const {
    receive_snail_mail,
  } = student || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState);
    dispatch(updateUserData(userData, handleRequest, handleServerErrors));
  };

  const handleRequest = () => {
    setEditorState('');
  };

  const handleServerErrors = (errors) => {
    const { data } = errors;

    if (data?.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  useEffect(() => {
    if (editorState === 'snailMailSubscription') {
      setCustomValue('snailSubscription', true);
    }
  }, [editorState]);

  const initialValues = {
    address: address_1,
    country: country?.id,
    city: city,
    state: state?.id,
    zip: zip,
    snailSubscription: receive_snail_mail,
  };

  const schema = getProfileSchema(showStates, editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setCustomValue,
    setErrors,
    clearValues,
    setValues,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });
  
  const states = useApi(
    {
      url: `/countries/${values.country}/states`,
      dataPlaceholder: [],
    },
    [values.country],
    !!values.country
  );

  useEffect(() => {
    if (countries.data.length && values.country) {
      checkStates(values.country, countries.data);
    }
  }, [values.country, countries.data.length]);

  const checkStates = (countryId, countries) => {
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );
    const countryWithStates = countriesWithStates.includes(
      selectedCountry?.name
    );
    if (!countryWithStates) {
      setCustomValue('state', '');
    }
    setShowStates(countryWithStates);
  };

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href="mailto:dan.yonah.marshalll@gmail.com">
        dan.yonah.marshall@gmail.com
      </a>
      <span>or call (617) 642-1444.</span>
    </Tooltip>
  );

  const renderSubcriptionSuggestion = (isSubscriber, blockName) => {
    if (!editorState) {
      return isSubscriber ? (
        <span className="account-profile__subcription-note">
          If you want to unsubscribe please{' '}
          <span className="account-profile__subcription-support">
            <Link href='https://cccaonline.org/contact-us/#contacts'>
              contact Support
            </Link>
            
            {renderSupportTooltip()}
          </span>
        </span>
      ) : (
        <span className="account-profile__subcription-note">
          If you want to subscribe please{' '}
          <Button onClick={() => setEditorState(blockName)} type="link">
            click here
          </Button>
        </span>
      );
    }
  };

  const snailSubscriptionBlockData = [
    {
      label: 'Address',
      inputType: Input,
      inputName: 'address',
      placeholder: 'Enter your Adress',
    },

    {
      label: 'Country',
      inputType: Select,
      inputName: 'country',
      placeholder: 'Select Country',
      additionalProps: {
        options: countries.data,
        onChange: (val) => setCustomValue('country', val.id),
        optionsLabel: 'name',
        optionsValue: 'id',
        optionsKey: 'id',
        searchable: true,
        enableClear: true,
      },
    },
    {
      label: 'City',
      inputType: Input,
      inputName: 'city',
      placeholder: 'Enter your City',
    },
    {
      label: 'State',
      inputType: Select,
      inputName: 'state',
      placeholder:
        (editorState !== 'snailMailAdress' && !values.state) ||
        !showStates ||
        !values.country
          ? '–'
          : 'Select State',
      additionalProps: {
        options: states.data,
        onChange: (val) => setCustomValue('state', val),
        optionsLabel: 'name',
        optionsValue: 'id',
        optionsKey: 'id',
        disabled:
          (editorState !== 'snailMailAdress' &&
            editorState !== 'snailMailSubscription') ||
          !showStates ||
          !values.country,
        searchable: true,
        enableClear: true,
      },
    },
    {
      label: 'Zip Code',
      inputType: Input,
      inputName: 'zip',
      placeholder: 'Enter your Zip Code',
    },
    {
      label: 'I want to receive BCCA/CCCA snail (standard post) mail',
      inputType: Checkbox,
      inputName: 'snailSubscription',
      hide: !isSnailSubcription,
      additionalProps: {
        disabled: true,
      },
    },
  ];

  const profileBlocksData = [
    {
      title: 'BCCA/CCCA Snail Mail',
      data: snailSubscriptionBlockData,
      infoText: receive_snail_mail
        ? 'You’re already subscribed to our Snail Mail.'
        : 'You’re not subscribed to our Snail Mail.',
      subscription: renderSubcriptionSuggestion(
        receive_snail_mail,
        'snailMailSubscription'
      ),
      name: isSnailSubcription ? 'snailMailSubscription' : 'snailMailAdress',
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
  </>
  );
};