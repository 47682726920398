import { queryParam } from '@src/utils';

export const setFiltersQuery = (filters) => {
  const queriesToAdd = {};
  const queriesToRemove = [];
  Object.entries(filters).forEach(([filterName, filterValue]) => {
    if (filterName === 'letter' && filterValue === 'all') {
      queriesToRemove.push('letter');
      return;
    }
    if (filterName === 'location' && !filterValue) {
      return (queriesToAdd.location = 0);
    }
    if (Array.isArray(filterValue) && !filterValue.length) {
      return queriesToRemove.push(filterName)
    }
    queriesToRemove.push(filterName);
    return filterValue ? (queriesToAdd[filterName] = filterValue) : '';
  });
  queryParam.delete(queriesToRemove);
  queryParam.setEntries(queriesToAdd);
};
