import React from 'react';
import { useDidUpdate } from '@src/hooks';
import { format } from 'date-fns';
import {
  dateSessionsFormat,
  dropInSelectedFormat,
  twelveHoursFormat,
} from '@src/constants';
import {
  EventsCalendar,
  Loader,
  Icon,
  Checkbox,
  ErrorMessage,
} from '@components/shared';
import { zonedDate, getDateTimes } from '@src/utils';
import _ from 'lodash';

export const SingleDateSchedulePicker = ({
  slots,
  errors,
  daysData,
  setDaysData,
  selectedSchedules,
  contentType,
  setCustomValue,
  selectedDays,
  scheduleErrors,
}) => {
  useDidUpdate(() => {
    if (selectedDays.length) {
      const daysToRender = compileData();
      setSchedulesIfNoTimeOptions(daysToRender);
      setDaysData(daysToRender);
    } else {
      setCustomValue('selectedSchedules', []);
    }
  }, [selectedDays]);

  const onDayClick = (date, modifiers) => {
    const { selected } = modifiers;
    let newSelected = selectedDays.slice();

    if (selected) {
      const filteredSelected = newSelected.filter(
        (oldDate) => oldDate.toDateString() !== date.toDateString()
      );

      return setCustomValue('selectedDays', filteredSelected);
    }

    newSelected.push(date);

    setCustomValue('selectedDays', newSelected);
  };

  const compileData = () => {
    const formattedSelectedDays = selectedDays.map((day) =>
      format(day, dateSessionsFormat)
    );

    let daysToRender = [];

    slots.data &&
      slots.data.forEach((slot) => {
        const { date } = slot;

        if (
          formattedSelectedDays.includes(
            format(zonedDate(date), dateSessionsFormat)
          )
        ) {
          daysToRender.push(slot);
        }
      });

    const daysToRenderWithTimeOptions = daysToRender.map((day) => ({
      ...day,
      timeOptions: getTimeOptions(day.time),
    }));

    return daysToRenderWithTimeOptions;
  };

  const setSchedulesIfNoTimeOptions = (days) => {
    const newSchedules = [];
    const datesWithOptions = [];

    days.forEach((day) => {
      if (day.timeOptions.length === 1) {
        const schedule = {
          date: day.date,
          value: day.timeOptions[0].value,
        };
        newSchedules.push(schedule);
      } else {
        datesWithOptions.push(day.date);
      }
    });

    setCustomValue('selectedSchedules', [
      ...selectedSchedules.filter(({ date }) =>
        datesWithOptions.includes(date)
      ),
      ...newSchedules,
    ]);
  };

  const getTimeOptions = (time) => {
    return time.map(({ start, end, schedule_id }) => {
      const { startTime, endTime } = getDateTimes(start, end);
      const formattedStart = format(startTime, twelveHoursFormat).toLowerCase();
      const formattedEnd = format(endTime, twelveHoursFormat).toLowerCase();
      return {
        label: `${formattedStart} - ${formattedEnd}`,
        value: { schedule_id, start },
      };
    });
  };

  const onDayCheckboxChange = (e, date, value) => {
    const isChecked = e.target.checked;
    const newValue = {
      date,
      value,
    };

    isChecked
      ? setCustomValue('selectedSchedules', [...selectedSchedules, newValue])
      : setCustomValue(
          'selectedSchedules',
          selectedSchedules.filter((item) => !_.isEqual(item, newValue))
        );
  };

  const renderSelectedDays = (daysData) => {
    return (
      <div className="list list--drop-in-days">
        {daysData.map(({ date, timeOptions }) => {
          const formattedDate = format(zonedDate(date), dropInSelectedFormat);
          return (
            <div className="list__item" key={formattedDate + date}>
              <div className="text text--drop-in-date">{formattedDate}</div>
              <div className="text text--drop-in-time">
                {timeOptions.map((option) => {
                  const { value, label } = option;
                  return (
                    <Checkbox
                      key={value.id + label}
                      name={date + value.start}
                      onChange={(e) => onDayCheckboxChange(e, date, value)}
                      value={selectedSchedules.some((schedule) =>
                        _.isEqual(schedule, { date, value })
                      )}
                      label={label + ' EST'}
                      disabled={timeOptions.length === 1}
                    />
                  );
                })}
                <ErrorMessage
                  show={scheduleErrors.includes(date)}
                  text={'Select a Timeslot to proceed'}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="registration-step__group registration-step__group--calendar">
        <Loader show={slots.loading} />
        {slots.data && !!slots.data.length && (
          <EventsCalendar
            onDayClick={onDayClick}
            selectedDays={selectedDays}
            slots={slots.data}
          />
        )}
        <ErrorMessage
          show={!!errors.selectedDays}
          text={'Select a Date to proceed'}
        />
      </div>

      {!!selectedDays.length && (
        <div className="registration-step__group registration-step__group--schedule">
          {renderSelectedDays(daysData)}
          <div className="registration-step__schedule-note">
            <Icon type="info" size="xxl" />
            <div>
              Please check the schedule in your user account before attending
              the {contentType.plural_short_name}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
