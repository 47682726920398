import React, { useState, useEffect } from 'react';
import { apiRequest} from '@src/utils';
import { Button, Icon, closeModal, Loader } from '@components/shared';

export const ExpiredLinkNotification = ({ linkType, email, redirect_to }) => {
  const [resendTimer, setResendTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onResendClick = () => {
    setResendTimer(60);
    switch (linkType) {
      case 'email':
        return resendVerificationEmail();
      case 'password':
        return resendPasswordRecovery();
      default:
        return () => {};
    }
  };

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    await apiRequest({
      url: '/auth/email/verify/resend',
    });
    setIsLoading(false);
  };

  const resendPasswordRecovery = async () => {
    setIsLoading(true);
    const data = {
      email,
      redirect_to,
    };
    await apiRequest({
      url: '/auth/password/email',
      method: 'POST',
      data: JSON.stringify(data),
    });

    setIsLoading(false);
  };

  useEffect(() => {
    let timer = null;

    if (resendTimer) {
      timer = setTimeout(() => {
        setResendTimer((value) => value - 1);
      }, 1000);
    }

    if (resendTimer === 0) {
      setResendTimer(null);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  return (
    <div className="login">
      <Loader sticky show={isLoading}/>
      <div className="registration-step registration-step--email-verification">
        <div className="registration-step__container">
          <Icon type="link-expired" className="login__icon" />
          <h1 className="login__title">Ooops...</h1>
          <div className="registration-step__text">
            Unfortunately, you can't procced since your link has been expired.
          </div>
          <div className="form__group form__group--login-buttons">
            <Button
              type="primary"
              onClick={() => closeModal('expired-link-notification')}
            >
              Got it!
            </Button>
          </div>
          <div className="form__group form__group--resend">
            <div className="registration-step__text">
              {linkType === 'password' && 'Still want to reset your password?'}
              {linkType === 'email' && 'Still want to confirm your email?'}
            </div>
            <Button
              disabled={!!resendTimer}
              type="transparent"
              onClick={onResendClick}
            >
              Resend Email {resendTimer && <>{resendTimer}s </>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};