import * as types from '@types/scheduleTypes';
import { getAction, onError, onLoading, onSuccess } from '../actionCreators';
import { apiRequest, requestReduxHandler } from '@src/utils';
import { SCHEDULE_DAY_URL, SCHEDULE_WEEK_VIEW_URL } from '@src/endpoints';

export const setLocationFilter = locationFilter =>
  getAction(types.FILTER_SELECT_LOCATION, locationFilter.id);
export const setCategoryFilter = categoryFilter =>
  getAction(types.FILTER_SELECT_CATEGORIES, categoryFilter.id);
export const setInstructorFilter = instructorFilter =>
  getAction(types.FILTER_SELECT_INSTRUCTORS, instructorFilter.id);
export const setLetterFilter = letterFilter =>
  getAction(types.ALPHABET_FILTER_LETTER, letterFilter);
export const setProgramFilter = programFilter =>
  getAction(types.FILTER_SELECT_PROGRAM, programFilter.id);

export const clearFilters = () => getAction(types.FILTER_SELECT_CLEAR);

export const setScheduleActiveTab = activeTab => getAction(types.ACTIVE_TAB, activeTab);

export const setDayDate = date => getAction(types.DAY_DATE, date);

export const setWeekDate = week => getAction(types.WEEK_DATE, week);

export const getFilters = () => () => {
  apiRequest({
    url: '/filter/schedule',
    reduxHandlers: requestReduxHandler(
      res => onSuccess(types.FILTERS, res.data),
      () => onLoading(types.FILTERS_LOADING),
      err => onError(types.FILTERS_ERROR, err)
    ),
  });
};

export const getDayTable = (day, filters) => () => {
  apiRequest({
    url: SCHEDULE_DAY_URL(day, filters),
    reduxHandlers: requestReduxHandler(
      res => onSuccess(types.DAY_TABLE, res.data),
      () => onLoading(types.DAY_TABLE_LOADING),
      err => onError(types.DAY_TABLE_ERROR, err)
    ),
  });
};

export const getWeekTable = (week, filters) => () => {
  apiRequest({
    url: SCHEDULE_WEEK_VIEW_URL('weekly', week, filters),
    reduxHandlers: requestReduxHandler(
      res => onSuccess(types.WEEK_TABLE, res.data),
      () => onLoading(types.WEEK_TABLE_LOADING),
      err => onError(types.WEEK_TABLE_ERROR, err)
    ),
  });
};

export const getCategoriesTable = (week, filters) => () => {
  apiRequest({
    url: SCHEDULE_WEEK_VIEW_URL('categories', week, filters),
    reduxHandlers: requestReduxHandler(
      res => onSuccess(types.CATEGORIES_TABLE, res.data),
      () => onLoading(types.CATEGORIES_TABLE_LOADING),
      err => onError(types.CATEGORIES_TABLE_ERROR, err)
    ),
  });
};

export const getInstructorsTable = (week, filters, alphabetFilter) => () => {
  apiRequest({
    url: SCHEDULE_WEEK_VIEW_URL('instructors', week, filters, alphabetFilter),
    reduxHandlers: requestReduxHandler(
      res => onSuccess(types.INSTRUCTORS_TABLE, res.data),
      () => onLoading(types.INSTRUCTORS_TABLE_LOADING),
      err => onError(types.INSTRUCTORS_TABLE_ERROR, err)
    ),
  });
};

export const getProgramsTable = (week, filters) => () => {
  apiRequest({
    url: SCHEDULE_WEEK_VIEW_URL('programs', week, filters),
    reduxHandlers: requestReduxHandler(
      res => onSuccess(types.PROGRAMS_TABLE, res.data),
      () => onLoading(types.PROGRAMS_TABLE_LOADING),
      err => onError(types.PROGRAMS_TABLE_ERROR, err)
    ),
  });
};
