import React from 'react';
import Details from '@components/shared/Details/Details';
import { classNames, getImageUrl } from '@src/utils';
import JWPlayer from 'react-jwplayer';
import Vimeo from '@u-wave/react-vimeo';
import { Icon } from '@components/shared';
import { useViewport } from '@src/hooks'

const VideoCard = ({ video, isWatchAllowed, isExpanded, setExpandedVideo, isVideoGalleryCard }) => {
  const { 
    title, 
    description, 
    poster_image, 
    embed_id, 
    type, 
    id,
    // location 
  } = video;
  const { isMobile } = useViewport();

  return (
    <div
      className={classNames([
        'video__card',
        isExpanded && isWatchAllowed && 'video__card--expanded',
      ])}
    >
      <div
        className={`video__preview video__preview--${type}`}
        onClick={() => setExpandedVideo(isExpanded ? null : id)}
      >
        {isExpanded && isWatchAllowed ? (
          <>
            {type === 'vimeo' ? (
              <Vimeo video={embed_id} />
            ) : (
              <JWPlayer videoId={embed_id} />
            )}
          </>
        ) : (
          <div className="video__preview-image">
            {poster_image?.path ? (
              <img src={getImageUrl(poster_image?.path)} alt="video poster" />
            ) : (
              <Icon type="party-dance" size="full" />
            )}
          </div>
        )}
      </div>
      <div className="video__description">
        <h3 className="video__title">{title}</h3>
        {/* <h4 className="video__subtitle">{subtitle}</h4> */}
        <Details
          className="video__text"
          onCollapse={() => setExpandedVideo(isExpanded ? null : id)}
          isCollapse={isExpanded}
          isVideoGalleryCard = {isVideoGalleryCard}
        >
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </Details>

        {/* <div className="video__logo">
          <a href={location.link} target="_blank">
            <img
              src={getImageUrl(location.logo?.path)}
              alt="Logo"
              height={isMobile ? '20' : '30'}
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default VideoCard;
