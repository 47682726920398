import React from 'react';
import { ProgramsCourseCard } from '@components/features/Programs/ProgramsCourseCard';

export const CoursesTitles = ({ courses, className, isProviderAbout }) => {
  return (
    <>
      {courses.data.map(course => (
        <ProgramsCourseCard 
          className={className} 
          course={course} 
          key={course.id} 
          isProviderAbout={isProviderAbout}
        />
      ))}
    </>
  );
};