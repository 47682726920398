import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getActiveTab, classNames } from '@src/utils';

export const Tabs = (props) => {
  const {
    className,
    tabsData,
    onTabClick,
    label,
    isShadowVisible,
    isDisabled,
    isActiveTabClickable,
  } = props;

  const tabsRef = useRef(null);
  const shadowRef = useRef(null);

  const onClick = (tabObject, e) => {
    e.preventDefault();
    const isTabActive = tabObject?.value === getActiveTab(tabsData)?.value;

    if (isTabActive && !isActiveTabClickable) return false;

    positionTabShadow(e.currentTarget);

    onTabClick(tabObject, e);
  };

  const positionTabShadow = (destinationElement) => {
    const shadow = shadowRef.current;

    if (!shadow || !destinationElement || !isShadowVisible) return false;

    const shadowWidth = shadow.offsetWidth;
    const destElementWidth = destinationElement.offsetWidth;
    const shadowScale = parseFloat(destElementWidth) / parseFloat(shadowWidth);

    shadow.style.transform = `translateX(${destinationElement.offsetLeft}px) scaleX(${shadowScale})`;
    shadow.style.height = destinationElement.offsetHeight + 'px';
  };

  const onResize = () => {
    positionTabShadow(
      tabsRef.current.getElementsByClassName('tabs__item--active')[0]
    );
  };

  useEffect(() => {
    const activeTab =
      tabsRef.current.getElementsByClassName('tabs__item--active')[0];

    positionTabShadow(activeTab);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [tabsData]);

  return (
    <div className="tabs__container">
      {label && <span className="tabs__label">{label}</span>}
      <div ref={tabsRef} className={classNames(['tabs', className])}>
        <div ref={shadowRef} className="tabs__item-shadow" />
        {tabsData.map((tab) => (
          <button
            type="btn"
            onClick={(e) => onClick(tab, e)}
            className={classNames([
              'tabs__item',
              tab.active && 'tabs__item--active'
            ])}
            key={tab.value}
            disabled={isDisabled}
          >
            {tab.renderer || tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  onTabClick: () => {},
  tabsData: [],
  label: '',
  isShadowVisible: true,
  isDisabled: false,
};

Tabs.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  tabsData: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string,
  isShadowVisible: PropTypes.bool,
  isDisabled: PropTypes.bool,
};