import { openModal, getModal } from '@components/shared';
import { queryParam } from '@src/utils';

export const openModalFromQuery = (query) => {
  const classId = +query.get('classId');
  const instructorId = +query.get('instructorId');
  const regStep = +query.get('regStep');
  const accountTab = query.get('account-tab');
  const loginModal = query.get('loginModal');
  const emailVerificationSuccess = query.get('email-verification');
  const donationModal = query.get('donationModal');
  const redirect = query.get('redirect');

  if (emailVerificationSuccess) {
    openModal(getModal({ name: 'email-verification-success' }));
  }

  if (classId) {
    openModal(getModal({ name: 'class-details', data: { eventId: classId } }));
  }

  if (instructorId) {
    // openModal(
    //   getModal({ name: 'instructor-details', data: { id: instructorId } })
    // );
  }

  if (loginModal) {
    openModal(getModal({ name: 'login' }));
  }

  if (accountTab) {
    // openModal(
    //   getModal({
    //     name: 'student-account',
    //     data: { initTabName: accountTab },
    //   })
    // );
  }


  if (regStep) {
    const classId = +query.get('classId');
    const sessionId = +query.get('sessionId');
    const regType = query.get('regType');

    openModal(
      getModal({
        name: 'registration',
        data: {
          classId,
          sessionId,
          initialStep: regStep,
          initialType: regType,
        },
      })
    );
  }

  if (donationModal) {
    openModal(
      getModal({
        name: 'donation',
      })
    );
  }

  if (redirect) {
    // openModal(
    //   getModal({
    //     name: 'student-account',
    //   })
    // );
    // queryParam.delete('redirect');
  }

};