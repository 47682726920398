export const requestFunction = options =>
  new Promise(async (resolve, reject) => {
    const { url, ...requestOptions } = options;

    try {
      let response = await fetch(url, requestOptions);
      let json = await response.json();
      if (response.ok) {
        resolve(json);
      } else {
        reject(json);
      }
    } catch (e) {
      reject(e.message);
    }
  });
