export const REG_SLOTS = `REG_SLOTS`;
export const REG_SLOTS_LOADING = `REG_SLOTS_LOADING`;
export const REG_SLOTS_ERROR = `REG_SLOTS_ERROR`;

export const REG_PRICE = `REG_PRICE`;
export const REG_PRICE_LOADING = `REG_PRICE_LOADING`;
export const REG_PRICE_ERROR = `REG_PRICE_ERROR`;

export const BLOCK_SELECTED_SLOTS = `BLOCK_SELECTED_SLOTS`;
export const BLOCK_SELECTED_SLOTS_LOADING = `BLOCK_SELECTED_SLOTS_LOADING`;
export const BLOCK_SELECTED_SLOTS_ERROR = `BLOCK_SELECTED_SLOTS_ERROR`;

export const BLOCK_START_DATES = `BLOCK_START_DATES`;
export const BLOCK_START_DATES_LOADING = `BLOCK_START_DATES_LOADING`;
export const BLOCK_START_DATES_ERROR = `BLOCK_START_DATES_ERROR`;

export const CLEAR_REG_DATA = 'CLEAR_REG_DATA';

export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
