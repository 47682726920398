import { format } from 'date-fns';
import { dateFormatRequest } from '@src/constants';
import { queryParam } from '@src/utils';

export const SCHEDULE_FILTERS_URL = ({
  location,
  instructor,
  category,
  program,
}, letter) =>
  queryParam.generateQuery(
    [
      {
        label: 'instructor',
        value: instructor,
      },
      {
        label: 'location',
        value: location,
      },
      {
        label: 'category',
        value: category,
      },
      {
        label: 'instructor_letter',
        value: letter !== 'all' && letter,
      },
      {
        label: 'program',
        value: program,
      },
    ],
    false
  );

export const SCHEDULE_DAY_URL = (day, filters) =>
  `/schedule/daily?date=${format(day, dateFormatRequest)}${SCHEDULE_FILTERS_URL(
    filters
  )}`;

export const SCHEDULE_WEEK_VIEW_URL = (type, week, filters, alphabetFilter) => {
  const requestWeekRangeUrl = `date[from]=${format(
    week[0],
    dateFormatRequest
  )}&date[to]=${format(week[week.length - 1], dateFormatRequest)}`;

  if (alphabetFilter){
    queryParam.set('letter', alphabetFilter?.letter);
  }

  return `/schedule/${type}?${requestWeekRangeUrl}${SCHEDULE_FILTERS_URL(
    filters, alphabetFilter?.letter
  )}`;
};
