import React, { useRef, useEffect } from 'react';
import { 
  InfoBox, 
  Button, 
  changeModalProps, 
  Link,
  closeModal
} from '@components/shared';
import { useViewport } from '@src/hooks';

export const Policy = ({ setCurrentView, setIsPolicyAccepted, options, isModal, isCurrentRef }) => {
  const { isMobile } = useViewport();

  const infoCollect = useRef();
  const infoUse = useRef();
  const infoShare = useRef();
  const infoRetain = useRef();
  const infoSafe = useRef();
  const privacyRights = useRef();
  const track = useRef();
  const residents = useRef();
  const policyUpdates = useRef();
  const contact = useRef();
  const request = useRef();
  const cookie = useRef(null);

  const onRefClick = (ref) => ref.current.scrollIntoView({block: 'start', behavior: "smooth"});

  const onPolicyAction = (action) => {
    if (options) {
      setIsPolicyAccepted(action);
    }
    setCurrentView('loginForm');
  };

  useEffect(() => {
    changeModalProps('registration', {
      nativeClose: false,
    });

    if (isCurrentRef) {
      const modalBody = document.querySelector('.modal--policy .modal__body');
      const modalBodyPaddingTop = parseInt(window.getComputedStyle(modalBody).getPropertyValue('padding-top'));
      const modalSrollValue = isMobile ? (cookie.current.offsetTop - modalBodyPaddingTop) : (cookie.current.offsetTop);
      const modalWindowClass = '.modal--policy .info-box';
      const storageItem = `${modalSrollValue}, ${modalWindowClass}`

      localStorage.setItem('modalStorageItem', storageItem);
    }
  }, []);

  return (
    <>
      <InfoBox height={!isModal && 600} className="info-box--policy">
        <div className="policy">
          <h1>Terms and Conditions</h1>

          <p>
            The Brookline/Cambridge Community Center for the Arts, Inc. (AKA
            BCCA / CCCA) reserves the right to dismiss any student due to
            misconduct, failure to follow rules or policies, or disruptive
            behavior. All admission fees must be paid on or before the date of
            the class/workshop/ event. There are no make-ups for failure to
            attend pre- or block-registered, or pre-paid Drop In
            classes/workshops/events.
          </p>

          <h2>No Refunds</h2>

          <p>
            I understand that I am liable for the entire fee paid toward the
            chosen enrollment option.
          </p>

          <h2>Photos/Videos/Audio</h2>

          <p>
            I understand that occasional photography, video, and audio, may be 
            taken during classes/workshops/events, and used for archival, marketing, 
            and social media purposes. When used for archival and reselling of digital 
            recordings of classes/workshops/events (to provide residual income for 
            instructors/producers/faculty members, and for other charitable purposes) 
            a specific authorization in the form of explicit permission will be needed. 
            By signing the Media Consent and Waiver, I am giving permission only to be 
            recorded with photography, video, and/or audio during classes/workshops/events. 
            I understand that I may reserve the right not to sign the Media Consent and Waiver 
            or to change my preference at any time, in which case my image, and/or video, 
            and/or audio, will no longer be taken from the date I choose to decline 
            the Media and Consent Waiver.
          </p>

          <h2>Release of liability in case of injury</h2>

          <p>
            In consideration of your acceptance of my enrollment, I do hereby
            for my self, my heirs, executors, and administrators, release and
            discharge the Brookline/Cambridge Community Center for the Arts,
            Inc., and/or its faculty members, staff, departments, officers,
            agents, representatives, successors, and/or assigns, and against any
            participants, for any and all damages which may be sustained by me
            in connection with, association with, or enrollment in the
            class/workshop/event, or damages which may arise out of traveling
            to, participating in, or returning from said class/workshop/event.
          </p>

          <h1>Privacy Notice</h1>

          <span>Last updated March 2, 2023</span>

          <p>
            Thank you for choosing to be part of our community at
            Brookline/Cambridge Community Center for the Arts, Inc. (BCCA /
            CCCA) ("<strong>Company</strong>", "<strong>we</strong>", "
            <strong>us</strong>", "<strong>our</strong>"). We are committed to
            protecting your personal information and your right to privacy. If
            you have any questions or concerns about this privacy notice, or our
            practices with regards to your personal information, please contact
            us at{' '}
            <Link href="mailto:dan.yonah.marshall@gmail.com" target="">
              dan.yonah.marshall@gmail.com
            </Link>
            .
          </p>

          <p>
            When you visit our website&nbsp;
            <Link
              href="https://www.cccaonline.org"
              rel="noopener noreferrer"
            >
              https://www.cccaonline.org
            </Link>
            &nbsp;(the "<strong>Website</strong>"), and more generally, use any
            of our services (the "<strong>Services</strong>", which include the
            Website), we appreciate that you are trusting us with your personal
            information. We take your privacy very seriously. In this privacy
            notice, we seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you have in
            relation to it. We hope you take some time to read through it
            carefully, as it is important. If there are any terms in this
            privacy notice that you do not agree with, please discontinue use of
            our Services immediately.
          </p>

          <p>
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events.
          </p>

          <p>
            <strong>
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.
            </strong>
          </p>

          <p>
            <strong>TABLE OF CONTENTS</strong>
          </p>

          <ul>
            <li>
              <Button type="navigation" onClick={() => onRefClick(infoCollect)}>
                1. WHAT INFORMATION DO WE COLLECT?
              </Button>
            </li>
            
            <li>
              <Button type="navigation" onClick={() => onRefClick(infoUse)}>
                2. HOW DO WE USE YOUR INFORMATION?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(infoShare)}>
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(infoRetain)}>
                4. HOW LONG DO WE KEEP YOUR INFORMATION?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(infoSafe)}>
                5. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(privacyRights)}>
                6. WHAT ARE YOUR PRIVACY RIGHTS?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(track)}>
                7. CONTROLS FOR DO-NOT-TRACK FEATURES
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(residents)}>
                8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(policyUpdates)}>
                9. DO WE MAKE UPDATES TO THIS NOTICE?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(contact)}>
                10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(request)}>
                11. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </Button>
            </li>

            <li>
              <Button type="navigation" onClick={() => onRefClick(cookie)}>
                12. COOKIE NOTICE & POLICY
              </Button>
            </li>
          </ul>



        {/*----- chapter 1 -----*/}
          <h2 ref={infoCollect}>
            <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
          </h2>

          <p>
            <strong>Personal information you disclose to us</strong>
          </p>

          <p>
            <i>
              <strong>In Short: </strong>
              We collect personal information that you provide to us.
            </i>
          </p>

          <p>
            We collect personal information that you voluntarily provide to us
            when you register on theWebsite, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Website (such as by posting
            messages in our online forums or entering competitions, contests or
            giveaways) or otherwise when you contact us.
          </p>

          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Website, the choices you make and
            the products and features you use. The personal information we
            collect may include the following:
          </p>

          <p>
            <strong>Personal Information Provided by You.</strong> We collect
            names; phone numbers; Email addresses; mailing addresses; usernames;
            passwords; contact preferences; contact or authentication data; and
            other similar information.
          </p>

          <p>
            <strong>Payment Data.</strong> We may collect data necessary to
            process your payment if you make purchases, such as your payment
            instrument number (such as a credit card number), and the security
            code associated with your payment instrument. All payment data is
            stored by PayPal, Inc.. You may find their privacy notice link(s)
            here:{' '}
            <Link
              href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
              rel="noopener noreferrer"
            >
              https://www.paypal.com/us/webapps/mpp/ua/privacy-full
            </Link>
            .
          </p>

          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>
          
          <p>
            <strong>Information automatically collected</strong>
          </p>

          <p>
            <i>
              <strong>In Short: </strong>
              Some information such as your Internet Protocol (IP) address
              and/or browser and device characteristics is collected
              automatically when you visit our Website.
            </i>
          </p>

          <p>
            We automatically collect certain information when you visit, use or
            navigate the Website. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our Website and other technical information.
            This information is primarily needed to maintain the security and
            operation of our Website, and for our internal analytics and
            reporting purposes.
          </p>

          <p>
            The information we collect includes:
          </p>

          <ul>
            <li>
              Log and Usage Data. Log and usage data is service-related,
              diagnostic, usage and performance information our servers
              automatically collect when you access or use our Website and which
              we record in log files. Depending on how you interact with us,
              this log data may include your IP address, device information,
              browser type and settings and information about your activity in
              the Website(such as the date/time stamps associated with your
              usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as
              system activity, error reports (sometimes called 'crash dumps')
              and hardware settings).
            </li>

            <li>
              Device Data. We collect device data such as information about your
              computer, phone, tablet or other device you use to access the
              Website. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model Internet service provider and/or mobile
              carrier, operating system and system configuration information.
            </li>
          </ul>



        {/*----- chapter 2 -----*/}
          <h2 ref={infoUse}>
            <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
          </h2>

          <p>
            <i>
              <strong>In Short: </strong>We process your information for
              purposes based on legitimate business interests, the fulfillment
              of our contract with you, compliance with our legal obligations,
              and/or your consent.
            </i>
          </p>

          <p>
            We use personal information collected via our Website for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>

          <p>
            We use the information we collect or receive:
          </p>

          <ul>
            <li>
              <strong>To facilitate account creation and logon process.</strong>{' '}
              If you choose to link your account with us to a third-party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process for the performance
              of the contract.
            </li>

            <li>
              <strong>To post testimonials.</strong> We post testimonials on our
              Website that may contain personal information. Prior to posting a
              testimonial, we will obtain your consent to use your name and the
              content of the testimonial. If you wish to update, or delete your
              testimonial, please contact us at{' '}
              <Link href="mailto:dan.yonah.marshall@gmail.com" target="">
                dan.yonah.marshall@gmail.com
              </Link>{' '}
              and be sure to include your name, testimonial location, and
              contact information.
            </li>

            <li>
              <strong>Request feedback.</strong> We may use your information to
              request feedback and to contact you about your use of our Website.
            </li>

            <li>
              <strong>To enable user-to-user communications.</strong> We may use
              your information in order to enable user-to-user communications
              with each user's consent.
            </li>

            <li>
              <strong>To manage user accounts.</strong> We may use your
              information for the purposes of managing our account and keeping
              it in working order.
            </li>

            <li>
              <strong>To send administrative information to you.</strong> We may
              use your personal information to send you product, service and new
              feature information and/or information about changes to our terms,
              conditions, and policies.
            </li>

            <li>
              <strong>To protect our Services.</strong> We may use your
              information as part of our efforts to keep our Website safe and
              secure (for example, for fraud monitoring and prevention).
            </li>

            <li>
              <strong>
                To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </strong>
            </li>

            <li>
              <strong>To respond to legal requests and prevent harm.</strong> If
              we receive a subpoena or other legal request, we may need to
              inspect the data we hold to determine how to respond.
            </li>

            <li>
              <strong>Fulfill and manage your orders.</strong> We may use your
              information to fulfill and manage your orders, payments, returns,
              and exchanges made through the Website.
            </li>

            <li>
              <strong>Administer prize draws and competitions.</strong> We may
              use your information to administer prize draws and competitions
              when you elect to participate in our competitions.
            </li>

            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>{' '}
              We may use your information to provide you with the requested
              service.
            </li>

            <li>
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>{' '}
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </li>

            <li>
              <strong>
                To send you marketing and promotional communications.
              </strong>{' '}
              We and/or our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing Emails at any time (see the "
              <Button
                type="navigation"
                onClick={() => onRefClick(privacyRights)}
              >
                WHAT ARE YOUR PRIVACY RIGHTS
              </Button>
              " below).
            </li>

            <li>
              <strong>Deliver targeted advertising to you.</strong>We may use
              your information to develop and display personalized content and
              advertising (and work with third parties who do so) tailored to
              your interests and/or location and to measure its effectiveness.
            </li>

            <li>
              <strong>For other business purposes.</strong>We may use your
              information for other business purposes, such as data analysis,
              identifying usage trends, determining the effectiveness of our
              promotional campaigns and to evaluate and improve our Website,
              products, marketing and your experience. We may use and store this
              information in aggregated and anonymized form so that it is not
              associated with individual end users and does not include personal
              information. We will not use identifiable personal information
              without your consent.
            </li>
          </ul>



        {/*----- chapter 3 -----*/}
          <h2 ref={infoShare}>
            <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
          </h2>

          <p>
            <i>
              <strong>In Short:</strong> We only share information with your
              consent, to comply with laws, to provide you with services, to
              protect your rights, or to fulfill business obligations.
            </i>
          </p>

          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>

          <ul>
            <li>
              <strong>Consent:</strong> We may process your data if you have
              given us specific consent to use your personal information for a
              specific purpose.
            </li>

            <li>
              <strong>Legitimate Interests:</strong> We may process your data
              when it is reasonably necessary to achieve our legitimate business
              interests.
            </li>

            <li>
              <strong>Performance of a Contract:</strong> Where we have entered
              into a contract with you, we may process your personal information
              to fulfill the terms of our contract.
            </li>

            <li>
              <strong>Legal Obligations:</strong> We may disclose your
              information where we are legally required to do so in order to
              comply with applicable law, governmental requests, a judicial
              proceeding, court order, or legal process, such as in response to
              a court order or a subpoena (including in response to public
              authorities to meet national security or law enforcement
              requirements).
            </li>

            <li>
              <strong>Vital Interests:</strong> We may disclose your information
              where we believe it is necessary to investigate, prevent, or take
              action regarding potential violations of our policies, suspected
              fraud, situations involving potential threats to the safety of any
              person and illegal activities, or as evidence in litigation in
              which we are involved.
            </li>
          </ul>

          <p>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>

          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>

            <li>
              <strong>Affiliates.</strong> We may share your information with
              our affiliates, in which case we will require those affiliates to
              honor this privacy notice. Affiliates include our parent company
              and any subsidiaries, joint venture partners or other companies
              that we control or that are under common control with us.
            </li>

            <li>
              <strong>Business Partners.</strong>We may share your information
              with our business partners to offer you certain products, services
              or promotions.
            </li>
          </ul>



        {/*----- chapter 4 -----*/}
          <h2 ref={infoRetain}>
            <strong>4. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
          </h2>

          <p>
            <i>
              <strong>In Short: </strong>We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy notice
              unless otherwise required by law.
            </i>
          </p>

          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>

          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>



        {/*----- chapter 5 -----*/}
          <h2 ref={infoSafe}>
            <strong>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
          </h2>

          <p>
            <i>
              <strong>In Short:</strong> We aim to protect your personal
              information through a system of organizational and technical
              security measures.
            </i>
          </p>

          <p>
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from our Website is at your own risk. You should only access the
            Website within a secure environment.
          </p>



        {/*----- chapter 6 -----*/}
          <h2 ref={privacyRights}>
            <strong>6. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
          </h2>

          <p>
            <i>
              <strong>In Short: </strong>In some regions, such as the European
              Economic Area, you have rights that allow you greater access to
              and control over your personal information. You may review,
              change, or terminate your account at any time.
            </i>
          </p>

          <p>
            In some regions (like the European Economic Area), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. To make such a request, please use the{' '}
            <Button type="navigation" onClick={() => onRefClick(contact)}>
              contact details
            </Button>{' '}
            provided below. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>

          <p>
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness
            of the processing before its withdrawal, nor will it affect the
            processing of your personal information conducted in reliance on
            lawful processing grounds other than consent.
          </p>

          <p>
            If you are a resident in the European Economic Area and you believe
            we are unlawfully processing your personal information, you also
            have the right to complain to your local data protection supervisory
            authority. You can find their contact details here:{' '}
            <Link
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              rel="noopener noreferrer"
            >
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </Link>
            .
          </p>

          <p>
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:{' '}
            <Link
              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              rel="noopener noreferrer"
            >
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </Link>
            .
          </p>

          <p>
            If you have questions or comments about your privacy rights, you may
            Email us at{' '}
            <Link href="mailto:dan.yonah.marshall@gmail.com" target="">
              dan.yonah.marshall@gmail.com
            </Link>
            .
          </p>

          <p>
            <strong>Account Information</strong>
          </p>
          
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>

          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>

            <li>
              Contact us using the contact information provided.
            </li>
          </ul>

          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.
          </p>

          <p>
            <strong>Opting out of Email marketing:</strong> You can unsubscribe
            from our marketing Email list at any time by clicking on the
            unsubscribe link in the Emails that we send or by contacting us
            using the details provided below. You will then be removed from the
            marketing Email list however, we may still communicate with you, for
            example to send you service-related Emails that are necessary for
            the administration and use of your account, to respond to service
            requests, or for other non-marketing purposes. To otherwise opt-out,
            you may:
          </p>

          <ul>
            <li>
              Access your account settings and update your preferences.
            </li>

            <li>
              Contact us using the contact information provided.
            </li>
          </ul>



        {/*----- chapter 7 -----*/}
          <h2 ref={track}>
            <strong>7. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
          </h2>

          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>



        {/*----- chapter 8 -----*/}
          <h2 ref={residents}>
            <strong>
              8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </strong>
          </h2>

          <p>
            <i>
              <strong>In Short:</strong> Yes, if you are a resident of California,
              you are granted specific rights regarding access to your personal
              information.
            </i>
          </p>

          <p>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>

          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Website, you have the right to request
            removal of unwanted data that you publicly post on the Website. To
            request removal of such data, please contact us using the contact
            information provided below, and include the Email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Website,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>

          <p>
            <strong>CCPA Privacy Notice</strong>
          </p>

          <p>
            The California Code of Regulations defines a "resident" as:
          </p>

          <ol>
            <li>
              every individual who is in the State of California for other than
              a temporary or transitory purpose and
            </li>

            <li>
              every individual who is domiciled in the State of California who
              is outside the State of California for a temporary or transitory
              purpose
            </li>
          </ol>

          <p>
            All other individuals are defined as "non-residents."
          </p>

          <p>
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>

          <p>
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>

          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Category</strong>
                </td>
                <td>
                  <strong>Examples</strong>
                </td>
                <td>
                  <strong>Collected</strong>
                </td>
              </tr>

              <tr>
                <td>
                  <p>A. Identifiers</p>
                </td>
                <td>
                  <p>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    Email address and account name
                  </p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </p>
                </td>
                <td>
                  <p>
                    Name, contact information, education, employment, employment
                    history and financial information
                  </p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>
                    C. Protected classification characteristics under California
                    or federal law
                  </p>
                </td>
                <td>
                  <p>Gender and date of birth</p>
                </td>
                <td>
                  <p>YES</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>D. Commercial information</p>
                </td>
                <td>
                  <p>
                    Transaction information, purchase history, financial details
                    and payment information
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>E. Biometric information</p>
                </td>
                <td>
                  <p>Fingerprints and voiceprints</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>F. Internet or other similar network activity</p>
                </td>
                <td>
                  <p>
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems and advertisements
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>G. Geolocation data</p>
                </td>
                <td>
                  <p>Device location</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </p>
                </td>
                <td>
                  <p>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>I. Professional or employment-related information</p>
                </td>
                <td>
                  <p>
                    Business contact details in order to provide you our
                    services at a business level, job title as well as work
                    history and professional qualifications if you apply for a
                    job with us
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>J. Education Information</p>
                </td>
                <td>
                  <p>Student records and directory information</p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
              
              <tr>
                <td>
                  <p>K. Inferences drawn from other personal information</p>
                </td>
                <td>
                  <p>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individuals preferences and
                    characteristics
                  </p>
                </td>
                <td>
                  <p>NO</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            We may also collect other personal information outside of these
            categories instances where you interact with us in-person, online,
            or by phone or mail in the context of:
          </p>

          <ul>
            <li>
              Receiving help through our customer support channels;
            </li>

            <li>
              Participation in customer surveys or contests;
            </li>

            <li>
              Facilitation in the delivery of our Services and to respond to your inquiries.
            </li>
          </ul>

          <p>
            <strong>How do we use and share your personal information?</strong>
          </p>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>

          <p>
            You may contact us by Email at{' '}
            <Link href="mailto:dan.yonah.marshall@gmail.com" target="">
              dan.yonah.marshall@gmail.com
            </Link>
            , by visiting{' '}
            <Link
              href="https://www.cccaonline.org"
              rel="noopener noreferrer"
            >
              https://www.cccaonline.org
            </Link>
            , by calling (617) 642-1444, or by referring to the contact details
            at the bottom of this document.
          </p>

          <p>
            If you are using an authorized agent to exercise your right to
            opt-out we may deny a request if the authorized agent does not
            submit proof that they have been validly authorized to act on your
            behalf.
          </p>

          <p>
            <strong>Will your information be shared with anyone else?</strong>
          </p>

          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
          </p>

          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal data.
          </p>

          <p>
            Brookline/Cambridge Community Center for the Arts, Inc. (BCCA /
            CCCA)has not disclosed or sold any personal information to third
            parties for a business or commercial purpose in the preceding 12
            months. Brookline/Cambridge Community Center for the Arts, Inc.
            (BCCA / CCCA) will not sell personal information in the future
            belonging to website visitors, users and other consumers.
          </p>

          <p>
            <strong>Your rights with respect to your personal data</strong>
          </p>

          <p>
            <u>Right to request deletion of the data - Request to delete</u>
          </p>

          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation or any
            processing that may be required to protect against illegal
            activities.
          </p>

          <p>
            <u>Right to be informed - Request to know</u>
          </p>

          <p>
            Depending on the circumstances, you have a right to know:
          </p>

          <ul>
            <li>
              whether we collect and use your personal information;
            </li>

            <li>
              the categories of personal information that we collect;
            </li>

            <li>
              the purposes for which the collected personal information is used;
            </li>

            <li>
              whether we sell your personal information to third parties;
            </li>

            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </li>

            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>

            <li>
              the business or commercial purpose for collecting or selling
              personal information.
            </li>
          </ul>

          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>

          <p>
            <u>
              Right to Non-Discrimination for the Exercise of a Consumers
              Privacy Rights
            </u>
          </p>

          <p>
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>

          <p>
            <u>Verification process</u>
          </p>

          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or Email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>

          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. If, however, if we cannot verify
            your identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity, and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>

          <p>
            <u>Other privacy rights</u>
          </p>

          <ul>
            <li>you may object to the processing of your personal data</li>

            <li>
              you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data
            </li>

            <li>
              you can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>

            <li>
              you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </li>
          </ul>

          <p>
            To exercise these rights, you can contact us by Email at&nbsp;
            <Link href="mailto:dan.yonah.marshall@gmail.com" target="">
              dan.yonah.marshall@gmail.com
            </Link>
            , by visiting{' '}
            <Link
              href="https://www.cccaonline.org"
              rel="noopener noreferrer"
            >
              https://www.cccaonline.org
            </Link>
            , by calling (617) 642-1444, or by referring to the contact details
            at the bottom of this document. If you have a complaint about how we
            handle your data, we would like to hear from you.
          </p>



        {/*----- chapter 9 -----*/}
          <h2 ref={policyUpdates}>
            <strong>9. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
          </h2>

          <p>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </p>

          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>


          
        {/*----- chapter 10 -----*/}
          <h2 ref={contact}>
            <strong>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
          </h2>

          <p>
            If you have questions or comments about this notice, you may contact
            our Executive Artistic Director, Dan Marshall, by Email at&nbsp;
            <Link href="mailto:dan.yonah.marshall@gmail.com" target="">
              dan.yonah.marshall@gmail.com
            </Link>
            ,&nbsp;by phone at (617) 642-1444, or by post at the following
            corporate address:
          </p>

          <p>
            <span>
              Brookline/Cambridge Community Center for the Arts, Inc. (BCCA /
              CCCA)
            </span>
            <span>327 Saint Paul Street, Apt. 2</span>
            <span>Brookline, MA 02446</span>
            <span>United States</span>
          </p>


        
        {/*----- chapter 11 -----*/}
          <h2 ref={request}>
            <strong>
              11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </strong>
          </h2>

          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please visit:{' '}
            <Link
              href="https://www.cccaonline.org"
              rel="noopener noreferrer"
            >
              https://www.cccaonline.org
            </Link>
            . We will respond to your request within 30 days.
          </p>



        {/*----- chapter 12 -----*/}
          <h2 ref={cookie}>
            <strong>
              12. COOKIE NOTICE & POLICY
            </strong>
          </h2>

          <p>
            <strong>Cookie List</strong>
          </p>

          <p>
            A cookie is a small piece of data (a text file) that a website – when 
            visited by a user – asks your browser to store on your device in order 
            to remember information about you, such as your language preference or 
            login information. Cookies that are set by us are called first-party cookies. 
            We also use third-party cookies – which are cookies from a domain different 
            than the domain of the website you are visiting. More specifically, we use 
            cookies and other tracking technologies for the following purposes:
          </p>

          <p>
            <strong>Strictly Necessary Cookies</strong>
          </p>

          <p>
            These cookies are necessary for the website to function and cannot be switched 
            off in our systems. They are usually only set in response to actions made by you 
            which amount to a request for services, such as setting your privacy preferences, 
            logging in or managing your session with the requested service. You can set your 
            browser to block or alert you about these cookies, but some parts of the site will not 
            then work. These cookies do not store any personally identifiable information.
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Cookie</strong>
                </td>
                <td><strong>Domain</strong>
                </td>
                <td><strong>Description</strong>
                </td>
                <td><strong>Duration</strong>
                </td>
              </tr>

              <tr>
                <td>ts</td>
                <td>.paypal.com</td>
                <td>PayPal sets this cookie to enable secure transactions through PayPal.</td>
                <td>3 years</td>
              </tr>

              <tr>
                <td>ts_c</td>
                <td>.paypal.com</td>
                <td>PayPal sets this cookie to make safe payments through PayPal.</td>
                <td>3 years</td>
              </tr>

              <tr>
                <td>enforce_policy</td>
                <td>.paypal.com</td>
                <td>PayPal sets this cookie for secure transactions.</td>
                <td>1 year</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>Performance Cookies</strong>
          </p>

          <p>
            These cookies are set by a third-party service and they are necessary 
            for the service login function on the website.
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Cookie</strong>
                </td>
                <td><strong>Domain</strong>
                </td>
                <td><strong>Description</strong>
                </td>
                <td><strong>Duration</strong>
                </td>
              </tr>

              <tr>
                <td>l7_az</td>
                <td>.paypal.com</td>
                <td>This cookie is necessary for the PayPal login-function on the website.</td>
                <td>30 minutes</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>Functional Cookies</strong>
          </p>

          <p>
            These cookies are set by a third-party service and they enable the website 
            to provide enhanced functionality and personalization.
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Cookie</strong>
                </td>
                <td><strong>Domain</strong>
                </td>
                <td><strong>Description</strong>
                </td>
                <td><strong>Duration</strong>
                </td>
              </tr>

              <tr>
                <td>LANG</td>
                <td>.paypal.com</td>
                <td>Linkedin set this cookie to set user's preferred language.</td>
                <td>9 hours</td>
              </tr>

              <tr>
                <td>x-pp-s</td>
                <td>.paypal.com</td>
                <td>PayPal sets this cookie to process payments on the site.</td>
                <td>session</td>
              </tr>

              <tr>
                <td>nsid</td>
                <td>www.paypal.com</td>
                <td>This cookie is set by the provider PayPal to enable the PayPal payment service in the website.</td>
                <td>session</td>
              </tr>

              <tr>
                <td>tsrce</td>
                <td>.paypal.com</td>
                <td>PayPal sets this cookie to enable the PayPal payment service in the website.</td>
                <td>3 days</td>
              </tr>
            </tbody>
          </table>

          <p>
            <strong>Analytics Cookies</strong>
          </p>

          <p>
            These cookies are necessary for our systems to measure audience and usage statistics, 
            as well as content consumption. These enable us to obtain relevant usage information 
            to produce internal analytics so we can monitor and improve the performance of our system.
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Cookie</strong>
                </td>
                <td><strong>Domain</strong>
                </td>
                <td><strong>Description</strong>
                </td>
                <td><strong>Duration</strong>
                </td>
              </tr>

              <tr>
                <td>_pk_id.1.a9f0</td>
                <td>ccca.worldeducationweb.org</td>
                <td>Matamo set this cookie to store a unique user ID.</td>
                <td>1 year 27 days</td>
              </tr>

              <tr>
                <td>_pk_ses.1.a9f0</td>
                <td>ccca.worldeducationweb.org</td>
                <td>This cookie is used to store a unique session ID for gathering information on how the users use the website.</td>
                <td>30 minutes</td>
              </tr>
            </tbody>
          </table>
        </div>
      </InfoBox>
      <div className="policy-footer">
        {options ? (
          <>
            <Button type="transparent" onClick={() => onPolicyAction(false)}>
              Decline
            </Button>
            <Button type="primary" onClick={() => onPolicyAction(true)}>
              Accept
            </Button>
          </>
        ) : (
          <Button 
            type="primary" 
            onClick={() => {
              isModal ? closeModal('policy-notice') : onPolicyAction()
            }}
          >
            Back
          </Button>
        )}
      </div>
    </>
  );
};
