import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getFacultyInstructors } from '@actions/facultyActions';
import { useLazyLoaded } from '@src/hooks';
import { classNames } from '@src/utils';
import { Container } from '@components/layout';
import { FacultyHeader, FacultyBody } from '@components/features/Faculty';

export const Faculty = ({ className, filtersSelect, search, instructors }) => {
  const { page } = instructors;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFacultyInstructors(page, filtersSelect, search));
  }, [search, filtersSelect]);

  const loadMore = () => {
    dispatch(getFacultyInstructors(page, filtersSelect, search, true));
  };

  const facultyBody = useRef(null);

  useLazyLoaded(facultyBody.current, instructors, loadMore);

  return (
    <Container>
      <div
        className={classNames([
          "global-page",
          "faculty", 
          className
        ])}
      >
        <FacultyHeader />
        <div ref={facultyBody}>
          <FacultyBody />
        </div>
      </div>
    </Container>
  );
};