import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { 
  createTab, 
  setActiveTab, 
  classNames, 
  changeBodyScroll 
} from '@src/utils';
import { useViewport } from '@src/hooks';
import { Tabs, Icon } from '@components/shared';

export const AccountNavigation = ({ setIsMobileNavOpen }) => {
  const { isTablet } = useViewport();
  const { pathname } = useLocation();
  const path = `/${pathname.split("/")[1]}`;
  const history = useHistory();

  const accountPath = '/account';

  const TabContent = (type, text) => (
    <>
      {!isTablet && 
        <span className={classNames([
          'account__tab-icon',
          `account__tab-icon--${type}`,
        ])}>
          <Icon type={type} size='lg' />
        </span>
      }
      <span className='account__tab-text'>
        {text}
      </span>
    </>
  );

  const [navTabs, setNavTabs] = useState([
    createTab(TabContent('calendar', 'Schedule'), `${path}/schedule`, true),
    createTab(TabContent('tickets-tab', 'Tickets'), `${path}/tickets`),
    createTab(TabContent('video-tab', 'Videos'), `${path}/videos`),
    createTab(TabContent('transaction-tab', 'Transactions'), `${path}/transactions`),
    createTab(TabContent('profile-tab', 'Profile'), `${path}/profile-information`),
    createTab(TabContent('institutions-tab', 'Institution Settings'), `${path}/institutions-settings`),
  ]);

  useEffect(() => {
    if(pathname !== accountPath) {
      setActiveTab(setNavTabs, pathname);
    }

    history.listen((location) => {
      setActiveTab(setNavTabs, location.pathname);
    });
  }, []);


  const onAccountTabClick = ({value}) => {
    if (isTablet) {
      setIsMobileNavOpen(false);
      changeBodyScroll(false, 'modal');
    }

    history.push(value);
  }

  return (
    <Tabs
      tabsData={navTabs}
      className='account__tabs'
      onTabClick={(tab) => onAccountTabClick(tab)}
      isShadowVisible={false}
    />
  );
};