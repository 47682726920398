import React, { useState } from 'react';
import VideoCard from '@components/features/Videos/VideoCard';
import { Button } from '@components/shared';

const Videos = ({ videos, videosHandler, isPurchased, noAuthorized }) => {
  const [expandedVideo, setExpandedVideo] = useState(null);

  return (
    <div className="videos">
      <div className="videos__title-block">
        {noAuthorized ? 
          (
            <h3>Videos</h3>
          ) : (
            <h3>
              {isPurchased ? 'Previously paid for videos' : 'Available Pay to Watch videos'}
            </h3>
          )
        }

        <Button
        type="primary"
        className="videos__btn"
        onClick={() => videosHandler()}
        >
          {isPurchased ? 'View all paid for videos' : 'Pay to Watch'}
        </Button>
      </div>
      {videos && videos.map((video) => (
        <VideoCard
          video={video}
          key={video.id}
          isExpanded={expandedVideo === video.id}
          setExpandedVideo={setExpandedVideo}
          isVideoGalleryCard
        />
      ))}
    </div>
  );
};

export default Videos;