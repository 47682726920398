import React from 'react';
import JWPlayer from 'react-jwplayer';
import Vimeo from '@u-wave/react-vimeo';
import Details from '@components/shared/Details/Details';

const VideoGalleryCard = ({ videoData }) => {
  const { type, embed_id, subtitle, title } = videoData;

  return (
    <div className="global-page-card video-gallery__card">
      <div className={`video-gallery__video video-gallery__video--${type}`}>
        {type === 'vimeo' ? (
          <Vimeo video={embed_id} />
        ) : (
          <JWPlayer videoId={embed_id} />
        )}
      </div>
      <div className="video-gallery__info">
        <span className="video-gallery__title">{title}</span>
        <Details className="video-gallery__subtitle" isVideoGalleryCard>
          <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
        </Details>
      </div>
    </div>
  );
};

VideoGalleryCard.propTypes = {};

export default VideoGalleryCard;