import React from "react";
import { receiptDateFormat, receiptTimeFormat } from '@src/constants';
import { Button, Loader, Icon } from '@components/shared';
import { format } from 'date-fns';
import { useViewport, useApi } from '@src/hooks';
import {
  downloadPdf,
  getOfferingsAmount,
  openPdf,
  apiRequest,
  requestCallback,
} from '@src/utils';

export const ReceiptModal = ({ receiptId }) => {
  const { isMobile } = useViewport();
  const receipt = useApi({
    url: `/account/transactions/${receiptId}`,
  });
  const { 
    order, 
    amount, 
    id, 
    created_at, 
    payment_method, 
    card_type 
  } = receipt.data || {};
  
  const {
    billing_address,
    registration,
    videos = [],
    videos_count,
    type,
    donation,
    tickets_count,
    tickets_dates,
  } = order || {};

  const {
    city,
    country,
    email,
    first_name,
    last_name,
    phone,
    state,
    street,
    zip,
  } = billing_address || {};

  const { course } = registration || videos[0] || order || {};

  const isDonation = type === 'donation';
  const isRegistration = type === 'registration';
  const isTicket = type === 'ticket';
  const isVideo = type === 'video_purchase';
  const donationDescription = donation?.description;

  const itemsAmount =
    (isRegistration &&
      getOfferingsAmount(
        registration.appointments_count,
        registration.course.content_type
      )) ||
    (isVideo && `${videos_count} video(s)`) ||
    (isTicket && `${tickets_count} ticket(s)`);

  const placedDate = created_at
    ? format(new Date(created_at), receiptDateFormat)
    : '';
  const placedTime = created_at
    ? format(new Date(created_at), receiptTimeFormat).toLowerCase()
    : '';

  const getPdf = (id, callback) => {
    apiRequest({
      url: `/account/transactions/${id}/receipt-pdf`,
      callbacks: requestCallback(({ data }) => {
        callback(data);
      }),
    });
  };

  const onPrintClick = () => {
    getPdf(id, openPdf);
  };

  const onSaveClick = () => {
    getPdf(id, downloadPdf);
  };

  if (!receipt.data) {
    return <Loader />;
  }

  return (
    <div className="account-modal">
      <div className="account-modal__block">
        <div className="account-modal__row">
          <div className="account-modal__label">Payment Made On:</div>
          <div className="account-modal__value">
            <div>{placedDate}</div>
            <div>{placedTime} EST</div>
          </div>
        </div>
      </div>
      <div className="account-modal__block">
        <div className="account-modal__row">
          <div className="account-modal__label">Amount of Transaction:</div>
          <div className="account-modal__value">&#36;{amount}</div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">Payment Method:</div>
          <div className="account-modal__value">{payment_method}</div>
        </div>
        {card_type &&  
        (payment_method !== 'Cash' || 'Check') &&
          (
            <div className="account-modal__row">
              <div className="account-modal__label">Card Type:</div>
              <div className="account-modal__value">{card_type}</div>
            </div>
          )}
      </div>
      <h4 className="account-modal__title">BILL TO</h4>
      <div className="account-modal__block">
        <div className="account-modal__row">
          <div className="account-modal__label">Full Name:</div>
          <div className="account-modal__value">
            {first_name} {last_name}
          </div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">Address:</div>
          <div className="account-modal__value">{street ? street : '–'}</div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">Country:</div>
          <div className="account-modal__value">{country ? country : '–'}</div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">City:</div>
          <div className="account-modal__value">{city ? city : '–'}</div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">State:</div>
          <div className="account-modal__value">{state ? state : '–'}</div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">Zip Code:</div>
          <div className="account-modal__value">{zip ? zip : '–'}</div>
        </div>
      </div>
      <div className="account-modal__block">
        <div className="account-modal__row">
          <div className="account-modal__label">Phone:</div>
          <div className="account-modal__value">{phone}</div>
        </div>
        <div className="account-modal__row">
          <div className="account-modal__label">Email:</div>
          <div className="account-modal__value">{email}</div>
        </div>
      </div>
      <h4 className="account-modal__title">ORDER DESCRIPTION</h4>
      <div className="account-modal__row">
        <div className="account-modal__label">Order ID:</div>
        <div className="account-modal__value">{order.id}</div>
      </div>
      {!isDonation && (
        <>
          <div className="account-modal__row">
            <div className="account-modal__label">Content / Offering:</div>
            <div className="account-modal__value">
              {course?.label} - {course?.title}
            </div>
          </div>

          <div className="account-modal__row">
            <div className="account-modal__label">Registration type:</div>
            <div className="account-modal__value">
              {registration?.type_label ||
                (isVideo && 'Pay to Watch Video') ||
                (isTicket && 'Tickets')}
            </div>
          </div>

          <div className="account-modal__row">
            <div className="account-modal__label">Amount:</div>
            <div className="account-modal__value">{itemsAmount}</div>
          </div>

          {isTicket && (
            <div className="account-modal__row">
              <div className="account-modal__label">Date(s):</div>
              <div className="account-modal__value">{tickets_dates}</div>
            </div>
          )}
          {isRegistration && (
            <div className="account-modal__row">
              <div className="account-modal__label">Date(s):</div>
              <div className="account-modal__value">
                {order.appointments_dates}
              </div>
            </div>
          )}
        </>
      )}
      {donationDescription && (
        <div className="account-modal__description">
          {isDonation ? 'A' : 'Plus a'} {donationDescription}
        </div>
      )}
      <div className="account-modal__actions">
        {!isMobile && (
          <Button
            type="primary"
            className="account-modal__print-button"
            iconRight={<Icon size="md" type="printer" />}
            onClick={onPrintClick}
          >
            Print
          </Button>
        )}
        <Button
          type="primary"
          className="account-modal__print-button account-modal__print-button--save"
          iconRight={<Icon size="md" type="save" />}
          onClick={onSaveClick}
        >
          Save
        </Button>
      </div>
    </div>
  );
}