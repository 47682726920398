import React, { useEffect, useState } from 'react';
import { ClassRegistration } from './ClassRegistration/ClassRegistration';
import { Checkout } from './Checkout/Checkout';
import { RegistrationType } from './RegistrationType/RegistrationType';
import { Login } from './Login/Login';
import {
  changeModalProps,
  closeModal,
  Loader,
  RegistrationTitleBlock,
} from '@components/shared';
import { getRegTypesTabs } from './RegistrationType/RegistrationTypeData';
import { useSelector } from 'react-redux';
import { queryParam, registrationPaymentRequest, checkoutPaymentRequest } from '@src/utils';
import { registerTypes } from '@src/constants';
import { useApi } from '@src/hooks';

export const Registration = ({
  classId,
  sessionId,
  initialStep,
  initialType,
}) => {
  const courseInfo = useApi({
    url: `/courses/${classId}`,
  });
  const paymentLoading = useSelector(
    (state) => state.registration.paymentLoading
  );
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [regStep, setRegStep] = useState(initialStep);
  const [regType, setRegType] = useState(initialType);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [regTypesTabs, setRegTypesTabs] = useState([]);

  const id = courseInfo?.data?.id;
  const title = courseInfo?.data?.title;
  const categoriesArray = courseInfo?.data?.categories.map((category) => {
    return category.name
  });
  const categoriesString = categoriesArray && categoriesArray.join(", ");

  useEffect(() => {
    changeModalProps('registration', {
      showBackButton: false,
    });
    setRegInitialQuery();

    return () => {
      queryParam.delete(['regStep', 'classId', 'sessionId', 'regType']);
    };
  }, []);

  useEffect(() => {
    if (courseInfo.data?.id === classId) {
      const sessions = courseInfo.data.sessions;
      const currSession = sessions.find(({ id }) => id === sessionId) || {};
      setSessionInfo(currSession);
      
      const tabs = getRegTypesTabs(currSession, courseInfo.data);
      setRegTypesTabs(tabs);
    }
  }, [courseInfo]);

  useEffect(() => {
    queryParam.set('regStep', regStep);
  }, [regStep]);

  const setRegInitialQuery = () => {
    queryParam.setEntries({ regStep, classId });
    initialType && queryParam.set('regType', initialType);
    sessionId && queryParam.set('sessionId', sessionId);
  };

  const btnCallbacks = {
    registrationType: {
      back: () => closeModal('registration'),
      submit: (regType) => {
        setRegType(regType);
        queryParam.set('regType', regType);
        if (isAuthorized) {
          window._mtm = window._mtm || [];

          if (regType === 'v') {
            window._mtm.push({'event': 'ptw_popup', 'video_popup': `${title}`});
          } else {
            window._mtm.push({
              'event': 'add_to_cart',
              'ecommerce': {
                'add_to_cart': {
                  'id': `${id}`, //SKU
                  'item_name': `${title}`, //Product name
                  'item_category': `${categoriesString}`, //Product category
                  'price': 'dynamic value', // product price
                }
              }
            });
          }

          setRegStep(3);
        } else {
          setRegStep(2);
        }
      },
    },
    classRegistration: {
      back: () => {
        setRegStep(1);
      },
      submit: (data) => {
        return registrationPaymentRequest(data, registerTypes[regType])
      },
    },
    login: {
      back: () => {
        setRegStep(1);
      },
      submit: () => {
        regType ? setRegStep(3) : setRegStep(1);
      },
    },
    checkout: {
      submit: (data) => checkoutPaymentRequest(data),
    },
  };

  const stepsNavigation = {
    1: (
      <RegistrationType
        btnCallbacks={btnCallbacks.registrationType}
        tabs={regTypesTabs}
        isAuthorized={isAuthorized}
        setRegStep={setRegStep}
        regType={regType}
      />
    ),

    2: <Login btnCallbacks={btnCallbacks.login} isCourseReg />,
    3: (
      <ClassRegistration
        regType={regType}
        sessionInfo={sessionInfo}
        btnCallbacks={btnCallbacks.classRegistration}
        courseInfo={courseInfo.data}
      />
    ),
    4: (
      <ClassRegistration
        regType={regType}
        sessionInfo={sessionInfo}
        btnCallbacks={btnCallbacks.classRegistration}
        courseInfo={courseInfo.data}
      />
    ),
    5: (<Checkout btnCallbacks={btnCallbacks.checkout}/>),
  };

  return (
    <>
      <Loader show={paymentLoading || courseInfo.loading} sticky />
      {courseInfo.data && sessionInfo && (
        <div className="registration-details">
          <RegistrationTitleBlock
            classDetails={courseInfo.data}
            regStep={regStep}
            regType={regType}
          />
          {stepsNavigation[regStep]}
        </div>
      )}
    </>
  );
};

Registration.defaultProps = {
  initialStep: 1,
};
