import React, { useState } from 'react';
import { format, getDay } from 'date-fns';
import { useViewport } from '@src/hooks';
import { getOptions, getAgeRange, classNames } from '@src/utils';
import { fullWeekDays, dateSessionsFormat, twelveHoursFormat } from '@src/constants';
import { ProgramsCourseSessions } from '@components/features/Programs/ProgramsCourseSessions';
import {
  Button,
  CourseBadges,
  getModal,
  Icon,
  openModal,
  InstructorName,
  Link
} from '@components/shared';
import { ProviderLogo } from '@components/provider'

export const ProgramsCourseCard = ({ course, className, isProviderAbout }) => {
  const { isMobile } = useViewport();
  const {
    id,
    label,
    title,
    participant_age_types,
    participant_age_from,
    participant_age_to,
    participant_levels,
    short_description,
    sessions,
    content_type,
    participant_level_exception,
    participant_age_exception,
    upcoming_class,
    profile_image_url,
  } = course;
  const [isSessionsShown, setIsSessionsShown] = useState(false);
  const href = window.location.href;
  const returnPath = localStorage.getItem('returnPath');

  const onLearnMoreClick = (eventId) => {
    if (!(returnPath && returnPath.includes('offering'))) {
      localStorage.setItem('returnHref', href);
      localStorage.setItem('returnPath', 'programs');
    }

    openModal(
      getModal({
        name: 'class-details',
        data: { eventId },
      })
    );

    window._mtm = window._mtm || [];
    href.includes('search') && window._mtm.push({'event': 'search_main', 'query': `${href.split("search=")[1].split("&")[0]}`});
    window._mtm.push({
      'event': 'view_item',
      'ecommerce': {
        'view_item': {
          'id': `${id}`,
          'item_name': `${title}`,
          'item_category': 'category value',
          'price': 'price value',
          }
        }
    });
  };

  const getFormattedUpcommingDate = (date) => {
    const formattedDate = new Date(date);
    const upcommingDate = format(formattedDate, dateSessionsFormat);
    const upcommingWeekday = fullWeekDays[getDay(formattedDate)];
    const upcommingTime = format(formattedDate, twelveHoursFormat);

    return (
      // <span style={{ whiteSpace: 'nowrap' }}>
      <span>
        {upcommingDate}, <b>{upcommingWeekday}</b>{' '}
        <span style={{ textTransform: 'lowercase' }}>{upcommingTime}</span> EST
      </span>
    );
  };

  const levels = getOptions(participant_levels);
  const ageTypes = getOptions(participant_age_types);
  const ageRange = getAgeRange(
    participant_age_from,
    participant_age_to,
    participant_age_types
  );

  return (
    <div
      className={classNames([
        "programs-course",
        profile_image_url ? "programs-course--full" : "programs-course--empty",
        className
      ])}
    >
      <div className="programs-course__visual">
        {/* <ProviderLogo 
          className="programs-course__logo"
          src="https://images.squarespace-cdn.com/content/v1/62dd8c08fd1480736a4e030a/780c2b99-4d78-4060-8348-04fb1110eccd/DS84+NEW+LOGO+STACKED.png"
        /> */}

        <div className="programs-course__visual-body">
          {profile_image_url && <img src={profile_image_url} alt="offering photo" />}
        </div>
      </div>

      <div className="programs-course__content">
        <div className="programs-course__content-body">
          <div className="programs-course__content-top">
            <span className="programs-course__course-id">{label}</span>

            <div className="programs-course__title-blok">
              <h3>
                {/* <Link 
                  href={`${window.location.origin}/offering/${id}`}
                  target=""
                  iconRight={<Icon size="full" type="proceed-next" />}
                  onClick={() => onLearnMoreClick(id)}
                >
                  {title}
                </Link> */}

                <Button
                  iconRight={<Icon size="full" type="proceed-next" />}
                  onClick={() => onLearnMoreClick(id)}
                >
                  {title}
                </Button>

              </h3>
            </div>

            <CourseBadges course={course} />

            {short_description && (
              <p className="programs-course__course-description">
                {short_description}
              </p>
            )}

            <InstructorName
              course={course}
              className="programs-course__instructors"
              hasIcon
              isInline
            />

            {(levels || ageTypes || ageRange) && (
              <div className="programs-course__requirements">
                <span>
                  {levels}<sup>{levels && participant_level_exception}</sup>
                </span>
                
                {levels && (ageTypes || ageRange) && ' / '}

                <span>
                  {ageTypes}
                  {ageRange}
                  <sup>{ageTypes && participant_age_exception}</sup>
                </span>
              </div>
            )}
          </div>

          {!!sessions.length && (
            <div className="programs-course__content-bottom">
              {upcoming_class && (
                <div className="programs-course__upcomming-date">
                  <span className="programs-course__upcomming-title">
                    Upcoming {content_type.singular_short_name}:{' '}
                  </span>

                  {getFormattedUpcommingDate(upcoming_class)}
                </div>
              )}

              {!isProviderAbout &&
                <Button
                  className={classNames([
                    "programs-course__schedule-button",
                    isSessionsShown && "programs-course__schedule-button--active",
                  ])}
                    iconRight={<Icon type="chevron" size="md" />}
                    onClick={() => setIsSessionsShown(!isSessionsShown)}
                >
                  {!isMobile && className !== "programs-course--about" && "Schedule"}
                </Button>
              } 
            </div>
          )}
        </div>

        {!isProviderAbout && isSessionsShown && (
          <div className="programs-course__sessions">
            <div className="programs-course__sessions-title">
              Schedule / Sessions:
            </div>

            {sessions.map((session) => (
              <ProgramsCourseSessions data={session} key={session.id} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};