import React from 'react';
import { Button, Icon } from '../index';
import { 
  format, 
  addDays, 
  subDays, 
  addMonths, 
  subMonths, 
  isSameMonth, 
  startOfWeek, 
  endOfWeek 
} from 'date-fns';
import { useViewport } from '@src/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { getWeekDays, getWeekRange } from '@src/utils';
import { setWeekDate, setDayDate } from '@actions/scheduleActions';

export const CalendarDropdownDatePreview = ({ 
  dayDate,
  setMonth, 
  allowedMonths, 
  calendarHandler, 
  selectedDay, 
  isAccountCalendar, 
  isScheduleDayCalendar, 
  isScheduleWeekCalendar, 
}) => {
  const { width } = useViewport();
  const isMobile = width < 480;
  const nextMonth = addMonths(new Date(selectedDay), 1);
  const prevMonth = subMonths(new Date(selectedDay), 1);
  const selectedWeek = useSelector((state) => state.schedule.weekDate);

  const dispatch = useDispatch();
  const setWeek = (week) => {
    dispatch(setWeekDate(week));
  };

  const isAllowedMonth = (allowedMonths, month, specificMonth) => {
    return !allowedMonths.length || allowedMonths.some((month) => isSameMonth(new Date(specificMonth), new Date(month)));
  }

  const onNextMonth = (month) => {
    if (isAllowedMonth(allowedMonths, month, nextMonth)) {
      setMonth(nextMonth);
    }
  };

  const onPrevMonth = (month) => {
    if (isAllowedMonth(allowedMonths, month, prevMonth)) {
      setMonth(prevMonth);
    }
  };

  const onDayHandler = (goNext = true, isWeek) => {
    if (!isWeek) {
      goNext
        ? dispatch(setDayDate(addDays(dayDate, 1)))
        : dispatch(setDayDate(subDays(dayDate, 1)));
    }
  };

  const onWeekHandler = (goNext) => {
    const nextWeekStartDate = addDays(selectedWeek[0], 7);
    const prevWeekStartDate = subDays(selectedWeek[0], 7);
    goNext
      ? setWeek(getWeekDays(getWeekRange(nextWeekStartDate).from))
      : setWeek(getWeekDays(getWeekRange(prevWeekStartDate).from));
  };

  return (
    <div className="calendar-dropdown__date-preview">
      <div className="date-preview">
        {isAllowedMonth(allowedMonths, selectedDay, prevMonth) && (
          <Button
            onClick={() => {
              isAccountCalendar && onPrevMonth(selectedDay);
              isScheduleDayCalendar && onDayHandler(false, false);
              isScheduleWeekCalendar && onWeekHandler(false);
            }}
            className="date-preview__button-prev"
          >              
            <Icon type="chevron" />
          </Button>
        )}
        <Button
          className="date-preview__content"
          iconLeft={<Icon  className='date-preview__calendar-icon' type="calendar" size="md" />}
          onClick={calendarHandler}
        >              
          {isAccountCalendar && format(selectedDay, 'MMMM d, yyyy')}
          {isScheduleDayCalendar && format(selectedDay, `${isMobile ? 'EEE' : 'EEEE'}, MMM d, yyyy`)}
          {isScheduleWeekCalendar && `${format(startOfWeek(selectedDay), `MMM d, ${isMobile ? 'yy' : 'yyyy'}`)} - ${format(endOfWeek(selectedDay), `MMM d, ${isMobile ? 'yy' : 'yyyy'}`)}`}
        </Button>
        {isAllowedMonth(allowedMonths, selectedDay, nextMonth) && (
          <Button
            onClick={() =>  {
              isAccountCalendar && onNextMonth(selectedDay);
              isScheduleDayCalendar && onDayHandler(true, false);
              isScheduleWeekCalendar && onWeekHandler(true);
            }}
            className="date-preview__button-next"
          >
            <Icon type="chevron" />
          </Button>
        )}
      </div>
    </div>
  );
};