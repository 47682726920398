import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useViewport, useLazyLoaded } from '@src/hooks';
import { queryParam, classNames } from '@src/utils';
import { getCourses } from '@actions/programsActions';
import { Container } from '@components/layout';
import { SlideMenu } from '@components/shared';
import { 
  ProgramsCourses, 
  ProgramsFilter, 
  ProgramsHeader, 
  ProgramsSortingMenu 
} from '@components/features/Programs';
import { ProviderBanner } from '@components/provider';

export const Programs = ({ 
  tab, 
  filtersSelect, 
  courses, 
  sortingBy, 
  sortingOrder, 
  className, 
  cardClassName, 
  isProvider, 
  isProviderAbout 
}) => {
  const { isTablet } = useViewport();
  const [filtersMenuOpen, setFiltersMenuOpen] = useState(false);
  const [sortingMenuOpen, setSortingMenuOpen] = useState(false);
  const [search, setSearch] = useState(queryParam.get('search') || '');

  const coursesRequestData = {
    filtersSelect,
    tab,
    sortingOrder,
    sortingBy,
  };

  const { page } = courses;

  const dispatch = useDispatch();

  const programsBody = useRef(null);

  const loadMore = () => {
    dispatch(getCourses(coursesRequestData, search, page, true));
  };

  useLazyLoaded(programsBody.current, courses, loadMore);

  return (     
    <div
      className={classNames([
        "global-page",
        "programs", 
        className
      ])}
    >
      {isProvider &&
        <ProviderBanner className="programs-banner" />
      }
      <Container>
        <div className="columns programs__columns">
          {!isTablet ? (
            <div className="columns__item programs__filter-column">
              <h1 className="global-page__title programs__title">Programs</h1>
              <ProgramsFilter />
            </div>
          ) : (
            <SlideMenu
              isShown={filtersMenuOpen}
              className={'mobile-filters'}
              onCloseClick={() => setFiltersMenuOpen(false)}
            >
              <ProgramsFilter />
            </SlideMenu>
          )}

          <div
            ref={programsBody}
            className="columns__item programs__courses-column"
          >
            {isTablet && (
              <h1 className="global-page__title programs__title">Programs</h1>
            )}
            <ProgramsHeader
              onFiltersMenuButtonClick={() => setFiltersMenuOpen(true)}
              search={search}
              setSearch={setSearch}
              onSortingMenuButtonClick={() => setSortingMenuOpen(true)}
            />

            <ProgramsCourses 
              className={cardClassName}
              isProviderAbout={isProviderAbout} 
            />

            {isTablet && (
              <SlideMenu
                isShown={sortingMenuOpen}
                className={'mobile-filters'}
                onCloseClick={() => setSortingMenuOpen(false)}
                position="left"
              >
                <ProgramsSortingMenu />
              </SlideMenu>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};