import { apiRequest, requestReduxHandler, requestCallback } from '@src/utils';
import { onError, onLoading, onSuccess } from '@src/store/actionCreators';
import * as types from '@types/authTypes';

export const logout = () => () => {
  apiRequest({
    url: `/auth/logout`,
    method: 'POST',
    callbacks: requestCallback(() => localStorage.removeItem('token')),
  });
};

export const getUserData = () => () => {
  apiRequest({
    url: '/account/profile',
    method: 'GET',
    reduxHandlers: requestReduxHandler(
      (res) => onSuccess(types.USER_DATA, res.data),
      () => onLoading(types.USER_DATA_LOADING),
      (err) => onError(types.USER_DATA_ERROR, err)
    ),
  });
};

export const updateUserData =
  (loginData, submitHandler, errorsHandler) => () => {
    apiRequest({
      url: '/account/profile',
      method: 'PUT',
      data: JSON.stringify(loginData),
      reduxHandlers: requestReduxHandler(
        (res) => onSuccess(types.USER_DATA, res.data),
        () => onLoading(types.USER_DATA_UPDATE_LOADING),
        (err) => onError(types.USER_DATA_ERROR, err)
      ),
      callbacks: requestCallback(
        (res) => {
          submitHandler();
        },
        (err) => {
          err?.response && errorsHandler(err.response);
        }
      ),
    });
  };
