export const DAY_DATE = `DAY_DATE`;
export const WEEK_DATE = `WEEK_DATE`;

export const FILTERS = `FILTERS`;
export const FILTERS_LOADING = `FILTERS_LOADING`;
export const FILTERS_ERROR = `FILTERS_ERROR`;

export const DAY_TABLE = `DAY_TABLE`;
export const DAY_TABLE_LOADING = `DAY_TABLE_LOADING`;
export const DAY_TABLE_ERROR = `DAY_TABLE_ERROR`;

export const WEEK_TABLE = `WEEK_TABLE`;
export const WEEK_TABLE_LOADING = `WEEK_TABLE_LOADING`;
export const WEEK_TABLE_ERROR = `WEEK_TABLE_ERROR`;

export const CATEGORIES_TABLE = `CATEGORIES_TABLE`;
export const CATEGORIES_TABLE_LOADING = `CATEGORIES_TABLE_LOADING`;
export const CATEGORIES_TABLE_ERROR = `CATEGORIES_TABLE_ERROR`;

export const INSTRUCTORS_TABLE = `INSTRUCTORS_TABLE`;
export const INSTRUCTORS_TABLE_LOADING = `INSTRUCTORS_TABLE_LOADING`;
export const INSTRUCTORS_TABLE_ERROR = `INSTRUCTORS_TABLE_ERROR`;

export const PROGRAMS_TABLE = `PROGRAMS_TABLE`;
export const PROGRAMS_TABLE_LOADING = `PROGRAMS_TABLE_LOADING`;
export const PROGRAMS_TABLE_ERROR = `PROGRAMS_TABLE_ERROR`;

export const FILTER_SELECT_LOCATION = `FILTER_SELECT_LOCATION`;
export const FILTER_SELECT_CATEGORIES = `FILTER_SELECT_CATEGORIES`;
export const FILTER_SELECT_INSTRUCTORS = `FILTER_SELECT_INSTRUCTORS`;
export const FILTER_SELECT_PROGRAM = `FILTER_SELECT_PROGRAM`;
export const FILTER_SELECT_CLEAR = `FILTER_SELECT_CLEAR`;

export const SESSIONS_ACTIVE_TAB = `SESSIONS_ACTIVE_TAB`;

export const ALPHABET_FILTER_LETTER = `ALPHABET_FILTER_LETTER`;

export const ACTIVE_TAB = `ACTIVE_TAB`;
