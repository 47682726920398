import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProgramsFiltersSelect, setProgramsTab } from '@actions/programsActions';
import { classNames, getImageUrl } from '@src/utils';
import { Icon, Button, Link } from '@components/shared';

export const FacultyCard = ({ instructor }) => {
  const filters = useSelector((state) => state.schedule.filters);
  const history = useHistory();
  const href = window.location.href;

  const {
    id,
    faculty: {
      art_instruction,
      profile_image,
      user_image,
    },
    display_name,
  } = instructor;

  const matomoHandler = () => {
    if (href.includes('instructor')) {
      const instructorId = href.split("instructor=")[1].split("&")[0];
      const instructor = filters?.data?.instructors.filter((instructor) => instructor.id === Number(instructorId));
      const instructorName = instructor[0].display_name;

      window._mtm.push({'event': 'search_main', 'query': `${instructorName}`});
    }
  }

  const onProfileClick = (id) => {
    localStorage.setItem('returnHref', href);
    localStorage.setItem('returnPath', 'faculty');

    matomoHandler();
  };

  const onOfferingClick = () => {
    localStorage.setItem('returnPath', `offering/${id}`);
    matomoHandler();

    dispatch(setProgramsFiltersSelect({ instructor: id }));
    dispatch(setProgramsTab('current'));
    history.push('/programs');
  };

  const trimSubtitle = (string) => {
    if (string.length < 100) {
      return string;
    }

    return string.slice(0, 100) + '...';
  };

  const dispatch = useDispatch();

  return (
    <div className="global-page-card faculty-card">
      <div
        className={classNames([
          'faculty-card__visual',
          !profile_image && !user_image && 'faculty-card__visual--empty',
        ])}
      >
        {(user_image || profile_image) && (
          <>
            {user_image && (
              <img src={getImageUrl(user_image.path)} alt="user" />
            )}

            {profile_image && !user_image && (
              <img src={getImageUrl(profile_image.path)} alt="user" />
            )}
          </>
        )}

        {!profile_image && !user_image && (
          <Icon type="user-undefined" />
        )}
      </div>

      <div className="faculty-card__content">
        <h3 className="faculty-card__title">{display_name}</h3>

        {art_instruction && (
          <span className="faculty-card__subtitle">
            {trimSubtitle(art_instruction)}
          </span>
        )}

        <div className="faculty-card__actions">
          {/* <Link
            className="faculty-card__view-schedule"
            type="button-primary"
            iconLeft={<Icon type="calendar" />}
            target=""
            onClick={onOfferingClick}
          >
            Offerings
          </Link> */}

          <Button
            className="faculty-card__view-schedule"
            type="primary"
            iconLeft={<Icon type="calendar" />}
            onClick={onOfferingClick}
          >
            Offerings
          </Button>

          <Link
            className="faculty-card__open-profile"
            type="button-primary"
            iconLeft={<Icon type="profile-tab" />}
            href={`${window.location.origin}/faculty/${id}`}
            onClick={() => onProfileClick(id)}
          >
            Profile
          </Link>
        </div>
      </div>
    </div>
  );
};