import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  BlockOfDatesSchedulePicker,
  RegistrationPriceBlock,
  RegistrationDonationBlock,
  Loader,
  PaymentMethodSelector,
  PaymentMethodButton,
} from '@components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { useDidUpdate, useForm, parseOnlyAmount } from '@src/hooks';
import { getPreRegPrice, getPreRegSlots } from '@actions/registrationActions';
import { dateAndMonthShort } from '@src/constants';
import { format } from 'date-fns';
import { getOfferingsAmount, zonedDate } from '@src/utils';
import { normalizePreRegData } from '@src/normalizers';

export const PreRegistration = ({ sessionInfo, btnCallbacks, contentType }) => {
  const {
    inactivity_days,
    pre_registration,
    id,
    days_count,
    start_date,
    end_date,
  } = sessionInfo;
  const slots = useSelector((state) => state.registration.regSlots);
  const selectedDates = slots?.data?.map(({ date }) => zonedDate(date)) || [];
  const price = useSelector((state) => state.registration.regPrice);
  const [timeSlotsErrors, setTimeSlotsErrors] = useState([]);
  const dispatch = useDispatch();

  const submitHandler = (values) => {
    const regData = {
      selectedWeekDaySchedules,
      sessionId: id,
      donation: {
        is_anonymous: values.isAnonymous,
        amount: values.donationAmount,
        hasDonation: values.hasDonation,
      },
    };
    return btnCallbacks.submit(normalizePreRegData(regData));
  };

  const schema = {
    donationAmount: {
      parse: parseOnlyAmount,
      validation: ['isRequired', 'donation'],
      valuesRequired: ['hasDonation'],
    },
    selectedWeekDaySchedules: {
      customValidation: [
        {
          check: () => validateTimeslots(),
          message: 'Select a Timeslot to proceed',
        },
      ],
    },
  };

  const initialValues = {
    hasDonation: false,
    donationAmount: null,
    isAnonymous: false,
    selectedDays: [],
    activeBlock: null,
    selectedWeekDaySchedules: {},
    selectedStartDate: null,
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setCustomValue,
    handleSubmit,
    validateOnSubmit,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const { selectedWeekDaySchedules } = values;

  useEffect(() => {
    dispatch(getPreRegSlots(id));
  }, []);

  useDidUpdate(() => {
    if (timeSlotsErrors.length) {
      const selectedWeekDays = Object.keys(selectedWeekDaySchedules);
      setTimeSlotsErrors((errors) =>
        errors.filter((err) => !selectedWeekDays.includes(`${err}`))
      );
    }

    if (!getFailedTimeslots()?.length) {
      const requestData = {
        sessionId: id,
        selectedWeekDaySchedules,
      };
      dispatch(getPreRegPrice(requestData));
    }
  }, [selectedWeekDaySchedules]);

  const validateTimeslots = () => {
    const failedTimeslots = getFailedTimeslots();
    if (failedTimeslots?.length) {
      setTimeSlotsErrors(failedTimeslots.map(({ day_num }) => day_num));
      return false;
    }
    return true;
  };

  const getFailedTimeslots = () => {
    const selectedDaysNumbers = Object.keys(selectedWeekDaySchedules).map(
      (num) => +num
    );
    return slots?.data?.filter(
      ({ day_num }) => !selectedDaysNumbers.includes(day_num)
    );
  };

  const getTitleDates = () => {
    const startDate = format(zonedDate(start_date), dateAndMonthShort);
    const endDate = format(zonedDate(end_date), dateAndMonthShort);

    return startDate === endDate
      ? `on ${startDate}`
      : `from ${startDate} — ${endDate}`;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Loader attached show={slots.loading} />
      <div className="registration-step__group">
        <h4 className="registration-step__title">
          Pre-register for {getOfferingsAmount(days_count, contentType)}{' '}
          {getTitleDates()}
        </h4>
      </div>
      <BlockOfDatesSchedulePicker
        slots={slots}
        inactivity_days={inactivity_days}
        selectedSlots={slots}
        selectedDates={selectedDates}
        selectedWeekDaySchedules={selectedWeekDaySchedules}
        setCustomValue={setCustomValue}
        errors={errors}
        contentType={contentType}
        timeSlotsErrors={timeSlotsErrors}
      />
      <div className="registration-step__group registration-step__group--price">
        <RegistrationDonationBlock
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <div className="registration-step__payment-group">
          <RegistrationPriceBlock
            price={!getFailedTimeslots()?.length && price.data}
            rate={pre_registration.rate}
            contentType={contentType}
            loading={price.loading}
            values={values}
          />
          <PaymentMethodSelector />
        </div>
      </div>

      <div className="btn__wrapper">
        <Button
          htmlType="button"
          onClick={btnCallbacks.back}
          type="transparent"
          iconLeft={<Icon type="chevron" />}
        >
          Back
        </Button>
        <PaymentMethodButton
          handleSubmit={handleSubmit}
          values={values}
          validateOnSubmit={validateOnSubmit}
          amount={price.data}
        />
      </div>
    </form>
  );
};