import React, { useState, useEffect } from 'react';
import { format, startOfDay } from 'date-fns';
import { dateFormatRequest } from '@src/constants';
import { queryParam, zonedDate } from '@src/utils';
import { DailyTable } from './DailyTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDayTable, setDayDate } from '@actions/scheduleActions';
import { Loader, CalendarDropdown } from '@components/shared';
import { ScheduleError } from '../ScheduleError';
import { NavigationPanel } from '../Navigation';
import { useApi } from '@src/hooks';

export const Day = ({ onCardClick }) => {
  const today = startOfDay(new Date());
  const querryDate = queryParam.get('chosen-date');
  const closestDay = useApi(
    { url: `/account/appointments/closest-date` },
    [],
    querryDate === 'closest'
  );
  const initDate = querryDate && querryDate !== 'closest' ? zonedDate(querryDate) : today;

  const [selectedDay, setSelectedDay] = useState(initDate);
  const [date, setDate] = useState(selectedDay);
  const dayDate = useSelector((state) => state.schedule.dayDate);
  const dayTable = useSelector((state) => state.schedule.dayTable);
  const filtersSelect = useSelector((state) => state.schedule.filtersSelect);

  const dispatch = useDispatch();

  useEffect(() => {
    if (closestDay.data) {
      setSelectedDay(zonedDate(closestDay.data));
    }
  }, [closestDay.data]);

  useEffect(() => {
    dispatch(getDayTable(dayDate, filtersSelect));
    queryParam.set('dayDate', format(dayDate, dateFormatRequest));
  }, [dayDate, filtersSelect]);

  return (
    <>
      <NavigationPanel
        className="schedule-table__navigation__wrapper--daily"
        pagination={ 
          <>    
            <CalendarDropdown 
              setDate={setDate}
              today={today}
              selectedDay={dayDate}
              setSelectedDay={(day) => dispatch(setDayDate(day))}
              onMonthChange={setDate}
              isScheduleDayCalendar
              dayDate={dayDate}
            />
          </>
        }
      />
      <Loader show={dayTable.loading} global />
      {dayTable.error ? (
        <ScheduleError />
      ) : (
        dayTable.data && (
          <DailyTable data={dayTable.data} onCardClick={onCardClick} />
        )
      )}
    </>
  );
};