import React from "react";
import { classNames } from '@src/utils';

export const ProviderBanner = ({ className }) => {
  return (    
    <div
      className={classNames([
        'provider-banner', 
        className
      ])}
    >
      <img 
        className="provider-banner__body" 
        src="https://thumbs.dreamstime.com/b/collage-talented-young-hip-hop-dancers-motion-isolated-over-multicolored-background-neon-lights-movements-light-youth-233863976.jpg" 
        alt="provider banner" 
      />
    </div>
  );
};