import React, { useState } from 'react';
import VideoCard from '@components/features/Videos/VideoCard';
import { Button, Icon, Loader } from '@components/shared';
import { openLink } from '@src/utils';
import { useApi } from '@src/hooks';

export const AccountVideos = () => {
  const [expandedVideo, setExpandedVideo] = useState(null);
  const [pageCounter, setPageCounter] = useState(1);

  const userVideos = useApi(
    { url: `/account/videos?page=${pageCounter}`, dataPlaceholder: [], reduce: true },
    [pageCounter]
  );

  const onProgramsClick = () => {
    const programsPageLink = `${window.location.origin}/programs`;
    openLink(programsPageLink, true);
  };

  return (
    <div className="account-videos">
      <h2 className='account__content-title'>Videos</h2>
      <Loader sticky show={userVideos.loading} />
      {userVideos.data.length ? (
        userVideos.data?.map((video) => {
          return (
            <VideoCard
              video={video}
              key={video.id}
              isExpanded={expandedVideo === video.id}
              setExpandedVideo={setExpandedVideo}
              isWatchAllowed
            />
          );
        })
      ) : (
        <div className="account__no-items account__no-videos">
          <Icon type="video" />
          {!userVideos.loading && (
            <p>
              You haven't any available video to watch. 
              {/* You can find some
              Content/Offerings with videos on the{' '}
              <Button type="link" onClick={onProgramsClick}>
                Programs page
              </Button> */}
            </p>
          )}
        </div>
      )}
      {userVideos.hasNextPage && (
        <Button
          className="account__more-button account-videos__more-button"
          onClick={() => setPageCounter(pageCounter + 1)}
        >
          View More
        </Button>
      )}
    </div>
  );
};
