import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../index';

export const NothingFound = ({ className, title, subtitle }) => {
  return (
    <div
      className={`nothing-found${
        className ? ' ' + className : ''
      }`}
    >
      <span className="nothing-found__title">{title}</span>
      <span className="nothing-found__subtitle">{subtitle}</span>
      <Icon type="block-class" className="nothing-found__class-icon" />
    </div>
  );
};

NothingFound.propTypes = {
  className: PropTypes.string,
};

NothingFound.defaultProps = {
  className: '',
  title: 'For now here are no Content / Offerings on the selected dates.',
  subtitle: 'Please look up for another date, clear or select another filter.',
};