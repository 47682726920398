import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { setProgramsFiltersSelect, setProgramsTab } from '@actions/programsActions';
import { Button } from '@components/shared';

export const CourseParticipantLevels = ({ course }) => {
  const { participant_levels, participant_level_exception } = course;
  const history = useHistory();
  const dispatch = useDispatch();

  const opeParticipantLevelsPrograms = (id) => {
    dispatch(setProgramsFiltersSelect({ participant_levels: [`${id}`] }))
    dispatch(setProgramsTab('current'));
    history.push('/programs');
  };

  const addSeparator = (i, arr) => {
    if (i === arr.length - 2) {
      return (
        <>&nbsp;&#38;&nbsp;</>
        
      );
    } else if (i !== arr.length - 1) {
      return (
        <>,&nbsp;</>
      );
    } else if (i === arr.length - 1) {
      return (
        <sup>{participant_level_exception}</sup>
      );
    } else {
      return (
        ''
      );
    }
  };

  const renderParticipantLevels = (levels) => {
    return (
      levels.map((level, i, arr) => (
        <span key={level.id} style={{display: "inline-flex"}}>
          <Button
            key={level.id}
            type="navigation"
            onClick={() =>  opeParticipantLevelsPrograms(level.id)}
          >
            {level.name}
          </Button>
          {addSeparator(i, arr)}
        </span>
      ))
    );
  };

  return (
    <>
      {course && renderParticipantLevels(participant_levels)}
    </>
  );
};

CourseParticipantLevels.propTypes = {
  course: PropTypes.object,
};

CourseParticipantLevels.defaultProps = {
  course: {},
};