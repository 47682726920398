import React, { useEffect } from 'react';
import { getUserData, updateUserData } from '@actions/authActions';
import { useForm, useDeepEffect } from '@src/hooks';
import { openLink, getProfileSchema } from '@src/utils';
import { defaultBirthDateFormatMask, userProfileDateFormat } from '@src/constants';
import { normalizeUserData, normalizeUserDataErrors } from '@src/normalizers';
import { format } from 'date-fns';
import { Button, Input, Tooltip } from '@components/shared';
import { AccountProfileCard } from '../../Supports/AccountProfileCard';

export const PersonalInformation = ({   
  userData, 
  editorState, 
  setEditorState, 
  dispatch
}) => {
  const isPersonalInfo = editorState === 'personalInfo';
  const { contact } = userData.data || {};

  const {
    first_name,
    middle_name,
    last_name,
    date_of_birth,
  } = contact || {};

  const submitHandler = (values) => {
    const userData = normalizeUserData(values, editorState, date_of_birth);
    dispatch(updateUserData(userData, handleRequest, handleServerErrors));
  };

  const handleRequest = () => {
    setEditorState('');
  };

  const handleServerErrors = (errors) => {
    const { data } = errors;

    if (data?.errors) {
      setErrors(normalizeUserDataErrors(data.errors));
    }
  };

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useDeepEffect(() => {
    if (userData.data) {
      setValues(initialValues);
    }
  }, [userData]);

  const initialValues = {
    firstName: first_name,
    middleName: middle_name,
    lastName: last_name,
    birthDate:
      date_of_birth && format(new Date(date_of_birth), userProfileDateFormat),
  };

  const schema = getProfileSchema(editorState);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
    clearValues,
    setValues,
  } = useForm({
    callback: submitHandler,
    schema,
    initialValues,
  });

  const onContactUsClick = () => {
    openLink('https://cccaonline.org/contact-us/#contacts', true);
  };

  const renderSupportTooltip = () => (
    <Tooltip position="bottom">
      <span>Also you can directly email</span>
      <a href="mailto:dan.yonah.marshalll@gmail.com">
        dan.yonah.marshall@gmail.com
      </a>
      <span>or call (617) 642-1444.</span>
    </Tooltip>
  );

  const renderDateOfBirthSuggestion = () => {
    return isPersonalInfo && !!date_of_birth ? (
      <span className="account-profile__subcription-note">
        If you want to change your Date of Birth please{' '}
        <span className="account-profile__subcription-support">
          <Button onClick={onContactUsClick} type="link">
            contact Support
          </Button>
          {renderSupportTooltip()}
        </span>
      </span>
    ) : null;
  };

  const personalBlockData = [
    {
      label: 'First Name',
      inputType: Input,
      inputName: 'firstName',
      placeholder: 'Enter your First Name',
    },
    {
      label: 'Middle Name',
      inputType: Input,
      inputName: 'middleName',
      placeholder: 'Enter your Middle Name',
    },
    {
      label: 'Last Name',
      inputType: Input,
      inputName: 'lastName',
      placeholder: 'Enter your Last Name',
    },
    {
      label: 'Date Of Birth',
      inputType: Input,
      inputName: 'birthDate',
      placeholder:
        !date_of_birth && !isPersonalInfo ? '–' : 'Enter your Date Of Birth',
      additionalProps: {
        disabled: !!date_of_birth || !isPersonalInfo,
        mask: defaultBirthDateFormatMask,
      },
    },
  ];

  const profileBlocksData = [
    {
      title: 'Personal Information',
      data: personalBlockData,
      name: 'personalInfo',
      subscription: renderDateOfBirthSuggestion(),
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      userData,
      editorState, 
      setEditorState,
      clearValues,
      setErrors,
    },
  ];

  return (
    <>
      {profileBlocksData.map((blockData) => AccountProfileCard(blockData))}
    </>
  );
};