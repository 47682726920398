import React from 'react';
import { CoursesTitles } from './CoursesTitles';
import { useSelector } from 'react-redux';
import { Loader, NothingFound } from '@components/shared';

export const ProgramsCourses = ({ className, isProviderAbout }) => {
  const courses = useSelector((state) => state.programs.courses);
  const filters = useSelector((state) => state.programs.filters);
  const isLoading = courses.loading || filters.loading;

  return (
    <>
      <Loader global show={isLoading} />
      {!courses.data.length ? (
        <>
          {!isLoading && (
            <NothingFound
              title="Sorry, no results found"
              subtitle="Please check your search or use different filters"
            />
          )}
        </>
      ) : (
        <CoursesTitles 
          className={className} 
          courses={courses} 
          isProviderAbout={isProviderAbout}
        />
      )}
    </>
  );
};
