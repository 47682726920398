import React from 'react';
import { checkoutDateFormat } from '@src/constants';
import { format } from 'date-fns';
import { getTwelveHoursPeriod } from '@src/utils';

export const CheckoutTable = ({ appointments= []}) => {

  return (
    <div className="checkout-table checkout-table--desktop-only">
      <div className="checkout-table__row">
        <p className="checkout-table__label">№</p>
        <p className="checkout-table__label">Date</p>
        <p className="checkout-table__label">Time</p>
        <p className="checkout-table__label">Studio(s)</p>
        <p className="checkout-table__label">Price</p>
      </div>
      {appointments.map((appointment, index) => {
        const { class_date, schedule, price, discount, increase } = appointment;
        const {studio_name, start_time, end_time} = schedule;
        return (
          <div className="checkout-table__row" key={index}>
            <p className="checkout-table__value">{index + 1}</p>
            <p className="checkout-table__value">
              {format(new Date(class_date), checkoutDateFormat)}
            </p>
            <p className="checkout-table__value">{getTwelveHoursPeriod(start_time, end_time)}</p>
            <p className="checkout-table__value">
              {studio_name}
            </p>
            <p className="checkout-table__value">
              ${price}
              {!!discount && (
                <span className="checkout-table__discount">
                  <span className="discount__label">Discount</span>
                  <span className="discount__value">-${discount}</span>
                </span>
              )}
              {!!increase && (
                <span className="checkout-table__increase">
                  <span className="increase__label">Increase</span>
                  <span className="increase__value">${increase}</span>
                </span>
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
};
