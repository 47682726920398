import React, { useState } from 'react';
import { stopIframeVideo } from '@src/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import VideoGalleryCardNew from '@components/features/Videos/VideoGalleryCardNew';
import { VideoGalleryThumbs } from './VideoGalleryThumbs';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/thumbs/thumbs.scss';

SwiperCore.use([Navigation, Thumbs]);

export const VideoGalleryNew = ({ videos, onPayClick }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const sliderSettings = {
    className: 'video-gallery__slider',
    thumbs: { swiper: thumbsSwiper },
    navigation: {
      nextEl: '.video-gallery__next-button',
      prevEl: '.video-gallery__prev-button',
    },
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    onSlideChangeTransitionEnd: ({ previousIndex, slides }) => 
      stopIframeVideo(slides[previousIndex]),
  };

  const thumbsSettings = {
    onSwiper: setThumbsSwiper,
    navigation: {
      nextEl: '.video-gallery__next-button',
      prevEl: '.video-gallery__prev-button',
    },
    loop: true,
    slidesPerView: 2,
    spaceBetween: 10,
    onSlideChangeTransitionEnd: ({ previousIndex, slides }) => 
      stopIframeVideo(slides[previousIndex]),
    breakpoints: {
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      }
    }
  };

  return (
    <div className="video-gallery--new">
      <div className="container">
        <div className="video-gallery__thumbs">
          <Swiper  {...thumbsSettings}>
            {videos?.map((video) => {
              return (
                <SwiperSlide key={video.id}>
                  <VideoGalleryThumbs videoData={video} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className='video-gallery__buttons'>
            <div className="swiper-button-prev video-gallery__prev-button"></div>
            <div className="swiper-button-next video-gallery__next-button"></div>
          </div>
        </div>

        <Swiper {...sliderSettings}>
          {videos?.map((video) => {
            return (
              <SwiperSlide className="video-gallery__slide" key={video.id}>
                <VideoGalleryCardNew videoData={video} onPayClick={onPayClick} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};